// custom css ngx-mat-select-search
ngx-mat-select-search {
  .mat-datepicker-content {
    box-shadow: none !important;
  }

  .mat-select-search-clear {
    width: 24px !important;
    height: 24px !important;
    top: 8px !important;
    right: 8px !important;

    mat-icon {
      width: 20px;
      height: 20px;
      font-size: 20px;
    }
  }
}
