.loading-container {
  width: 100%;
  height: 100vh;
  display: flex;
}

.mat-loading {
  .mdc-button__label,
  mat-icon {
    visibility: hidden;
  }

  .mat-mdc-progress-spinner {
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
  }
}
