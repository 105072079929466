@mixin b3n-typography() {
  .h0,
  h1,
  h2,
  h3,
  h4 {
    letter-spacing: -0.2px !important;
  }

  .h0 {
    font-size: 24px !important;
    font-weight: 550 !important;
  }

  h1,
  .h1 {
    font-size: 20px !important;
    font-weight: 550 !important;
    line-height: 32px !important;
  }

  h2,
  .h2 {
    font-size: 16px !important;
    font-weight: 550 !important;
  }

  h3,
  .h3 {
    font-size: 14px !important;
    font-weight: 550 !important;
  }

  h4,
  .h4 {
    font-size: 12px;
    font-weight: 550;
  }

  .body-2 {
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  // set default bold to 550
  strong,
  b {
    font-weight: 550;
  }
}
