@mixin b3n-menu() {
  .mat-mdc-menu-content:has(button.mat-mdc-menu-item) {
    padding-right: 16px;
    border-radius: 8px !important;
    button.mat-mdc-menu-item {
      min-height: 40px;
      margin-left: 8px;
      border-radius: 6px !important;
      padding-left: 8px !important;
      padding-right: 8px !important;

      &:hover:not([disabled]) {
        background-color: var(--b3n-selection-hover, #f2f3f5);

        .mdc-list-item__primary-text,
        mat-icon:not(.inherit-color),
        .mat-mdc-menu-submenu-icon {
          color: var(--b3n-selection-text-hover, #313338);
        }
      }

      &.mat-mdc-menu-item-highlighted {
        background-color: var(--b3n-selection-active, #ebf2fb) !important;

        .mdc-list-item__primary-text,
        mat-icon:not(.inherit-color),
        .mat-mdc-menu-submenu-icon {
          color: var(--b3n-selection-text-active, '#136899');
        }
      }
    }
  }

  // For input phone number
  .ngx-mat-tel-input-mat-menu-panel .mat-mdc-menu-content:has(button.mat-mdc-menu-item) {
    padding-top: 0 !important;
    padding-right: 0 !important;

    button.country-list-button {
      padding-left: 8px !important;
      margin-left: 0 !important;
      border-radius: 0 !important;
    }
  }
}
