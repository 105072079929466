@use '@angular/material' as mat;

// Include core Angular Material styles
@include mat.core();

// General style
@import 'partials/icons';
@import 'partials/scrollbars';
@import 'partials/loading-button';

// custom material
@import './material-custom.scss';

// remove responsive of container class in tailwind
.container:not(.responsive) {
  width: 100%;
  max-width: 100% !important;
}
