@mixin b3n-formfield() {
  // remove spacing bottom
  mat-form-field.no-validator {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
    .mat-mdc-floating-label {
      top: 20px;
    }

    .mdc-icon-button.mat-mdc-button-base {
      .mat-datepicker-toggle-default-icon {
        display: inline-block;
      }
    }
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    --mat-mdc-form-field-label-transform: translateY(-26.75px)
      scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  }

  .mdc-floating-label {
    will-change: auto;
  }

  .mdc-notched-outline__notch {
    border-right: 0;
  }

  .mdc-text-field--outlined {
    &:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid) {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: var(--b3n-border, #e0e0e0);
      }

      &:hover {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          border-color: var(--b3n-link, #1979b1);
        }

        .mdc-notched-outline__notch .mdc-floating-label {
          color: var(--b3n-link, #027cb5);
        }
      }
    }

    &.mdc-text-field--focused:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: var(--b3n-link, #027cb5);
      }

      .mdc-notched-outline__notch .mdc-floating-label {
        color: var(--b3n-link, #027cb5);
      }
    }
  }
}
