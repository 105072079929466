// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------

@import './config-color-theme';
@import 'lightmode.theme';
@import 'darkmode.theme';

body {
  // TODO: check chip module with angular 15
  .mat-chip.mat-standard-chip {
    background-color: var(--b3n-highlight, #424242);
  }

  // TODO: remove class angular 14
  .mat-drawer-side.mat-drawer-end {
    border: 1px solid var(--b3n-border, #e0e0e0);
  }

  .mat-input-element::-webkit-input-placeholder,
  .mat-input-element::placeholder {
    color: var(--b3n-secondary-color, #0000008a) !important;
  }

  // fix border radius style in popup dialog
  .cdk-overlay-container {
    .cdk-overlay-pane {
      background: inherit !important;
    }
  }

  .color-link {
    color: var(--b3n-link, #1979b1);
  }

  //input autofill
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--b3n-color, #000000de);
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px var(--b3n-background, #ffffff);
  }

  // hide input number arrow
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
body:not(.is-mobile) ::-webkit-scrollbar {
  // background-color: var(--b3n-background , #FFFFFF) !important;
  width: 5px !important;
  height: 5px !important;
}
body:not(.is-mobile) ::-webkit-scrollbar-thumb {
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  border: unset !important;
  background-color: var(--b3n-scrollbar-thumb, #bdbdbd) !important;
}

body:not(.is-mobile) ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent !important;
  box-shadow: inset 0 0 6px transparent !important;
  background-color: transparent !important;
  border-radius: 12px;
}
