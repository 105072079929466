@mixin b3n-selectbox {
  .mat-mdc-form-field.mat-focused.mat-primary:not(.mat-form-field-invalid) {
    .mat-mdc-select-arrow {
      color: var(--b3n-link, #1979b1) !important;
    }
  }

  .mat-mdc-autocomplete-panel,
  .mat-mdc-select-panel {
    border-radius: 6px !important;
    min-width: max-content !important;

    mat-option:not(.ngx-mat-select-search) {
      min-height: 40px;
      margin-left: 8px;
      margin-right: 8px;
      border-radius: 6px;
      padding-left: 8px;
      padding-right: 8px;

      &:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled):hover {
        --mdc-theme-primary: var(--b3n-selection-text-hover, #313338);
        background-color: var(--b3n-selection-hover, #f2f3f5) !important;
      }

      &.more-input {
        &:hover {
          --mdc-theme-primary: var(--b3n-selection-text-hover, #313338);
          background-color: #fff !important;
        }

        .mdc-list-item__primary-text {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &:not(.mat-mdc-option-multiple).mdc-list-item--selected {
        --mdc-theme-primary: var(--b3n-selection-text-active, #136899);
        background-color: var(--b3n-selection-active, #ebf2fb) !important;
      }
    }

    .mat-mdc-option.mat-mdc-option-active {
      background: var(--b3n-selection-hover, #f2f3f5);
      color: var(--b3n-color, #26272c) !important;
    }

    mat-optgroup {
      .mat-mdc-optgroup-label {
        font-size: 12px;

        .mdc-list-item__primary-text {
          font-weight: 550;
          color: var(--b3n-secondary-color, #26272ccc);
        }
      }
    }
  }
}
