@tailwind base;
@tailwind components;
@tailwind utilities;
/* Import core scss*/
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

mat-icon.s-10 {
  font-size: 10px !important;
  width: 10px !important;
  height: 10px !important;
  min-width: 10px !important;
  min-height: 10px !important;
  line-height: 10px !important;
}
mat-icon.s-11 {
  font-size: 11px !important;
  width: 11px !important;
  height: 11px !important;
  min-width: 11px !important;
  min-height: 11px !important;
  line-height: 11px !important;
}
mat-icon.s-12 {
  font-size: 12px !important;
  width: 12px !important;
  height: 12px !important;
  min-width: 12px !important;
  min-height: 12px !important;
  line-height: 12px !important;
}
mat-icon.s-13 {
  font-size: 13px !important;
  width: 13px !important;
  height: 13px !important;
  min-width: 13px !important;
  min-height: 13px !important;
  line-height: 13px !important;
}
mat-icon.s-14 {
  font-size: 14px !important;
  width: 14px !important;
  height: 14px !important;
  min-width: 14px !important;
  min-height: 14px !important;
  line-height: 14px !important;
}
mat-icon.s-15 {
  font-size: 15px !important;
  width: 15px !important;
  height: 15px !important;
  min-width: 15px !important;
  min-height: 15px !important;
  line-height: 15px !important;
}
mat-icon.s-16 {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
  min-width: 16px !important;
  min-height: 16px !important;
  line-height: 16px !important;
}
mat-icon.s-17 {
  font-size: 17px !important;
  width: 17px !important;
  height: 17px !important;
  min-width: 17px !important;
  min-height: 17px !important;
  line-height: 17px !important;
}
mat-icon.s-18 {
  font-size: 18px !important;
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
  min-height: 18px !important;
  line-height: 18px !important;
}
mat-icon.s-19 {
  font-size: 19px !important;
  width: 19px !important;
  height: 19px !important;
  min-width: 19px !important;
  min-height: 19px !important;
  line-height: 19px !important;
}
mat-icon.s-20 {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
  min-width: 20px !important;
  min-height: 20px !important;
  line-height: 20px !important;
}
mat-icon.s-21 {
  font-size: 21px !important;
  width: 21px !important;
  height: 21px !important;
  min-width: 21px !important;
  min-height: 21px !important;
  line-height: 21px !important;
}
mat-icon.s-22 {
  font-size: 22px !important;
  width: 22px !important;
  height: 22px !important;
  min-width: 22px !important;
  min-height: 22px !important;
  line-height: 22px !important;
}
mat-icon.s-23 {
  font-size: 23px !important;
  width: 23px !important;
  height: 23px !important;
  min-width: 23px !important;
  min-height: 23px !important;
  line-height: 23px !important;
}
mat-icon.s-24 {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  min-height: 24px !important;
  line-height: 24px !important;
}
mat-icon.s-25 {
  font-size: 25px !important;
  width: 25px !important;
  height: 25px !important;
  min-width: 25px !important;
  min-height: 25px !important;
  line-height: 25px !important;
}
mat-icon.s-26 {
  font-size: 26px !important;
  width: 26px !important;
  height: 26px !important;
  min-width: 26px !important;
  min-height: 26px !important;
  line-height: 26px !important;
}
mat-icon.s-27 {
  font-size: 27px !important;
  width: 27px !important;
  height: 27px !important;
  min-width: 27px !important;
  min-height: 27px !important;
  line-height: 27px !important;
}
mat-icon.s-28 {
  font-size: 28px !important;
  width: 28px !important;
  height: 28px !important;
  min-width: 28px !important;
  min-height: 28px !important;
  line-height: 28px !important;
}
mat-icon.s-29 {
  font-size: 29px !important;
  width: 29px !important;
  height: 29px !important;
  min-width: 29px !important;
  min-height: 29px !important;
  line-height: 29px !important;
}
mat-icon.s-30 {
  font-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
  min-width: 30px !important;
  min-height: 30px !important;
  line-height: 30px !important;
}
mat-icon.s-31 {
  font-size: 31px !important;
  width: 31px !important;
  height: 31px !important;
  min-width: 31px !important;
  min-height: 31px !important;
  line-height: 31px !important;
}
mat-icon.s-32 {
  font-size: 32px !important;
  width: 32px !important;
  height: 32px !important;
  min-width: 32px !important;
  min-height: 32px !important;
  line-height: 32px !important;
}
mat-icon.s-33 {
  font-size: 33px !important;
  width: 33px !important;
  height: 33px !important;
  min-width: 33px !important;
  min-height: 33px !important;
  line-height: 33px !important;
}
mat-icon.s-34 {
  font-size: 34px !important;
  width: 34px !important;
  height: 34px !important;
  min-width: 34px !important;
  min-height: 34px !important;
  line-height: 34px !important;
}
mat-icon.s-35 {
  font-size: 35px !important;
  width: 35px !important;
  height: 35px !important;
  min-width: 35px !important;
  min-height: 35px !important;
  line-height: 35px !important;
}
mat-icon.s-36 {
  font-size: 36px !important;
  width: 36px !important;
  height: 36px !important;
  min-width: 36px !important;
  min-height: 36px !important;
  line-height: 36px !important;
}
mat-icon.s-37 {
  font-size: 37px !important;
  width: 37px !important;
  height: 37px !important;
  min-width: 37px !important;
  min-height: 37px !important;
  line-height: 37px !important;
}
mat-icon.s-38 {
  font-size: 38px !important;
  width: 38px !important;
  height: 38px !important;
  min-width: 38px !important;
  min-height: 38px !important;
  line-height: 38px !important;
}
mat-icon.s-39 {
  font-size: 39px !important;
  width: 39px !important;
  height: 39px !important;
  min-width: 39px !important;
  min-height: 39px !important;
  line-height: 39px !important;
}
mat-icon.s-40 {
  font-size: 40px !important;
  width: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  min-height: 40px !important;
  line-height: 40px !important;
}
mat-icon.s-41 {
  font-size: 41px !important;
  width: 41px !important;
  height: 41px !important;
  min-width: 41px !important;
  min-height: 41px !important;
  line-height: 41px !important;
}
mat-icon.s-42 {
  font-size: 42px !important;
  width: 42px !important;
  height: 42px !important;
  min-width: 42px !important;
  min-height: 42px !important;
  line-height: 42px !important;
}
mat-icon.s-43 {
  font-size: 43px !important;
  width: 43px !important;
  height: 43px !important;
  min-width: 43px !important;
  min-height: 43px !important;
  line-height: 43px !important;
}
mat-icon.s-44 {
  font-size: 44px !important;
  width: 44px !important;
  height: 44px !important;
  min-width: 44px !important;
  min-height: 44px !important;
  line-height: 44px !important;
}
mat-icon.s-45 {
  font-size: 45px !important;
  width: 45px !important;
  height: 45px !important;
  min-width: 45px !important;
  min-height: 45px !important;
  line-height: 45px !important;
}
mat-icon.s-46 {
  font-size: 46px !important;
  width: 46px !important;
  height: 46px !important;
  min-width: 46px !important;
  min-height: 46px !important;
  line-height: 46px !important;
}
mat-icon.s-47 {
  font-size: 47px !important;
  width: 47px !important;
  height: 47px !important;
  min-width: 47px !important;
  min-height: 47px !important;
  line-height: 47px !important;
}
mat-icon.s-48 {
  font-size: 48px !important;
  width: 48px !important;
  height: 48px !important;
  min-width: 48px !important;
  min-height: 48px !important;
  line-height: 48px !important;
}
mat-icon.s-49 {
  font-size: 49px !important;
  width: 49px !important;
  height: 49px !important;
  min-width: 49px !important;
  min-height: 49px !important;
  line-height: 49px !important;
}
mat-icon.s-50 {
  font-size: 50px !important;
  width: 50px !important;
  height: 50px !important;
  min-width: 50px !important;
  min-height: 50px !important;
  line-height: 50px !important;
}
mat-icon.s-51 {
  font-size: 51px !important;
  width: 51px !important;
  height: 51px !important;
  min-width: 51px !important;
  min-height: 51px !important;
  line-height: 51px !important;
}
mat-icon.s-52 {
  font-size: 52px !important;
  width: 52px !important;
  height: 52px !important;
  min-width: 52px !important;
  min-height: 52px !important;
  line-height: 52px !important;
}
mat-icon.s-53 {
  font-size: 53px !important;
  width: 53px !important;
  height: 53px !important;
  min-width: 53px !important;
  min-height: 53px !important;
  line-height: 53px !important;
}
mat-icon.s-54 {
  font-size: 54px !important;
  width: 54px !important;
  height: 54px !important;
  min-width: 54px !important;
  min-height: 54px !important;
  line-height: 54px !important;
}
mat-icon.s-55 {
  font-size: 55px !important;
  width: 55px !important;
  height: 55px !important;
  min-width: 55px !important;
  min-height: 55px !important;
  line-height: 55px !important;
}
mat-icon.s-56 {
  font-size: 56px !important;
  width: 56px !important;
  height: 56px !important;
  min-width: 56px !important;
  min-height: 56px !important;
  line-height: 56px !important;
}
mat-icon.s-57 {
  font-size: 57px !important;
  width: 57px !important;
  height: 57px !important;
  min-width: 57px !important;
  min-height: 57px !important;
  line-height: 57px !important;
}
mat-icon.s-58 {
  font-size: 58px !important;
  width: 58px !important;
  height: 58px !important;
  min-width: 58px !important;
  min-height: 58px !important;
  line-height: 58px !important;
}
mat-icon.s-59 {
  font-size: 59px !important;
  width: 59px !important;
  height: 59px !important;
  min-width: 59px !important;
  min-height: 59px !important;
  line-height: 59px !important;
}
mat-icon.s-60 {
  font-size: 60px !important;
  width: 60px !important;
  height: 60px !important;
  min-width: 60px !important;
  min-height: 60px !important;
  line-height: 60px !important;
}
mat-icon.s-61 {
  font-size: 61px !important;
  width: 61px !important;
  height: 61px !important;
  min-width: 61px !important;
  min-height: 61px !important;
  line-height: 61px !important;
}
mat-icon.s-62 {
  font-size: 62px !important;
  width: 62px !important;
  height: 62px !important;
  min-width: 62px !important;
  min-height: 62px !important;
  line-height: 62px !important;
}
mat-icon.s-63 {
  font-size: 63px !important;
  width: 63px !important;
  height: 63px !important;
  min-width: 63px !important;
  min-height: 63px !important;
  line-height: 63px !important;
}
mat-icon.s-64 {
  font-size: 64px !important;
  width: 64px !important;
  height: 64px !important;
  min-width: 64px !important;
  min-height: 64px !important;
  line-height: 64px !important;
}
mat-icon.s-65 {
  font-size: 65px !important;
  width: 65px !important;
  height: 65px !important;
  min-width: 65px !important;
  min-height: 65px !important;
  line-height: 65px !important;
}
mat-icon.s-66 {
  font-size: 66px !important;
  width: 66px !important;
  height: 66px !important;
  min-width: 66px !important;
  min-height: 66px !important;
  line-height: 66px !important;
}
mat-icon.s-67 {
  font-size: 67px !important;
  width: 67px !important;
  height: 67px !important;
  min-width: 67px !important;
  min-height: 67px !important;
  line-height: 67px !important;
}
mat-icon.s-68 {
  font-size: 68px !important;
  width: 68px !important;
  height: 68px !important;
  min-width: 68px !important;
  min-height: 68px !important;
  line-height: 68px !important;
}
mat-icon.s-69 {
  font-size: 69px !important;
  width: 69px !important;
  height: 69px !important;
  min-width: 69px !important;
  min-height: 69px !important;
  line-height: 69px !important;
}
mat-icon.s-70 {
  font-size: 70px !important;
  width: 70px !important;
  height: 70px !important;
  min-width: 70px !important;
  min-height: 70px !important;
  line-height: 70px !important;
}
mat-icon.s-71 {
  font-size: 71px !important;
  width: 71px !important;
  height: 71px !important;
  min-width: 71px !important;
  min-height: 71px !important;
  line-height: 71px !important;
}
mat-icon.s-72 {
  font-size: 72px !important;
  width: 72px !important;
  height: 72px !important;
  min-width: 72px !important;
  min-height: 72px !important;
  line-height: 72px !important;
}
mat-icon.s-73 {
  font-size: 73px !important;
  width: 73px !important;
  height: 73px !important;
  min-width: 73px !important;
  min-height: 73px !important;
  line-height: 73px !important;
}
mat-icon.s-74 {
  font-size: 74px !important;
  width: 74px !important;
  height: 74px !important;
  min-width: 74px !important;
  min-height: 74px !important;
  line-height: 74px !important;
}
mat-icon.s-75 {
  font-size: 75px !important;
  width: 75px !important;
  height: 75px !important;
  min-width: 75px !important;
  min-height: 75px !important;
  line-height: 75px !important;
}
mat-icon.s-76 {
  font-size: 76px !important;
  width: 76px !important;
  height: 76px !important;
  min-width: 76px !important;
  min-height: 76px !important;
  line-height: 76px !important;
}
mat-icon.s-77 {
  font-size: 77px !important;
  width: 77px !important;
  height: 77px !important;
  min-width: 77px !important;
  min-height: 77px !important;
  line-height: 77px !important;
}
mat-icon.s-78 {
  font-size: 78px !important;
  width: 78px !important;
  height: 78px !important;
  min-width: 78px !important;
  min-height: 78px !important;
  line-height: 78px !important;
}
mat-icon.s-79 {
  font-size: 79px !important;
  width: 79px !important;
  height: 79px !important;
  min-width: 79px !important;
  min-height: 79px !important;
  line-height: 79px !important;
}
mat-icon.s-80 {
  font-size: 80px !important;
  width: 80px !important;
  height: 80px !important;
  min-width: 80px !important;
  min-height: 80px !important;
  line-height: 80px !important;
}

body:not(.is-mobile) ::-webkit-scrollbar {
  width: 8px;
  height: 12px;
  background-color: rgba(0, 0, 0, 0);
}
body:not(.is-mobile) ::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.12);
}
body:not(.is-mobile) ::-webkit-scrollbar-corner {
  background-color: transparent;
}
body:not(.is-mobile) ::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.37);
  border-radius: 12px;
}
body:not(.is-mobile) ::-webkit-scrollbar-thumb:active {
  box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.54);
  border-radius: 12px;
}

.loading-container {
  width: 100%;
  height: 100vh;
  display: flex;
}

.mat-loading .mdc-button__label,
.mat-loading mat-icon {
  visibility: hidden;
}
.mat-loading .mat-mdc-progress-spinner {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
}

.intlTel .mat-ripple-element,
.intlTel .mat-mdc-button-persistent-ripple::before {
  background-color: transparent !important;
}
.intlTel .country-selector {
  opacity: 1 !important;
  top: 2px !important;
  left: -20px !important;
  width: 100px !important;
}
.intlTel input:not(.country-search) {
  left: 10px !important;
  padding-left: 70px !important;
  padding: 0 0 0 90px !important;
}
.intlTel input:not(.country-search)::placeholder {
  color: var(--b3n-placeholder, rgba(31, 33, 35, 0.4)) !important;
}
.intlTel mat-error {
  font-size: 10.5px;
}

.ngx-mat-tel-input-mat-menu-panel.mat-mdc-menu-panel {
  max-width: fit-content !important;
  padding: 8px !important;
}
.ngx-mat-tel-input-mat-menu-panel .mat-mdc-menu-content:not(:empty) {
  max-height: 350px !important;
  min-width: max-content !important;
}
.ngx-mat-tel-input-mat-menu-panel .mat-mdc-menu-content:not(:empty) .country-list-button {
  padding: 14px 24px 14px 8px !important;
}
.ngx-mat-tel-input-mat-menu-panel .mat-mdc-menu-content:not(:empty) .country-list-button .icon-wrapper {
  padding-right: 16px !important;
}
.ngx-mat-tel-input-mat-menu-panel .country-search {
  background-color: var(--b3n-background, #ffffff) !important;
  margin-bottom: 8px !important;
  color: var(--b3n-color, #313338) !important;
  border: none !important;
  outline: none !important;
  padding: 20px 10px 24px !important;
  margin-bottom: 0 !important;
  font-size: 16px !important;
}
.ngx-mat-tel-input-mat-menu-panel .country-search::placeholder {
  color: var(--b3n-placeholder, rgba(31, 33, 35, 0.4)) !important;
}
.ngx-mat-tel-input-mat-menu-panel .country-search:focus {
  outline: none !important;
}
.ngx-mat-tel-input-mat-menu-panel .mat-divider {
  margin: 4px 0 !important;
}
.ngx-mat-tel-input-mat-menu-panel input {
  border: 1px solid #ddd !important;
  border-radius: 6px !important;
}
.ngx-mat-tel-input-mat-menu-panel input:focus-visible {
  outline: var(--mdc-theme-primary, #2196f3) auto 1px !important;
}

.flag {
  background-image: url(../../../assets/icons/flags_sprite_2x.png);
  background-size: 100% auto;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.54));
  height: 14px;
  width: 24px;
  margin-right: 4px;
  min-width: 24px;
  max-width: 24px;
}

.flag.KY {
  background-position: 0 0;
}
.flag.AC {
  background-position: 0 -14px;
}
.flag.AE {
  background-position: 0 -28px;
}
.flag.AF {
  background-position: 0 -42px;
}
.flag.AG {
  background-position: 0 -56px;
}
.flag.AI {
  background-position: 0 -70px;
}
.flag.AL {
  background-position: 0 -84px;
}
.flag.AM {
  background-position: 0 -98px;
}
.flag.AO {
  background-position: 0 -112px;
}
.flag.AQ {
  background-position: 0 -126px;
}
.flag.AR {
  background-position: 0 -140px;
}
.flag.AS {
  background-position: 0 -154px;
}
.flag.AT {
  background-position: 0 -168px;
}
.flag.AU {
  background-position: 0 -182px;
}
.flag.AW {
  background-position: 0 -196px;
}
.flag.AX {
  background-position: 0 -210px;
}
.flag.AZ {
  background-position: 0 -224px;
}
.flag.BA {
  background-position: 0 -238px;
}
.flag.BB {
  background-position: 0 -252px;
}
.flag.BD {
  background-position: 0 -266px;
}
.flag.BE {
  background-position: 0 -280px;
}
.flag.BF {
  background-position: 0 -294px;
}
.flag.BG {
  background-position: 0 -308px;
}
.flag.BH {
  background-position: 0 -322px;
}
.flag.BI {
  background-position: 0 -336px;
}
.flag.BJ {
  background-position: 0 -350px;
}
.flag.BL {
  background-position: 0 -364px;
}
.flag.BM {
  background-position: 0 -378px;
}
.flag.BN {
  background-position: 0 -392px;
}
.flag.BO {
  background-position: 0 -406px;
}
.flag.BQ {
  background-position: 0 -420px;
}
.flag.BR {
  background-position: 0 -434px;
}
.flag.BS {
  background-position: 0 -448px;
}
.flag.BT {
  background-position: 0 -462px;
}
.flag.BV {
  background-position: 0 -476px;
}
.flag.BW {
  background-position: 0 -490px;
}
.flag.BY {
  background-position: 0 -504px;
}
.flag.BZ {
  background-position: 0 -518px;
}
.flag.CA {
  background-position: 0 -532px;
}
.flag.CC {
  background-position: 0 -546px;
}
.flag.CD {
  background-position: 0 -560px;
}
.flag.CF {
  background-position: 0 -574px;
}
.flag.CG {
  background-position: 0 -588px;
}
.flag.CH {
  background-position: 0 -602px;
}
.flag.CI {
  background-position: 0 -616px;
}
.flag.CK {
  background-position: 0 -630px;
}
.flag.CL {
  background-position: 0 -644px;
}
.flag.CM {
  background-position: 0 -658px;
}
.flag.CN {
  background-position: 0 -672px;
}
.flag.CO {
  background-position: 0 -686px;
}
.flag.CP {
  background-position: 0 -700px;
}
.flag.CR {
  background-position: 0 -714px;
}
.flag.CU {
  background-position: 0 -728px;
}
.flag.CV {
  background-position: 0 -742px;
}
.flag.CW {
  background-position: 0 -756px;
}
.flag.CX {
  background-position: 0 -770px;
}
.flag.CY {
  background-position: 0 -784px;
}
.flag.CZ {
  background-position: 0 -798px;
}
.flag.DE {
  background-position: 0 -812px;
}
.flag.DG {
  background-position: 0 -826px;
}
.flag.DJ {
  background-position: 0 -840px;
}
.flag.DK {
  background-position: 0 -854px;
}
.flag.DM {
  background-position: 0 -868px;
}
.flag.DO {
  background-position: 0 -882px;
}
.flag.DZ {
  background-position: 0 -896px;
}
.flag.EA {
  background-position: 0 -910px;
}
.flag.EC {
  background-position: 0 -924px;
}
.flag.EE {
  background-position: 0 -938px;
}
.flag.EG {
  background-position: 0 -952px;
}
.flag.EH {
  background-position: 0 -966px;
}
.flag.ER {
  background-position: 0 -980px;
}
.flag.ES {
  background-position: 0 -994px;
}
.flag.ET {
  background-position: 0 -1008px;
}
.flag.EU {
  background-position: 0 -1022px;
}
.flag.FI {
  background-position: 0 -1036px;
}
.flag.FJ {
  background-position: 0 -1050px;
}
.flag.FK {
  background-position: 0 -1064px;
}
.flag.FM {
  background-position: 0 -1078px;
}
.flag.FO {
  background-position: 0 -1092px;
}
.flag.FR {
  background-position: 0 -1106px;
}
.flag.GA {
  background-position: 0 -1120px;
}
.flag.GB {
  background-position: 0 -1134px;
}
.flag.GD {
  background-position: 0 -1148px;
}
.flag.GE {
  background-position: 0 -1162px;
}
.flag.GF {
  background-position: 0 -1176px;
}
.flag.GG {
  background-position: 0 -1190px;
}
.flag.GH {
  background-position: 0 -1204px;
}
.flag.GI {
  background-position: 0 -1218px;
}
.flag.GL {
  background-position: 0 -1232px;
}
.flag.GM {
  background-position: 0 -1246px;
}
.flag.GN {
  background-position: 0 -1260px;
}
.flag.GP {
  background-position: 0 -1274px;
}
.flag.GQ {
  background-position: 0 -1288px;
}
.flag.GR {
  background-position: 0 -1302px;
}
.flag.GS {
  background-position: 0 -1316px;
}
.flag.GT {
  background-position: 0 -1330px;
}
.flag.GU {
  background-position: 0 -1344px;
}
.flag.GW {
  background-position: 0 -1358px;
}
.flag.GY {
  background-position: 0 -1372px;
}
.flag.HK {
  background-position: 0 -1386px;
}
.flag.HM {
  background-position: 0 -1400px;
}
.flag.HN {
  background-position: 0 -1414px;
}
.flag.HR {
  background-position: 0 -1428px;
}
.flag.HT {
  background-position: 0 -1442px;
}
.flag.HU {
  background-position: 0 -1456px;
}
.flag.IC {
  background-position: 0 -1470px;
}
.flag.ID {
  background-position: 0 -1484px;
}
.flag.IE {
  background-position: 0 -1498px;
}
.flag.IL {
  background-position: 0 -1512px;
}
.flag.IM {
  background-position: 0 -1526px;
}
.flag.IN {
  background-position: 0 -1540px;
}
.flag.IO {
  background-position: 0 -1554px;
}
.flag.IQ {
  background-position: 0 -1568px;
}
.flag.IR {
  background-position: 0 -1582px;
}
.flag.IS {
  background-position: 0 -1596px;
}
.flag.IT {
  background-position: 0 -1610px;
}
.flag.JE {
  background-position: 0 -1624px;
}
.flag.JM {
  background-position: 0 -1638px;
}
.flag.JO {
  background-position: 0 -1652px;
}
.flag.JP {
  background-position: 0 -1666px;
}
.flag.KE {
  background-position: 0 -1680px;
}
.flag.KG {
  background-position: 0 -1694px;
}
.flag.KH {
  background-position: 0 -1708px;
}
.flag.KI {
  background-position: 0 -1722px;
}
.flag.KM {
  background-position: 0 -1736px;
}
.flag.KN {
  background-position: 0 -1750px;
}
.flag.KP {
  background-position: 0 -1764px;
}
.flag.KR {
  background-position: 0 -1778px;
}
.flag.KW {
  background-position: 0 -1792px;
}
.flag.AD {
  background-position: 0 -1806px;
}
.flag.KZ {
  background-position: 0 -1820px;
}
.flag.LA {
  background-position: 0 -1834px;
}
.flag.LB {
  background-position: 0 -1848px;
}
.flag.LC {
  background-position: 0 -1862px;
}
.flag.LI {
  background-position: 0 -1876px;
}
.flag.LK {
  background-position: 0 -1890px;
}
.flag.LR {
  background-position: 0 -1904px;
}
.flag.LS {
  background-position: 0 -1918px;
}
.flag.LT {
  background-position: 0 -1932px;
}
.flag.LU {
  background-position: 0 -1946px;
}
.flag.LV {
  background-position: 0 -1960px;
}
.flag.LY {
  background-position: 0 -1974px;
}
.flag.MA {
  background-position: 0 -1988px;
}
.flag.MC {
  background-position: 0 -2002px;
}
.flag.MD {
  background-position: 0 -2016px;
}
.flag.ME {
  background-position: 0 -2030px;
}
.flag.MF {
  background-position: 0 -2044px;
}
.flag.MG {
  background-position: 0 -2058px;
}
.flag.MH {
  background-position: 0 -2072px;
}
.flag.MK {
  background-position: 0 -2086px;
}
.flag.ML {
  background-position: 0 -2100px;
}
.flag.MM {
  background-position: 0 -2114px;
}
.flag.MN {
  background-position: 0 -2128px;
}
.flag.MO {
  background-position: 0 -2142px;
}
.flag.MP {
  background-position: 0 -2156px;
}
.flag.MQ {
  background-position: 0 -2170px;
}
.flag.MR {
  background-position: 0 -2184px;
}
.flag.MS {
  background-position: 0 -2198px;
}
.flag.MT {
  background-position: 0 -2212px;
}
.flag.MU {
  background-position: 0 -2226px;
}
.flag.MV {
  background-position: 0 -2240px;
}
.flag.MW {
  background-position: 0 -2254px;
}
.flag.MX {
  background-position: 0 -2268px;
}
.flag.MY {
  background-position: 0 -2282px;
}
.flag.MZ {
  background-position: 0 -2296px;
}
.flag.NA {
  background-position: 0 -2310px;
}
.flag.NC {
  background-position: 0 -2324px;
}
.flag.NE {
  background-position: 0 -2338px;
}
.flag.NF {
  background-position: 0 -2352px;
}
.flag.NG {
  background-position: 0 -2366px;
}
.flag.NI {
  background-position: 0 -2380px;
}
.flag.NL {
  background-position: 0 -2394px;
}
.flag.NO {
  background-position: 0 -2408px;
}
.flag.NP {
  background-position: 0 -2422px;
}
.flag.NR {
  background-position: 0 -2436px;
}
.flag.NU {
  background-position: 0 -2450px;
}
.flag.NZ {
  background-position: 0 -2464px;
}
.flag.OM {
  background-position: 0 -2478px;
}
.flag.PA {
  background-position: 0 -2492px;
}
.flag.PE {
  background-position: 0 -2506px;
}
.flag.PF {
  background-position: 0 -2520px;
}
.flag.PG {
  background-position: 0 -2534px;
}
.flag.PH {
  background-position: 0 -2548px;
}
.flag.PK {
  background-position: 0 -2562px;
}
.flag.PL {
  background-position: 0 -2576px;
}
.flag.PM {
  background-position: 0 -2590px;
}
.flag.PN {
  background-position: 0 -2604px;
}
.flag.PR {
  background-position: 0 -2618px;
}
.flag.PS {
  background-position: 0 -2632px;
}
.flag.PT {
  background-position: 0 -2646px;
}
.flag.PW {
  background-position: 0 -2660px;
}
.flag.PY {
  background-position: 0 -2674px;
}
.flag.QA {
  background-position: 0 -2688px;
}
.flag.RE {
  background-position: 0 -2702px;
}
.flag.RO {
  background-position: 0 -2716px;
}
.flag.RS {
  background-position: 0 -2730px;
}
.flag.RU {
  background-position: 0 -2744px;
}
.flag.RW {
  background-position: 0 -2758px;
}
.flag.SA {
  background-position: 0 -2772px;
}
.flag.SB {
  background-position: 0 -2786px;
}
.flag.SC {
  background-position: 0 -2800px;
}
.flag.SD {
  background-position: 0 -2814px;
}
.flag.SE {
  background-position: 0 -2828px;
}
.flag.SG {
  background-position: 0 -2842px;
}
.flag.SH {
  background-position: 0 -2856px;
}
.flag.SI {
  background-position: 0 -2870px;
}
.flag.SJ {
  background-position: 0 -2884px;
}
.flag.SK {
  background-position: 0 -2898px;
}
.flag.SL {
  background-position: 0 -2912px;
}
.flag.SM {
  background-position: 0 -2926px;
}
.flag.SN {
  background-position: 0 -2940px;
}
.flag.SO {
  background-position: 0 -2954px;
}
.flag.SR {
  background-position: 0 -2968px;
}
.flag.SS {
  background-position: 0 -2982px;
}
.flag.ST {
  background-position: 0 -2996px;
}
.flag.SV {
  background-position: 0 -3010px;
}
.flag.SX {
  background-position: 0 -3024px;
}
.flag.SY {
  background-position: 0 -3038px;
}
.flag.SZ {
  background-position: 0 -3052px;
}
.flag.TA {
  background-position: 0 -3066px;
}
.flag.TC {
  background-position: 0 -3080px;
}
.flag.TD {
  background-position: 0 -3094px;
}
.flag.TF {
  background-position: 0 -3108px;
}
.flag.TG {
  background-position: 0 -3122px;
}
.flag.TH {
  background-position: 0 -3136px;
}
.flag.TJ {
  background-position: 0 -3150px;
}
.flag.TK {
  background-position: 0 -3164px;
}
.flag.TL {
  background-position: 0 -3178px;
}
.flag.TM {
  background-position: 0 -3192px;
}
.flag.TN {
  background-position: 0 -3206px;
}
.flag.TO {
  background-position: 0 -3220px;
}
.flag.TR {
  background-position: 0 -3234px;
}
.flag.TT {
  background-position: 0 -3248px;
}
.flag.TV {
  background-position: 0 -3262px;
}
.flag.TW {
  background-position: 0 -3276px;
}
.flag.TZ {
  background-position: 0 -3290px;
}
.flag.UA {
  background-position: 0 -3304px;
}
.flag.UG {
  background-position: 0 -3318px;
}
.flag.UM {
  background-position: 0 -3332px;
}
.flag.UN {
  background-position: 0 -3346px;
}
.flag.US {
  background-position: 0 -3360px;
}
.flag.UY {
  background-position: 0 -3374px;
}
.flag.UZ {
  background-position: 0 -3388px;
}
.flag.VA {
  background-position: 0 -3402px;
}
.flag.VC {
  background-position: 0 -3416px;
}
.flag.VE {
  background-position: 0 -3430px;
}
.flag.VG {
  background-position: 0 -3444px;
}
.flag.VI {
  background-position: 0 -3458px;
}
.flag.VN {
  background-position: 0 -3472px;
}
.flag.VU {
  background-position: 0 -3486px;
}
.flag.WF {
  background-position: 0 -3500px;
}
.flag.WS {
  background-position: 0 -3514px;
}
.flag.XK {
  background-position: 0 -3528px;
}
.flag.YE {
  background-position: 0 -3542px;
}
.flag.YT {
  background-position: 0 -3556px;
}
.flag.ZA {
  background-position: 0 -3570px;
}
.flag.ZM {
  background-position: 0 -3584px;
}
.flag.ZW {
  background-position: 0 -3598px;
}

ngx-mat-select-search .mat-datepicker-content {
  box-shadow: none !important;
}
ngx-mat-select-search .mat-select-search-clear {
  width: 24px !important;
  height: 24px !important;
  top: 8px !important;
  right: 8px !important;
}
ngx-mat-select-search .mat-select-search-clear mat-icon {
  width: 20px;
  height: 20px;
  font-size: 20px;
}

.container:not(.responsive) {
  width: 100%;
  max-width: 100% !important;
}

body {
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
body .mat-chip.mat-standard-chip {
  background-color: var(--b3n-highlight, #424242);
}
body .mat-drawer-side.mat-drawer-end {
  border: 1px solid var(--b3n-border, #e0e0e0);
}
body .mat-input-element::-webkit-input-placeholder,
body .mat-input-element::placeholder {
  color: var(--b3n-secondary-color, rgba(0, 0, 0, 0.5411764706)) !important;
}
body .cdk-overlay-container .cdk-overlay-pane {
  background: inherit !important;
}
body .color-link {
  color: var(--b3n-link, #1979b1);
}
body input:-webkit-autofill,
body input:-webkit-autofill:hover,
body input:-webkit-autofill:focus,
body input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--b3n-color, rgba(0, 0, 0, 0.8705882353));
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px var(--b3n-background, #ffffff);
}
body input::-webkit-outer-spin-button,
body input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
body input[type=number] {
  -moz-appearance: textfield;
}

body:not(.is-mobile) ::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

body:not(.is-mobile) ::-webkit-scrollbar-thumb {
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  border: unset !important;
  background-color: var(--b3n-scrollbar-thumb, #bdbdbd) !important;
}

body:not(.is-mobile) ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent !important;
  box-shadow: inset 0 0 6px transparent !important;
  background-color: transparent !important;
  border-radius: 12px;
}

.theme-default {
  background-color: var(--b3n-background, #ffffff);
  color: var(--b3n-color, rgba(0, 0, 0, 0.8705882353));
  --b3n-primary: #1979b1;
  --b3n-primary-text: white;
  --b3n-warn: #f44336;
  font-size: 14px;
  font-family: "Inter";
  letter-spacing: -0.02px;
  line-height: 24px;
  /**
    config density
    default is 0 or maximum
    maximum, 0, -1, -2, -3, -4, 0
  */
  --mdc-shape-small: 6px;
  --mdc-dialog-container-shape: 6px;
}
.theme-default .mat-ripple-element {
  background-color: var(--b3n-highlight, #f5f5f5);
  opacity: 0.1;
}
.theme-default .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .mat-mdc-option:hover:not(.mdc-list-item--disabled), .theme-default .mat-mdc-option:focus:not(.mdc-list-item--disabled), .theme-default .mat-mdc-option.mat-mdc-option-active, .theme-default .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.theme-default .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #1979b1);
}
.theme-default .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #039be5);
}
.theme-default .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .mat-pseudo-checkbox-full {
  color: var(--b3n-secondary-color, rgba(0, 0, 0, 0.8705882353));
}
.theme-default .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.theme-default .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.theme-default .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #1979b1;
}
.theme-default .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.theme-default .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #1979b1;
}
.theme-default .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.theme-default .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: white;
}
.theme-default .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.theme-default .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #039be5;
}
.theme-default .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.theme-default .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #039be5;
}
.theme-default .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.theme-default .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: white;
}
.theme-default .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.theme-default .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #039be5;
}
.theme-default .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.theme-default .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #039be5;
}
.theme-default .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.theme-default .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: white;
}
.theme-default .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.theme-default .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.theme-default .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.theme-default .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.theme-default .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.theme-default .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: white;
}
.theme-default .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.theme-default .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.theme-default .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.theme-default .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}
.theme-default .mat-app-background, .theme-default.mat-app-background {
  background-color: white;
  color: var(--b3n-color, rgba(0, 0, 0, 0.8705882353));
}
.theme-default .mat-elevation-z0, .theme-default .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z1, .theme-default .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z2, .theme-default .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z3, .theme-default .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z4, .theme-default .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z5, .theme-default .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z6, .theme-default .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z7, .theme-default .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z8, .theme-default .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z9, .theme-default .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z10, .theme-default .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z11, .theme-default .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z12, .theme-default .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z13, .theme-default .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z14, .theme-default .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z15, .theme-default .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z16, .theme-default .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z17, .theme-default .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z18, .theme-default .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z19, .theme-default .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z20, .theme-default .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z21, .theme-default .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z22, .theme-default .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z23, .theme-default .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-elevation-z24, .theme-default .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-default .mat-mdc-option {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-body1-font-size, 1em);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, -0.02);
}
.theme-default .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: var(--b3n-background, #ffffff);
}
.theme-default .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #000;
}
.theme-default .mat-mdc-card-subtitle {
  color: var(--b3n-secondary-color, rgba(0, 0, 0, 0.8705882353));
}
.theme-default .mat-mdc-card-title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-headline6-font-size, 20px);
  line-height: var(--mdc-typography-headline6-line-height, 32px);
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: var(--mdc-typography-headline6-text-transform, none);
}
.theme-default .mat-mdc-card-subtitle {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-subtitle2-font-size, 12px);
  line-height: var(--mdc-typography-subtitle2-line-height, 24px);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 550);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}
.theme-default .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #1979b1;
}
.theme-default .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(25, 121, 177, 0.25)'/%3E%3C/svg%3E");
}
.theme-default .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(25, 121, 177, 0.25);
}
.theme-default .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #039be5;
}
.theme-default .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(3, 155, 229, 0.25)'/%3E%3C/svg%3E");
}
.theme-default .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(3, 155, 229, 0.25);
}
.theme-default .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
}
.theme-default .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
}
.theme-default .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
}
.theme-default .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.theme-default .mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: Inter;
  --mdc-plain-tooltip-supporting-text-size: 10.5px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: -0.02;
}
.theme-default .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.theme-default .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .theme-default .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .theme-default .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.theme-default .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #1979b1);
}
.theme-default .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.theme-default .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.theme-default .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.theme-default .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.theme-default .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.theme-default .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-default .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.theme-default .mdc-text-field--filled .mdc-text-field__ripple::before,
.theme-default .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.theme-default .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .theme-default .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.theme-default .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .theme-default .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.theme-default .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #000;
}
.theme-default .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.theme-default .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.theme-default .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #1979b1);
}
.theme-default .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-default .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-default .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.theme-default .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-default .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-default .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.theme-default .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #1979b1);
}
[dir=rtl] .theme-default .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .theme-default .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-default .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .theme-default .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-default .mdc-text-field--outlined .mdc-text-field__ripple::before,
.theme-default .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .theme-default .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .theme-default .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-default .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(25, 121, 177, 0.87);
}
.theme-default .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.theme-default .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.theme-default .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.theme-default .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-default .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-default .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-default .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-default .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .theme-default .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .theme-default .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.theme-default .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.theme-default .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.theme-default .mdc-text-field--disabled .mdc-text-field-character-counter,
.theme-default .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.theme-default .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.theme-default .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.theme-default .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-default .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.theme-default .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.theme-default .mdc-text-field--disabled .mdc-notched-outline__leading,
.theme-default .mdc-text-field--disabled .mdc-notched-outline__notch,
.theme-default .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .mdc-text-field--disabled .mdc-text-field-character-counter,
  .theme-default .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .theme-default .mdc-text-field--disabled .mdc-notched-outline__leading,
  .theme-default .mdc-text-field--disabled .mdc-notched-outline__notch,
  .theme-default .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.theme-default .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #000;
}
[dir=rtl] .theme-default .mdc-text-field--end-aligned .mdc-text-field__input, .theme-default .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-default .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .theme-default .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-default .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
.theme-default .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
.theme-default .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
.theme-default .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.theme-default .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(25, 121, 177, 0.87);
}
.theme-default .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(3, 155, 229, 0.87);
}
.theme-default .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.theme-default .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.theme-default .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #039be5);
}
.theme-default .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #039be5);
}
.theme-default .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(3, 155, 229, 0.87);
}
.theme-default .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-default .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-default .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-default .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-default .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #039be5);
}
.theme-default .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(244, 67, 54, 0.87);
}
.theme-default .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.theme-default .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.theme-default .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.theme-default .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.theme-default .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.theme-default .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.theme-default .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.theme-default .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.theme-default [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.theme-default .mat-mdc-form-field-infix {
  min-height: 56px;
}
.theme-default .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}
.theme-default .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.theme-default .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}
.theme-default .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}
.theme-default .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}
.theme-default .mdc-text-field__input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-subtitle1-font-size, 1em);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
.theme-default .mdc-text-field__affix {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-subtitle1-font-size, 1em);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
[dir=rtl] .theme-default .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .theme-default .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .theme-default .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .theme-default .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-default .mdc-text-field--textarea .mdc-text-field__input {
  line-height: 1.5rem;
}
[dir=rtl] .theme-default .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .theme-default .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
[dir=rtl] .theme-default .mdc-text-field--end-aligned .mdc-text-field__input, .theme-default .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-default .mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-subtitle1-font-size, 1em);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
[dir=rtl] .theme-default .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .theme-default .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.theme-default .mat-mdc-form-field-subscript-wrapper,
.theme-default .mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-caption-font-size, 10.5px);
  line-height: var(--mdc-typography-caption-line-height, 12px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}
.theme-default .mat-mdc-form-field,
.theme-default .mat-mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-body1-font-size, 1em);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}
.theme-default .mat-mdc-form-field .mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: calc(1em * var(--mat-mdc-form-field-floating-label-scale, 0.75));
}
.theme-default .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1em;
}
.theme-default .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, var(--b3n-background, #ffffff));
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-default .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-default .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-default .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-default .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-default .mdc-list-item--disabled .mdc-list-item__start,
.theme-default .mdc-list-item--disabled .mdc-list-item__content,
.theme-default .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-default .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #1979b1);
}
.theme-default .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #1979b1);
}
.theme-default .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-default .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-default .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.theme-default .mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.theme-default .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.theme-default .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.theme-default .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(25, 121, 177, 0.87);
}
.theme-default .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(3, 155, 229, 0.87);
}
.theme-default .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.theme-default .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.theme-default .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.theme-default .mat-mdc-select-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-subtitle1-font-size, 1em);
  line-height: var(--mdc-typography-subtitle1-line-height, 24px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}
.theme-default .mat-mdc-select {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-body1-font-size, 1em);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}
.theme-default .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, var(--b3n-background, #ffffff));
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-default .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-default .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-default .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-default .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-default .mdc-list-item--disabled .mdc-list-item__start,
.theme-default .mdc-list-item--disabled .mdc-list-item__content,
.theme-default .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-default .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #1979b1);
}
.theme-default .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #1979b1);
}
.theme-default .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-default .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-default .mat-mdc-autocomplete-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-subtitle1-font-size, 1em);
  line-height: var(--mdc-typography-subtitle1-line-height, 24px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}
.theme-default .mat-mdc-dialog-container {
  --mdc-dialog-container-color: var(--b3n-background, #ffffff);
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
.theme-default .mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: Inter;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Inter;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 1em;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: -0.02;
}
.theme-default .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #000;
  --mdc-chip-elevated-disabled-container-color: #000;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.theme-default .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .theme-default .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #1979b1;
  --mdc-chip-elevated-disabled-container-color: #1979b1;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-default .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .theme-default .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #039be5;
  --mdc-chip-elevated-disabled-container-color: #039be5;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-default .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .theme-default .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.theme-default .mat-mdc-chip-focus-overlay {
  background: var(--b3n-highlight, #f5f5f5);
}
.theme-default .mat-mdc-chip {
  height: 32px;
}
.theme-default .mat-mdc-standard-chip {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 24px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.theme-default .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.theme-default .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: var(--b3n-disabled, rgba(0, 0, 0, 0.3803921569));
}
.theme-default .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #1e88e5;
  --mdc-switch-selected-handle-color: #1e88e5;
  --mdc-switch-selected-hover-state-layer-color: #1e88e5;
  --mdc-switch-selected-pressed-state-layer-color: #1e88e5;
  --mdc-switch-selected-focus-handle-color: #0d47a1;
  --mdc-switch-selected-hover-handle-color: #0d47a1;
  --mdc-switch-selected-pressed-handle-color: #0d47a1;
  --mdc-switch-selected-focus-track-color: #64b5f6;
  --mdc-switch-selected-hover-track-color: #64b5f6;
  --mdc-switch-selected-pressed-track-color: #64b5f6;
  --mdc-switch-selected-track-color: #64b5f6;
}
.theme-default .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #039be5;
  --mdc-switch-selected-handle-color: #039be5;
  --mdc-switch-selected-hover-state-layer-color: #039be5;
  --mdc-switch-selected-pressed-state-layer-color: #039be5;
  --mdc-switch-selected-focus-handle-color: #01579b;
  --mdc-switch-selected-hover-handle-color: #01579b;
  --mdc-switch-selected-pressed-handle-color: #01579b;
  --mdc-switch-selected-focus-track-color: #4fc3f7;
  --mdc-switch-selected-hover-track-color: #4fc3f7;
  --mdc-switch-selected-pressed-track-color: #4fc3f7;
  --mdc-switch-selected-track-color: #4fc3f7;
}
.theme-default .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}
.theme-default .mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}
.theme-default .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 24px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.theme-default .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #1979b1;
  --mdc-radio-selected-hover-icon-color: #1979b1;
  --mdc-radio-selected-icon-color: #1979b1;
  --mdc-radio-selected-pressed-icon-color: #1979b1;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #1979b1;
}
.theme-default .mat-mdc-radio-button.mat-primary .mdc-radio--disabled + label {
  color: var(--b3n-disabled, rgba(0, 0, 0, 0.3803921569));
}
.theme-default .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #039be5;
  --mdc-radio-selected-hover-icon-color: #039be5;
  --mdc-radio-selected-icon-color: #039be5;
  --mdc-radio-selected-pressed-icon-color: #039be5;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #039be5;
}
.theme-default .mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label {
  color: var(--b3n-disabled, rgba(0, 0, 0, 0.3803921569));
}
.theme-default .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #f44336;
}
.theme-default .mat-mdc-radio-button.mat-warn .mdc-radio--disabled + label {
  color: var(--b3n-disabled, rgba(0, 0, 0, 0.3803921569));
}
.theme-default .mat-mdc-radio-button .mdc-radio {
  padding: calc((40px - 20px) / 2);
}
.theme-default .mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  top: calc(-1 * (40px - 20px) / 2);
  left: calc(-1 * (40px - 20px) / 2);
  width: 40px;
  height: 40px;
}
.theme-default .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control {
  top: calc((40px - 40px) / 2);
  right: calc((40px - 40px) / 2);
  left: calc((40px - 40px) / 2);
  width: 40px;
  height: 40px;
}
.theme-default .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 24px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.theme-default .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.theme-default .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #1979b1;
  --mdc-slider-focus-handle-color: #1979b1;
  --mdc-slider-hover-handle-color: #1979b1;
  --mdc-slider-active-track-color: #1979b1;
  --mdc-slider-inactive-track-color: #1979b1;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #1979b1;
  --mat-mdc-slider-ripple-color: #1979b1;
  --mat-mdc-slider-hover-ripple-color: rgba(25, 121, 177, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(25, 121, 177, 0.2);
}
.theme-default .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #039be5;
  --mdc-slider-focus-handle-color: #039be5;
  --mdc-slider-hover-handle-color: #039be5;
  --mdc-slider-active-track-color: #039be5;
  --mdc-slider-inactive-track-color: #039be5;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #039be5;
  --mat-mdc-slider-ripple-color: #039be5;
  --mat-mdc-slider-hover-ripple-color: rgba(3, 155, 229, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(3, 155, 229, 0.2);
}
.theme-default .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}
.theme-default .mat-mdc-slider {
  --mdc-slider-label-label-text-font: Inter;
  --mdc-slider-label-label-text-size: 12px;
  --mdc-slider-label-label-text-line-height: 24px;
  --mdc-slider-label-label-text-tracking: -0.02;
  --mdc-slider-label-label-text-weight: 550;
}
.theme-default .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, var(--b3n-background, #ffffff));
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
.theme-default .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-default .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.theme-default .mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.theme-default .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
.theme-default .mdc-list-item--disabled .mdc-list-item__start,
.theme-default .mdc-list-item--disabled .mdc-list-item__content,
.theme-default .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.theme-default .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.theme-default .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #1979b1);
}
.theme-default .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #1979b1);
}
.theme-default .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .mdc-list-divider::after {
  border-bottom-color: white;
}
.theme-default .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-default .mat-mdc-menu-item[disabled],
.theme-default .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.theme-default .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}
.theme-default .mat-mdc-menu-item .mat-icon-no-color,
.theme-default .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .mat-mdc-menu-item:hover:not([disabled]),
.theme-default .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.theme-default .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-default .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.theme-default .mat-mdc-menu-content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-subtitle1-font-size, 1em);
  line-height: var(--mdc-typography-subtitle1-line-height, 24px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}
.theme-default .mat-mdc-menu-content,
.theme-default .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-body1-font-size, 1em);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}
.theme-default .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: var(--b3n-color, rgba(0, 0, 0, 0.8705882353));
  --mdc-list-list-item-supporting-text-color: var(--b3n-secondary-color, rgba(0, 0, 0, 0.8705882353));
  --mdc-list-list-item-leading-icon-color: var(--b3n-highlight, #f5f5f5);
  --mdc-list-list-item-trailing-supporting-text-color: var(--b3n-disabled, rgba(0, 0, 0, 0.3803921569));
  --mdc-list-list-item-trailing-icon-color: var(--b3n-highlight, #f5f5f5);
  --mdc-list-list-item-selected-trailing-icon-color: var(--b3n-highlight, #f5f5f5);
  --mdc-list-list-item-disabled-label-text-color: var(--b3n-highlight, #f5f5f5);
  --mdc-list-list-item-disabled-leading-icon-color: var(--b3n-highlight, #f5f5f5);
  --mdc-list-list-item-disabled-trailing-icon-color: var(--b3n-highlight, #f5f5f5);
  --mdc-list-list-item-hover-label-text-color: var(--b3n-color, rgba(0, 0, 0, 0.8705882353));
  --mdc-list-list-item-hover-leading-icon-color: var(--b3n-highlight, #f5f5f5);
  --mdc-list-list-item-hover-trailing-icon-color: var(--b3n-highlight, #f5f5f5);
  --mdc-list-list-item-focus-label-text-color: var(--b3n-color, rgba(0, 0, 0, 0.8705882353));
  --mdc-list-list-item-hover-state-layer-color: var(--b3n-highlight, #f5f5f5);
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: var(--b3n-highlight, #f5f5f5);
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.theme-default .mat-mdc-list-option .mdc-list-item__start, .theme-default .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1979b1;
  --mdc-checkbox-selected-hover-icon-color: #1979b1;
  --mdc-checkbox-selected-icon-color: #1979b1;
  --mdc-checkbox-selected-pressed-icon-color: #1979b1;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-default .mat-mdc-list-option .mdc-list-item__start, .theme-default .mat-mdc-list-option .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #1979b1;
  --mdc-radio-selected-hover-icon-color: #1979b1;
  --mdc-radio-selected-icon-color: #1979b1;
  --mdc-radio-selected-pressed-icon-color: #1979b1;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #1979b1;
}
.theme-default .mat-mdc-list-option .mdc-list-item__start .mdc-radio--disabled + label, .theme-default .mat-mdc-list-option .mdc-list-item__end .mdc-radio--disabled + label {
  color: var(--b3n-disabled, rgba(0, 0, 0, 0.3803921569));
}
.theme-default .mat-mdc-list-option.mat-accent .mdc-list-item__start, .theme-default .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #039be5;
  --mdc-checkbox-selected-hover-icon-color: #039be5;
  --mdc-checkbox-selected-icon-color: #039be5;
  --mdc-checkbox-selected-pressed-icon-color: #039be5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-default .mat-mdc-list-option.mat-accent .mdc-list-item__start, .theme-default .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #039be5;
  --mdc-radio-selected-hover-icon-color: #039be5;
  --mdc-radio-selected-icon-color: #039be5;
  --mdc-radio-selected-pressed-icon-color: #039be5;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #039be5;
}
.theme-default .mat-mdc-list-option.mat-accent .mdc-list-item__start .mdc-radio--disabled + label, .theme-default .mat-mdc-list-option.mat-accent .mdc-list-item__end .mdc-radio--disabled + label {
  color: var(--b3n-disabled, rgba(0, 0, 0, 0.3803921569));
}
.theme-default .mat-mdc-list-option.mat-warn .mdc-list-item__start, .theme-default .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-default .mat-mdc-list-option.mat-warn .mdc-list-item__start, .theme-default .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #f44336;
}
.theme-default .mat-mdc-list-option.mat-warn .mdc-list-item__start .mdc-radio--disabled + label, .theme-default .mat-mdc-list-option.mat-warn .mdc-list-item__end .mdc-radio--disabled + label {
  color: var(--b3n-disabled, rgba(0, 0, 0, 0.3803921569));
}
.theme-default .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-default .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #1979b1;
}
.theme-default .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-default .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #1979b1;
}
.theme-default .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-default .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-default .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-default .mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}
.theme-default .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .theme-default .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .theme-default .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.theme-default .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .theme-default .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .theme-default .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}
.theme-default .mat-mdc-list-base {
  --mdc-list-list-item-label-text-font: Inter;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 1em;
  --mdc-list-list-item-label-text-tracking: -0.02;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Inter;
  --mdc-list-list-item-supporting-text-line-height: 24px;
  --mdc-list-list-item-supporting-text-size: 1em;
  --mdc-list-list-item-supporting-text-tracking: -0.02;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Inter;
  --mdc-list-list-item-trailing-supporting-text-line-height: 12px;
  --mdc-list-list-item-trailing-supporting-text-size: 10.5px;
  --mdc-list-list-item-trailing-supporting-text-tracking: -0.02;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}
.theme-default .mdc-list-group__subheader {
  font-size: 1em;
  font-weight: 400;
  line-height: 24px;
  font-family: Inter;
  letter-spacing: -0.02;
}
.theme-default .mat-mdc-paginator {
  background: var(--b3n-background, #ffffff);
  color: rgba(var(--b3n-highlight, #f5f5f5), 0.87);
}
.theme-default .mat-mdc-paginator-icon {
  fill: rgba(var(--b3n-highlight, #f5f5f5), 0.54);
}
.theme-default .mat-mdc-paginator-decrement,
.theme-default .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(var(--b3n-highlight, #f5f5f5), 0.54);
  border-right: 2px solid rgba(var(--b3n-highlight, #f5f5f5), 0.54);
}
.theme-default .mat-mdc-paginator-first,
.theme-default .mat-mdc-paginator-last {
  border-top: 2px solid rgba(var(--b3n-highlight, #f5f5f5), 0.54);
}
.theme-default .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.theme-default .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.theme-default .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.theme-default .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(var(--b3n-highlight, #f5f5f5), 0.12);
}
.theme-default .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(var(--b3n-highlight, #f5f5f5), 0.12);
}
.theme-default .mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.theme-default .mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.theme-default .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.theme-default .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.theme-default .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.theme-default .mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.theme-default .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}
.theme-default .mat-mdc-paginator-container {
  min-height: 56px;
}
.theme-default .mat-mdc-paginator {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-caption-font-size, 10.5px);
  line-height: var(--mdc-typography-caption-line-height, 12px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}
.theme-default .mat-mdc-paginator .mat-mdc-select-value {
  font-size: 10.5px;
}
.theme-default .mat-mdc-tab, .theme-default .mat-mdc-tab-link {
  background-color: transparent;
}
.theme-default .mat-mdc-tab .mdc-tab__text-label, .theme-default .mat-mdc-tab-link .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.theme-default .mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.theme-default .mat-mdc-tab.mat-mdc-tab-disabled .mat-ripple-element, .theme-default .mat-mdc-tab-link.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.theme-default .mat-mdc-tab-link.mat-mdc-tab-disabled .mat-ripple-element {
  background-color: var(--b3n-disabled, rgba(0, 0, 0, 0.3803921569));
}
.theme-default .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-default .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #1979b1;
}
.theme-default .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-default .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #1979b1);
}
.theme-default .mdc-tab__ripple::before,
.theme-default .mat-mdc-tab .mat-ripple-element,
.theme-default .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default .mat-mdc-tab-link .mat-ripple-element {
  background-color: #1979b1;
}
.theme-default .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-default .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-default .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-default .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #039be5;
}
.theme-default .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-default .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-default .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-default .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #039be5);
}
.theme-default .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.theme-default .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-default .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .theme-default .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.theme-default .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.theme-default .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #039be5;
}
.theme-default .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-default .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-default .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .theme-default .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #f44336;
}
.theme-default .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-default .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-default .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .theme-default .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #f44336);
}
.theme-default .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.theme-default .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-default .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .theme-default .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.theme-default .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.theme-default .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.theme-default .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #f44336;
}
.theme-default .mat-mdc-tab-group.mat-background-primary, .theme-default .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #1979b1;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-default .mat-mdc-tab-group.mat-background-accent, .theme-default .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #039be5;
  --mat-mdc-tab-header-with-background-foreground-color: #000;
}
.theme-default .mat-mdc-tab-group.mat-background-warn, .theme-default .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #f44336;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.theme-default .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #000);
}
.theme-default .mat-mdc-tab-header .mdc-tab {
  height: 48px;
}
.theme-default .mdc-tab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-button-font-size, 1em);
  line-height: var(--mdc-typography-button-line-height, 36px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.theme-default .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.theme-default .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.theme-default .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #000;
}
.theme-default .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1979b1;
  --mdc-checkbox-selected-hover-icon-color: #1979b1;
  --mdc-checkbox-selected-icon-color: #1979b1;
  --mdc-checkbox-selected-pressed-icon-color: #1979b1;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-default .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(25, 121, 177, 0.1);
}
.theme-default .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #1979b1;
}
.theme-default .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #039be5;
  --mdc-checkbox-selected-hover-icon-color: #039be5;
  --mdc-checkbox-selected-icon-color: #039be5;
  --mdc-checkbox-selected-pressed-icon-color: #039be5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-default .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(3, 155, 229, 0.1);
}
.theme-default .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #039be5;
}
.theme-default .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.theme-default .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(244, 67, 54, 0.1);
}
.theme-default .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #f44336;
}
.theme-default .mat-mdc-checkbox-disabled label {
  color: var(--b3n-disabled, rgba(0, 0, 0, 0.3803921569));
}
.theme-default .mat-mdc-checkbox .mdc-checkbox {
  padding: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  margin: calc((var(--mdc-checkbox-touch-target-size, 40px) - 40px) / 2);
}
.theme-default .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  top: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  left: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
}
.theme-default .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
  top: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  right: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  left: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  width: var(--mdc-checkbox-touch-target-size, 40px);
  height: var(--mdc-checkbox-touch-target-size, 40px);
}
@media all and (-ms-high-contrast: none) {
  .theme-default .mdc-checkbox .mdc-checkbox__focus-ring {
    display: none;
  }
}
.theme-default .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 24px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.theme-default .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.theme-default .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #1979b1;
}
.theme-default .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #039be5;
}
.theme-default .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.theme-default .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.theme-default .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: var(--b3n-background, #ffffff);
  --mdc-filled-button-label-text-color: #000;
}
.theme-default .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #1979b1;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-default .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #039be5;
  --mdc-filled-button-label-text-color: #000;
}
.theme-default .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-default .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.theme-default .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: var(--b3n-background, #ffffff);
  --mdc-protected-button-label-text-color: #000;
}
.theme-default .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #1979b1;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-default .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #039be5;
  --mdc-protected-button-label-text-color: #000;
}
.theme-default .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-default .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.theme-default .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.theme-default .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.theme-default .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #1979b1;
}
.theme-default .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #039be5;
}
.theme-default .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.theme-default .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.theme-default .mat-mdc-button, .theme-default .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-default .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .theme-default .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-default .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-default .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-default .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-default .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-default .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .theme-default .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-default .mat-mdc-button.mat-primary, .theme-default .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #1979b1;
  --mat-mdc-button-ripple-color: rgba(25, 121, 177, 0.1);
}
.theme-default .mat-mdc-button.mat-accent, .theme-default .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #039be5;
  --mat-mdc-button-ripple-color: rgba(3, 155, 229, 0.1);
}
.theme-default .mat-mdc-button.mat-warn, .theme-default .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.theme-default .mat-mdc-raised-button, .theme-default .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-default .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .theme-default .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-default .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-default .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-default .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-default .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-default .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .theme-default .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-default .mat-mdc-raised-button.mat-primary, .theme-default .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .mat-mdc-raised-button.mat-accent, .theme-default .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-default .mat-mdc-raised-button.mat-warn, .theme-default .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .mat-mdc-button.mat-mdc-button-base,
.theme-default .mat-mdc-raised-button.mat-mdc-button-base,
.theme-default .mat-mdc-unelevated-button.mat-mdc-button-base,
.theme-default .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}
.theme-default .mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-button-font-size, 1em);
  line-height: var(--mdc-typography-button-line-height, 36px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.theme-default .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-default .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-default .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-default .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-default .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-default .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #1979b1;
  --mat-mdc-button-ripple-color: rgba(25, 121, 177, 0.1);
}
.theme-default .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #039be5;
  --mat-mdc-button-ripple-color: rgba(3, 155, 229, 0.1);
}
.theme-default .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.theme-default .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #1979b1;
}
.theme-default .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #039be5;
}
.theme-default .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
}
.theme-default .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.theme-default .mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}
.theme-default .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
}
.theme-default .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.theme-default .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.theme-default .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}
.theme-default .mat-mdc-fab, .theme-default .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-default .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .theme-default .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.theme-default .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-default .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-default .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-default .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-default .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .theme-default .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.theme-default .mat-mdc-fab.mat-primary, .theme-default .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .mat-mdc-fab.mat-accent, .theme-default .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.theme-default .mat-mdc-fab.mat-warn, .theme-default .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-default .mat-mdc-fab.mat-unthemed, .theme-default .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: var(--b3n-background, #ffffff);
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.theme-default .mat-mdc-fab.mat-primary, .theme-default .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #1979b1;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-default .mat-mdc-fab.mat-accent, .theme-default .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #039be5;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.theme-default .mat-mdc-fab.mat-warn, .theme-default .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.theme-default .mat-mdc-fab[disabled][disabled], .theme-default .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.theme-default .mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-button-font-size, 1em);
  line-height: var(--mdc-typography-button-line-height, 36px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.theme-default .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #039be5;
  --mdc-snackbar-container-color: #000;
  --mdc-snackbar-supporting-text-color: var(--b3n-background, #ffffff);
}
.theme-default .mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: Inter;
  --mdc-snackbar-supporting-text-line-height: 24px;
  --mdc-snackbar-supporting-text-size: 1em;
  --mdc-snackbar-supporting-text-weight: 400;
}
.theme-default .mdc-data-table {
  background-color: var(--mdc-theme-surface, var(--b3n-background, #ffffff));
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-default .mdc-data-table__row {
  background-color: inherit;
}
.theme-default .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, var(--b3n-background, #ffffff));
}
.theme-default .mdc-data-table__row--selected {
  background-color: rgba(25, 121, 177, 0.04);
}
.theme-default .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.theme-default .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.theme-default .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.theme-default .mdc-data-table__cell,
.theme-default .mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.theme-default .mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.theme-default .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.theme-default .mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}
.theme-default .mdc-data-table__pagination-total,
.theme-default .mdc-data-table__pagination-rows-per-page-label,
.theme-default .mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}
[dir=rtl] .theme-default .mdc-data-table__pagination-button .mdc-button__icon, .theme-default .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-default .mat-mdc-table {
  background: var(--b3n-background, #ffffff);
}
.theme-default .mat-mdc-table .mdc-data-table__row {
  height: 52px;
}
.theme-default .mat-mdc-table .mdc-data-table__pagination {
  min-height: 52px;
}
.theme-default .mat-mdc-table .mdc-data-table__header-row {
  height: 56px;
}
.theme-default .mdc-data-table__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 24px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.theme-default .mdc-data-table__cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 24px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.theme-default .mdc-data-table__header-cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-subtitle2-font-size, 12px);
  line-height: var(--mdc-typography-subtitle2-line-height, 24px);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 550);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}
[dir=rtl] .theme-default .mdc-data-table__pagination-button .mdc-button__icon, .theme-default .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.theme-default .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #1979b1;
}
.theme-default .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #039be5;
}
.theme-default .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}
.theme-default .mat-badge {
  position: relative;
}
.theme-default .mat-badge.mat-badge {
  overflow: visible;
}
.theme-default .mat-badge-hidden .mat-badge-content {
  display: none;
}
.theme-default .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.theme-default .ng-animate-disabled .mat-badge-content,
.theme-default .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.theme-default .mat-badge-content.mat-badge-active {
  transform: none;
}
.theme-default .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.theme-default .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.theme-default .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.theme-default .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .theme-default .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.theme-default .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .theme-default .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.theme-default .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .theme-default .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.theme-default .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .theme-default .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.theme-default .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.theme-default .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.theme-default .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.theme-default .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .theme-default .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.theme-default .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .theme-default .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.theme-default .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .theme-default .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.theme-default .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .theme-default .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.theme-default .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.theme-default .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.theme-default .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.theme-default .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .theme-default .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.theme-default .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .theme-default .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.theme-default .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .theme-default .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.theme-default .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .theme-default .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.theme-default .mat-badge-content {
  color: white;
  background: #1979b1;
}
.cdk-high-contrast-active .theme-default .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-default .mat-badge-accent .mat-badge-content {
  background: #039be5;
  color: white;
}
.theme-default .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.theme-default .mat-badge-disabled .mat-badge-content {
  background: var(--b3n-disabled, rgba(0, 0, 0, 0.8705882353));
  color: var(--b3n-disabled, rgba(0, 0, 0, 0.3803921569));
}
.theme-default .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Inter;
}
.theme-default .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.theme-default .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.theme-default .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: var(--b3n-background, #ffffff);
  color: var(--b3n-color, rgba(0, 0, 0, 0.8705882353));
}
.theme-default .mat-bottom-sheet-container {
  font-size: 1em;
  font-weight: 400;
  line-height: 24px;
  font-family: Inter;
  letter-spacing: -0.02;
}
.theme-default .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.theme-default .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.theme-default .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.theme-default .mat-button-toggle {
  color: var(--b3n-disabled, rgba(0, 0, 0, 0.3803921569));
}
.theme-default .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.theme-default .mat-button-toggle-appearance-standard {
  color: var(--b3n-color, rgba(0, 0, 0, 0.8705882353));
  background: var(--b3n-background, #ffffff);
}
.theme-default .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.theme-default .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px var(--b3n-border, #e0e0e0);
}
.theme-default [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px var(--b3n-border, #e0e0e0);
}
.theme-default .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px var(--b3n-border, #e0e0e0);
}
.theme-default .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: var(--b3n-secondary-color, rgba(0, 0, 0, 0.8705882353));
}
.theme-default .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: var(--b3n-color, rgba(0, 0, 0, 0.8705882353));
}
.theme-default .mat-button-toggle-disabled {
  color: var(--b3n-disabled, rgba(0, 0, 0, 0.8705882353));
  background-color: #eeeeee;
}
.theme-default .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: var(--b3n-background, #ffffff);
}
.theme-default .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.theme-default .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-default .mat-button-toggle-group-appearance-standard {
  border: solid 1px var(--b3n-border, #e0e0e0);
}
.theme-default .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}
.theme-default .mat-button-toggle {
  font-family: Inter;
}
.theme-default .mat-calendar-arrow {
  fill: var(--b3n-icon-color, #888);
}
.theme-default .mat-datepicker-toggle,
.theme-default .mat-datepicker-content .mat-calendar-next-button,
.theme-default .mat-datepicker-content .mat-calendar-previous-button {
  color: var(--b3n-icon-color, #888);
}
.theme-default .mat-calendar-table-header-divider::after {
  background: var(--b3n-border, #e0e0e0);
}
.theme-default .mat-calendar-table-header,
.theme-default .mat-calendar-body-label {
  color: var(--b3n-secondary-color, rgba(0, 0, 0, 0.8705882353));
}
.theme-default .mat-calendar-body-cell-content,
.theme-default .mat-date-range-input-separator {
  color: var(--b3n-color, rgba(0, 0, 0, 0.8705882353));
  border-color: transparent;
}
.theme-default .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: var(--b3n-disabled, rgba(0, 0, 0, 0.3803921569));
}
.theme-default .mat-form-field-disabled .mat-date-range-input-separator {
  color: var(--b3n-disabled, rgba(0, 0, 0, 0.3803921569));
}
.theme-default .mat-calendar-body-in-preview {
  color: var(--b3n-border, #e0e0e0);
}
.theme-default .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: var(--b3n-disabled, rgba(0, 0, 0, 0.3803921569));
}
.theme-default .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  opacity: 0.5;
}
.theme-default .mat-calendar-body-in-range::before {
  background: rgba(25, 121, 177, 0.2);
}
.theme-default .mat-calendar-body-comparison-identical,
.theme-default .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-default .mat-calendar-body-comparison-bridge-start::before,
.theme-default [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(25, 121, 177, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-default .mat-calendar-body-comparison-bridge-end::before,
.theme-default [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(25, 121, 177, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-default .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-default .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-default .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-default .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-default .mat-calendar-body-selected {
  background-color: #1979b1;
  color: white;
}
.theme-default .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(25, 121, 177, 0.4);
}
.theme-default .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-default .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(25, 121, 177, 0.3);
}
@media (hover: hover) {
  .theme-default .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(25, 121, 177, 0.3);
  }
}
.theme-default .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: var(--b3n-background, #ffffff);
  color: var(--b3n-color, rgba(0, 0, 0, 0.8705882353));
}
.theme-default .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(3, 155, 229, 0.2);
}
.theme-default .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.theme-default .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-default .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.theme-default .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(3, 155, 229, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-default .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.theme-default .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(3, 155, 229, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-default .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-default .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-default .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-default .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-default .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #039be5;
  color: white;
}
.theme-default .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(3, 155, 229, 0.4);
}
.theme-default .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-default .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(3, 155, 229, 0.3);
}
@media (hover: hover) {
  .theme-default .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(3, 155, 229, 0.3);
  }
}
.theme-default .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.theme-default .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.theme-default .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.theme-default .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.theme-default .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-default .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.theme-default .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.theme-default .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.theme-default .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.theme-default .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.theme-default .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.theme-default .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.theme-default .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.theme-default .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-default .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.theme-default .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .theme-default .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.theme-default .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-datepicker-toggle-active {
  color: #1979b1;
}
.theme-default .mat-datepicker-toggle-active.mat-accent {
  color: #039be5;
}
.theme-default .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.theme-default .mat-date-range-input-inner[disabled] {
  color: var(--b3n-disabled, rgba(0, 0, 0, 0.3803921569));
}
.theme-default .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.theme-default .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.theme-default .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.theme-default .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.theme-default .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.theme-default .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
.theme-default .mat-calendar {
  font-family: Inter;
}
.theme-default .mat-calendar-body {
  font-size: 13px;
}
.theme-default .mat-calendar-body-label,
.theme-default .mat-calendar-period-button {
  font-size: 1em;
  font-weight: 500;
}
.theme-default .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.theme-default .mat-divider {
  border-top-color: var(--b3n-border, #e0e0e0);
}
.theme-default .mat-divider-vertical {
  border-right-color: var(--b3n-border, #e0e0e0);
}
.theme-default .mat-expansion-panel {
  background: var(--b3n-background, #ffffff);
  color: var(--b3n-color, rgba(0, 0, 0, 0.8705882353));
}
.theme-default .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-action-row {
  border-top-color: var(--b3n-border, #e0e0e0);
}
.theme-default .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-default .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-default .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .theme-default .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: var(--b3n-background, #ffffff);
  }
}
.theme-default .mat-expansion-panel-header-title {
  color: var(--b3n-color, rgba(0, 0, 0, 0.8705882353));
}
.theme-default .mat-expansion-panel-header-description,
.theme-default .mat-expansion-indicator::after {
  color: var(--b3n-secondary-color, rgba(0, 0, 0, 0.8705882353));
}
.theme-default .mat-expansion-panel-header[aria-disabled=true] {
  color: var(--b3n-disabled, rgba(0, 0, 0, 0.8705882353));
}
.theme-default .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-default .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-default .mat-expansion-panel-header {
  height: 48px;
}
.theme-default .mat-expansion-panel-header.mat-expanded {
  height: 64px;
}
.theme-default .mat-expansion-panel-header {
  font-family: Inter;
  font-size: 12px;
  font-weight: 550;
}
.theme-default .mat-expansion-panel-content {
  font-size: 1em;
  font-weight: 400;
  line-height: 24px;
  font-family: Inter;
  letter-spacing: -0.02;
}
.theme-default .mat-grid-tile-header,
.theme-default .mat-grid-tile-footer {
  font-size: 1em;
}
.theme-default .mat-grid-tile-header .mat-line,
.theme-default .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.theme-default .mat-grid-tile-header .mat-line:nth-child(n+2),
.theme-default .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 10.5px;
}
.theme-default .mat-icon.mat-primary {
  color: #1979b1;
}
.theme-default .mat-icon.mat-accent {
  color: #039be5;
}
.theme-default .mat-icon.mat-warn {
  color: #f44336;
}
.theme-default .mat-drawer-container {
  background-color: white;
  color: var(--b3n-color, rgba(0, 0, 0, 0.8705882353));
}
.theme-default .mat-drawer {
  background-color: var(--b3n-background, #ffffff);
  color: var(--b3n-color, rgba(0, 0, 0, 0.8705882353));
}
.theme-default .mat-drawer.mat-drawer-push {
  background-color: var(--b3n-background, #ffffff);
}
.theme-default .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-default .mat-drawer-side {
  border-right: solid 1px var(--b3n-border, #e0e0e0);
}
.theme-default .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px var(--b3n-border, #e0e0e0);
  border-right: none;
}
.theme-default [dir=rtl] .mat-drawer-side {
  border-left: solid 1px var(--b3n-border, #e0e0e0);
  border-right: none;
}
.theme-default [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px var(--b3n-border, #e0e0e0);
}
.theme-default .mat-drawer-backdrop.mat-drawer-shown {
  background-color: var(--b3n-background, #ffffff);
  opacity: 0.6;
}
.theme-default .mat-step-header.cdk-keyboard-focused, .theme-default .mat-step-header.cdk-program-focused, .theme-default .mat-step-header:hover:not([aria-disabled]), .theme-default .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.theme-default .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .theme-default .mat-step-header:hover {
    background: none;
  }
}
.theme-default .mat-step-header .mat-step-label,
.theme-default .mat-step-header .mat-step-optional {
  color: var(--b3n-secondary-color, rgba(0, 0, 0, 0.8705882353));
}
.theme-default .mat-step-header .mat-step-icon {
  background-color: var(--b3n-secondary-color, rgba(0, 0, 0, 0.8705882353));
  color: white;
}
.theme-default .mat-step-header .mat-step-icon-selected,
.theme-default .mat-step-header .mat-step-icon-state-done,
.theme-default .mat-step-header .mat-step-icon-state-edit {
  background-color: #1979b1;
  color: white;
}
.theme-default .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.theme-default .mat-step-header.mat-accent .mat-step-icon-selected,
.theme-default .mat-step-header.mat-accent .mat-step-icon-state-done,
.theme-default .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #039be5;
  color: white;
}
.theme-default .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.theme-default .mat-step-header.mat-warn .mat-step-icon-selected,
.theme-default .mat-step-header.mat-warn .mat-step-icon-state-done,
.theme-default .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.theme-default .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.theme-default .mat-step-header .mat-step-label.mat-step-label-active {
  color: var(--b3n-color, rgba(0, 0, 0, 0.8705882353));
}
.theme-default .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.theme-default .mat-stepper-horizontal, .theme-default .mat-stepper-vertical {
  background-color: var(--b3n-background, #ffffff);
}
.theme-default .mat-stepper-vertical-line::before {
  border-left-color: var(--b3n-border, #e0e0e0);
}
.theme-default .mat-horizontal-stepper-header::before,
.theme-default .mat-horizontal-stepper-header::after,
.theme-default .mat-stepper-horizontal-line {
  border-top-color: var(--b3n-border, #e0e0e0);
}
.theme-default .mat-horizontal-stepper-header {
  height: 72px;
}
.theme-default .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.theme-default .mat-vertical-stepper-header {
  padding: 24px 24px;
}
.theme-default .mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}
.theme-default .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .theme-default .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}
.theme-default .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}
.theme-default .mat-stepper-vertical, .theme-default .mat-stepper-horizontal {
  font-family: Inter;
}
.theme-default .mat-step-label {
  font-size: 1em;
  font-weight: 400;
}
.theme-default .mat-step-sub-label-error {
  font-weight: normal;
}
.theme-default .mat-step-label-error {
  font-size: 1em;
}
.theme-default .mat-step-label-selected {
  font-size: 1em;
  font-weight: 400;
}
.theme-default .mat-sort-header-arrow {
  color: var(--b3n-secondary-color, rgba(0, 0, 0, 0.8705882353));
}
.theme-default .mat-toolbar {
  background: var(--b3n-background, #ffffff);
  color: var(--b3n-color, rgba(0, 0, 0, 0.8705882353));
}
.theme-default .mat-toolbar.mat-primary {
  background: #1979b1;
  color: white;
}
.theme-default .mat-toolbar.mat-accent {
  background: #039be5;
  color: white;
}
.theme-default .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.theme-default .mat-toolbar .mat-form-field-underline,
.theme-default .mat-toolbar .mat-form-field-ripple,
.theme-default .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-default .mat-toolbar .mat-form-field-label,
.theme-default .mat-toolbar .mat-focused .mat-form-field-label,
.theme-default .mat-toolbar .mat-select-value,
.theme-default .mat-toolbar .mat-select-arrow,
.theme-default .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-default .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-default .mat-toolbar-multiple-rows {
  min-height: 64px;
}
.theme-default .mat-toolbar-row, .theme-default .mat-toolbar-single-row {
  height: 64px;
}
@media (max-width: 599px) {
  .theme-default .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .theme-default .mat-toolbar-row, .theme-default .mat-toolbar-single-row {
    height: 56px;
  }
}
.theme-default .mat-toolbar,
.theme-default .mat-toolbar h1,
.theme-default .mat-toolbar h2,
.theme-default .mat-toolbar h3,
.theme-default .mat-toolbar h4,
.theme-default .mat-toolbar h5,
.theme-default .mat-toolbar h6 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Inter;
  letter-spacing: 0.0125em;
  margin: 0;
}
.theme-default .mat-tree {
  background: var(--b3n-background, #ffffff);
}
.theme-default .mat-tree-node,
.theme-default .mat-nested-tree-node {
  color: var(--b3n-color, rgba(0, 0, 0, 0.8705882353));
}
.theme-default .mat-tree-node {
  min-height: 48px;
}
.theme-default .mat-tree {
  font-family: Inter;
}
.theme-default .mat-tree-node,
.theme-default .mat-nested-tree-node {
  font-weight: 400;
  font-size: 1em;
}
.theme-default .mat-mdc-form-field-infix {
  min-height: 44px;
}
.theme-default .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 22px;
}
.theme-default .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -28.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.theme-default .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 10px;
  padding-bottom: 10px;
}
.theme-default .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 10px;
  padding-bottom: 10px;
}
.theme-default .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 10px;
  padding-bottom: 10px;
}
.theme-default .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}
.theme-default .mat-mdc-checkbox .mdc-checkbox {
  padding: calc((var(--mdc-checkbox-ripple-size, 28px) - 18px) / 2);
  margin: calc((var(--mdc-checkbox-touch-target-size, 28px) - 28px) / 2);
}
.theme-default .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  top: calc((var(--mdc-checkbox-ripple-size, 28px) - 18px) / 2);
  left: calc((var(--mdc-checkbox-ripple-size, 28px) - 18px) / 2);
}
.theme-default .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
  top: calc((28px - var(--mdc-checkbox-touch-target-size, 28px)) / 2);
  right: calc((28px - var(--mdc-checkbox-touch-target-size, 28px)) / 2);
  left: calc((28px - var(--mdc-checkbox-touch-target-size, 28px)) / 2);
  width: var(--mdc-checkbox-touch-target-size, 28px);
  height: var(--mdc-checkbox-touch-target-size, 28px);
}
.theme-default .mat-mdc-checkbox-touch-target {
  display: none;
}
.theme-default .mat-mdc-chip {
  height: 32px;
}
.theme-default .mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}
.theme-default .mat-mdc-radio-button .mdc-radio {
  padding: calc((28px - 20px) / 2);
  margin: 0;
}
.theme-default .mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  top: calc(-1 * (28px - 20px) / 2);
  left: calc(-1 * (28px - 20px) / 2);
  width: 28px;
  height: 28px;
}
.theme-default .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control {
  top: calc((28px - 28px) / 2);
  right: calc((28px - 28px) / 2);
  left: calc((28px - 28px) / 2);
  width: 28px;
  height: 28px;
}
.theme-default .mat-mdc-radio-touch-target {
  display: none;
}
.theme-default .mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 40px;
  --mdc-list-list-item-two-line-container-height: 56px;
  --mdc-list-list-item-three-line-container-height: 80px;
}
.theme-default .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .theme-default .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .theme-default .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 48px;
}
.theme-default .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .theme-default .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .theme-default .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 64px;
}
.theme-default .mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.theme-default .mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.theme-default .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.theme-default .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.theme-default .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.theme-default .mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.theme-default .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}
.theme-default .mat-mdc-paginator-container {
  min-height: 56px;
}
.theme-default .mat-mdc-tab-header .mdc-tab {
  height: 48px;
}
.theme-default .mat-mdc-button.mat-mdc-button-base,
.theme-default .mat-mdc-raised-button.mat-mdc-button-base,
.theme-default .mat-mdc-unelevated-button.mat-mdc-button-base,
.theme-default .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}
.theme-default .mat-mdc-icon-button.mat-mdc-button-base {
  width: 36px;
  height: 36px;
  padding: 6px;
}
.theme-default .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 36px;
  max-width: 36px;
}
.theme-default .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 36px;
  height: 36px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.theme-default .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 36px;
  max-width: 36px;
}
.theme-default .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 36px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 36px;
  transform: translate(-50%, -50%);
}
.theme-default .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
.theme-default .mat-mdc-table .mdc-data-table__row {
  height: 52px;
}
.theme-default .mat-mdc-table .mdc-data-table__pagination {
  min-height: 52px;
}
.theme-default .mat-mdc-table .mdc-data-table__header-row {
  height: 56px;
}
.theme-default .mat-expansion-panel-header {
  height: 48px;
}
.theme-default .mat-expansion-panel-header.mat-expanded {
  height: 64px;
}
.theme-default .mat-horizontal-stepper-header {
  height: 72px;
}
.theme-default .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.theme-default .mat-vertical-stepper-header {
  padding: 24px 24px;
}
.theme-default .mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}
.theme-default .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .theme-default .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}
.theme-default .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}
.theme-default .mat-toolbar-multiple-rows {
  min-height: 64px;
}
.theme-default .mat-toolbar-row, .theme-default .mat-toolbar-single-row {
  height: 64px;
}
@media (max-width: 599px) {
  .theme-default .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .theme-default .mat-toolbar-row, .theme-default .mat-toolbar-single-row {
    height: 56px;
  }
}
.theme-default .mat-tree-node {
  min-height: 48px;
}
.theme-default .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}
.theme-default .h0,
.theme-default h1,
.theme-default h2,
.theme-default h3,
.theme-default h4 {
  letter-spacing: -0.2px !important;
}
.theme-default .h0 {
  font-size: 24px !important;
  font-weight: 550 !important;
}
.theme-default h1,
.theme-default .h1 {
  font-size: 20px !important;
  font-weight: 550 !important;
  line-height: 32px !important;
}
.theme-default h2,
.theme-default .h2 {
  font-size: 16px !important;
  font-weight: 550 !important;
}
.theme-default h3,
.theme-default .h3 {
  font-size: 14px !important;
  font-weight: 550 !important;
}
.theme-default h4,
.theme-default .h4 {
  font-size: 12px;
  font-weight: 550;
}
.theme-default .body-2 {
  font-size: 12px !important;
  font-weight: 400 !important;
}
.theme-default strong,
.theme-default b {
  font-weight: 550;
}
.theme-default .mat-mdc-menu-content:has(button.mat-mdc-menu-item) {
  padding-right: 16px;
  border-radius: 8px !important;
}
.theme-default .mat-mdc-menu-content:has(button.mat-mdc-menu-item) button.mat-mdc-menu-item {
  min-height: 40px;
  margin-left: 8px;
  border-radius: 6px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.theme-default .mat-mdc-menu-content:has(button.mat-mdc-menu-item) button.mat-mdc-menu-item:hover:not([disabled]) {
  background-color: var(--b3n-selection-hover, #f2f3f5);
}
.theme-default .mat-mdc-menu-content:has(button.mat-mdc-menu-item) button.mat-mdc-menu-item:hover:not([disabled]) .mdc-list-item__primary-text,
.theme-default .mat-mdc-menu-content:has(button.mat-mdc-menu-item) button.mat-mdc-menu-item:hover:not([disabled]) mat-icon:not(.inherit-color),
.theme-default .mat-mdc-menu-content:has(button.mat-mdc-menu-item) button.mat-mdc-menu-item:hover:not([disabled]) .mat-mdc-menu-submenu-icon {
  color: var(--b3n-selection-text-hover, #313338);
}
.theme-default .mat-mdc-menu-content:has(button.mat-mdc-menu-item) button.mat-mdc-menu-item.mat-mdc-menu-item-highlighted {
  background-color: var(--b3n-selection-active, #ebf2fb) !important;
}
.theme-default .mat-mdc-menu-content:has(button.mat-mdc-menu-item) button.mat-mdc-menu-item.mat-mdc-menu-item-highlighted .mdc-list-item__primary-text,
.theme-default .mat-mdc-menu-content:has(button.mat-mdc-menu-item) button.mat-mdc-menu-item.mat-mdc-menu-item-highlighted mat-icon:not(.inherit-color),
.theme-default .mat-mdc-menu-content:has(button.mat-mdc-menu-item) button.mat-mdc-menu-item.mat-mdc-menu-item-highlighted .mat-mdc-menu-submenu-icon {
  color: var(--b3n-selection-text-active, "#136899");
}
.theme-default .ngx-mat-tel-input-mat-menu-panel .mat-mdc-menu-content:has(button.mat-mdc-menu-item) {
  padding-top: 0 !important;
  padding-right: 0 !important;
}
.theme-default .ngx-mat-tel-input-mat-menu-panel .mat-mdc-menu-content:has(button.mat-mdc-menu-item) button.country-list-button {
  padding-left: 8px !important;
  margin-left: 0 !important;
  border-radius: 0 !important;
}
.theme-default .mat-mdc-form-field.mat-focused.mat-primary:not(.mat-form-field-invalid) .mat-mdc-select-arrow {
  color: var(--b3n-link, #1979b1) !important;
}
.theme-default .mat-mdc-autocomplete-panel,
.theme-default .mat-mdc-select-panel {
  border-radius: 6px !important;
  min-width: max-content !important;
}
.theme-default .mat-mdc-autocomplete-panel mat-option:not(.ngx-mat-select-search),
.theme-default .mat-mdc-select-panel mat-option:not(.ngx-mat-select-search) {
  min-height: 40px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 6px;
  padding-left: 8px;
  padding-right: 8px;
}
.theme-default .mat-mdc-autocomplete-panel mat-option:not(.ngx-mat-select-search):not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled):hover,
.theme-default .mat-mdc-select-panel mat-option:not(.ngx-mat-select-search):not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled):hover {
  --mdc-theme-primary: var(--b3n-selection-text-hover, #313338);
  background-color: var(--b3n-selection-hover, #f2f3f5) !important;
}
.theme-default .mat-mdc-autocomplete-panel mat-option:not(.ngx-mat-select-search).more-input:hover,
.theme-default .mat-mdc-select-panel mat-option:not(.ngx-mat-select-search).more-input:hover {
  --mdc-theme-primary: var(--b3n-selection-text-hover, #313338);
  background-color: #fff !important;
}
.theme-default .mat-mdc-autocomplete-panel mat-option:not(.ngx-mat-select-search).more-input .mdc-list-item__primary-text,
.theme-default .mat-mdc-select-panel mat-option:not(.ngx-mat-select-search).more-input .mdc-list-item__primary-text {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.theme-default .mat-mdc-autocomplete-panel mat-option:not(.ngx-mat-select-search):not(.mat-mdc-option-multiple).mdc-list-item--selected,
.theme-default .mat-mdc-select-panel mat-option:not(.ngx-mat-select-search):not(.mat-mdc-option-multiple).mdc-list-item--selected {
  --mdc-theme-primary: var(--b3n-selection-text-active, #136899);
  background-color: var(--b3n-selection-active, #ebf2fb) !important;
}
.theme-default .mat-mdc-autocomplete-panel .mat-mdc-option.mat-mdc-option-active,
.theme-default .mat-mdc-select-panel .mat-mdc-option.mat-mdc-option-active {
  background: var(--b3n-selection-hover, #f2f3f5);
  color: var(--b3n-color, #26272c) !important;
}
.theme-default .mat-mdc-autocomplete-panel mat-optgroup .mat-mdc-optgroup-label,
.theme-default .mat-mdc-select-panel mat-optgroup .mat-mdc-optgroup-label {
  font-size: 12px;
}
.theme-default .mat-mdc-autocomplete-panel mat-optgroup .mat-mdc-optgroup-label .mdc-list-item__primary-text,
.theme-default .mat-mdc-select-panel mat-optgroup .mat-mdc-optgroup-label .mdc-list-item__primary-text {
  font-weight: 550;
  color: var(--b3n-secondary-color, rgba(38, 39, 44, 0.8));
}
.theme-default mat-toolbar {
  font-size: inherit !important;
  letter-spacing: -0.2px !important;
  font-weight: 400 !important;
}
.theme-default mat-toolbar-row .mat-mdc-form-field-infix,
.theme-default .input-40px .mat-mdc-form-field-infix {
  min-height: 40px;
}
.theme-default mat-toolbar-row .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label,
.theme-default .input-40px .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.theme-default mat-toolbar-row .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.theme-default .input-40px .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.theme-default mat-toolbar-row .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix,
.theme-default .input-40px .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.theme-default mat-toolbar-row .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix,
.theme-default .input-40px .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.theme-default mat-toolbar-row .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix,
.theme-default .input-40px .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.theme-default mat-toolbar-row .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label,
.theme-default .input-40px .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}
.theme-default mat-toolbar-row mat-form-field,
.theme-default .input-40px mat-form-field {
  max-width: 280px;
}
.theme-default mat-toolbar-row mat-form-field .mat-mdc-form-field-infix,
.theme-default .input-40px mat-form-field .mat-mdc-form-field-infix {
  width: fit-content;
  min-width: 100px;
}
.theme-default mat-toolbar-row mat-form-field .mat-mdc-form-field-infix .mat-mdc-select-arrow-wrapper,
.theme-default .input-40px mat-form-field .mat-mdc-form-field-infix .mat-mdc-select-arrow-wrapper {
  padding-left: 8px;
}
.theme-default mat-toolbar-row mat-form-field .mat-mdc-form-field-subscript-wrapper,
.theme-default .input-40px mat-form-field .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.theme-default mat-toolbar-row .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label,
.theme-default .input-40px .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 18px;
}
.theme-default mat-toolbar-row .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.theme-default .input-40px .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(-24.75px)
    scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
}
.theme-default mat-form-field.no-validator .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.theme-default .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.theme-default .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-icon-button.mat-mdc-button-base .mat-datepicker-toggle-default-icon {
  display: inline-block;
}
.theme-default .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(-26.75px)
    scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
}
.theme-default .mdc-floating-label {
  will-change: auto;
}
.theme-default .mdc-notched-outline__notch {
  border-right: 0;
}
.theme-default .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid) .mdc-notched-outline__leading,
.theme-default .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid) .mdc-notched-outline__notch,
.theme-default .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid) .mdc-notched-outline__trailing {
  border-color: var(--b3n-border, #e0e0e0);
}
.theme-default .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid):hover .mdc-notched-outline__leading,
.theme-default .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid):hover .mdc-notched-outline__notch,
.theme-default .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid):hover .mdc-notched-outline__trailing {
  border-color: var(--b3n-link, #1979b1);
}
.theme-default .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid):hover .mdc-notched-outline__notch .mdc-floating-label {
  color: var(--b3n-link, #027cb5);
}
.theme-default .mdc-text-field--outlined.mdc-text-field--focused:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__leading,
.theme-default .mdc-text-field--outlined.mdc-text-field--focused:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__notch,
.theme-default .mdc-text-field--outlined.mdc-text-field--focused:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__trailing {
  border-color: var(--b3n-link, #027cb5);
}
.theme-default .mdc-text-field--outlined.mdc-text-field--focused:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__notch .mdc-floating-label {
  color: var(--b3n-link, #027cb5);
}
.theme-default .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #039be5;
  --mdc-switch-selected-handle-color: #039be5;
  --mdc-switch-selected-hover-state-layer-color: #039be5;
  --mdc-switch-selected-pressed-state-layer-color: #039be5;
  --mdc-switch-selected-focus-handle-color: #039be5;
  --mdc-switch-selected-hover-handle-color: #039be5;
  --mdc-switch-selected-pressed-handle-color: #039be5;
  --mdc-switch-selected-focus-track-color: #039be58a;
  --mdc-switch-selected-hover-track-color: #039be58a;
  --mdc-switch-selected-pressed-track-color: #039be58a;
  --mdc-switch-selected-track-color: #039be58a;
}
.theme-default .mat-mdc-slide-toggle.mat-accent .mdc-form-field > label {
  padding-left: 8px;
}
.theme-default .mat-mdc-slide-toggle {
  --mdc-switch-unselected-focus-track-color: #00000061;
  --mdc-switch-unselected-hover-track-color: #00000061;
  --mdc-switch-unselected-pressed-track-color: #00000061;
  --mdc-switch-unselected-track-color: #00000061;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #fafafa;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.theme-default * {
  -moz-osx-font-smoothing: inherit !important;
  -webkit-font-smoothing: inherit !important;
}
.theme-default .mat-tooltip {
  white-space: pre-line !important;
  font-size: 12px !important;
}
.theme-default .secondary-text {
  color: var(--b3n-secondary-text, rgba(0, 0, 0, 0.5411764706)) !important;
}
.theme-default .mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 32px !important;
  --mdc-switch-selected-focus-state-layer-color: var(--b3n-primary) !important;
  --mdc-switch-selected-hover-state-layer-color: var(--b3n-primary) !important;
  --mdc-switch-selected-pressed-state-layer-color: var(--b3n-primary) !important;
  --mdc-switch-selected-handle-color: var(--b3n-primary) !important;
  --mdc-switch-selected-focus-handle-color: var(--b3n-primary) !important;
  --mdc-switch-selected-hover-handle-color: var(--b3n-primary) !important;
  --mdc-switch-selected-pressed-handle-color: var(--b3n-primary) !important;
  --mdc-switch-selected-focus-track-color: #1979b180 !important;
  --mdc-switch-selected-hover-track-color: #1979b180 !important;
  --mdc-switch-selected-pressed-track-color: #1979b180 !important;
  --mdc-switch-selected-track-color: #1979b180 !important;
}
.theme-default .mat-mdc-icon-button,
.theme-default .mat-mdc-fab,
.theme-default .mat-mdc-mini-fab {
  padding: 0 !important;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
}
.theme-default .mdc-dialog .mdc-dialog__title {
  --mdc-dialog-subhead-weight: 550;
  color: var(--b3n-title, #26272c);
}
.theme-default .mdc-dialog .mdc-dialog__content {
  padding: 6px 24px 8px;
}
.theme-default .mdc-dialog .mdc-dialog__actions {
  padding: 8px 24px 24px;
}
.theme-default .mat-mdc-dialog-container .mdc-dialog__content {
  --mdc-dialog-supporting-text-color: var(--b3n-color, #000000de) !important;
}
.theme-default .mat-mdc-button.mat-mdc-button-base,
.theme-default .mat-mdc-raised-button.mat-mdc-button-base,
.theme-default .mat-mdc-unelevated-button.mat-mdc-button-base,
.theme-default .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 40px;
  font-weight: 550;
}
.theme-default .mat-mdc-radio-button .mdc-form-field > label {
  cursor: pointer;
}
.theme-default .mdc-button--outlined .mdc-button__ripple {
  border-radius: 5px;
}
.theme-default a {
  color: var(--b3n-link, #1979b1);
}
.theme-default .mdc-switch .mdc-switch__icons {
  display: none;
}
.theme-default .mat-expansion-panel-header {
  font-weight: inherit;
}
.theme-default .mat-mdc-table .mdc-data-table__header-cell {
  color: var(--b3n-secondary-color, rgba(31, 33, 35, 0.6));
  border-bottom-color: var(--b3n-table-border, #dddddd);
}
.theme-default .mat-mdc-table .mdc-data-table__cell {
  color: var(--b3n-table-color, #1f2123) !important;
  border-bottom-color: var(--b3n-table-border, #dddddd);
}
.theme-default .mat-mdc-no-data-row {
  height: 52px;
}
.theme-default .mat-mdc-paginator {
  border-top: 1px solid var(--b3n-border, #e0e0e0);
}
.theme-default .mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  margin-right: 0.5rem;
}
.theme-default .box-uuid {
  padding: 4px 8px !important;
  line-height: 25px !important;
  background: var(--b3n-box-uuid, #e6e6e6);
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 550;
  color: var(--b3n-color, rgba(0, 0, 0, 0.8705882353));
  height: 25px !important;
  white-space: nowrap !important;
}
.theme-default .mat-datepicker-actions {
  padding: 16px;
}
.theme-default .mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.theme-default .mat-datepicker-content .mat-calendar .mat-calendar-content {
  padding-bottom: 24px;
}
.theme-default .mat-select-search-input::placeholder {
  color: var(--b3n-placeholder, rgba(31, 33, 35, 0.4)) !important;
}
.theme-default .mat-step-header.mat-primary .mat-step-icon-selected {
  background-color: var(--b3n-primary, #1979b1);
  color: white;
}
.theme-default .mat-step-header.mat-primary .mat-step-label-selected {
  color: var(--b3n-primary, #1979b1);
  font-weight: 550;
}
.theme-default .color-warn {
  color: #f44336 !important;
}

.dark-theme-portal-base {
  background-color: var(--b3n-background, #313338);
  color: var(--b3n-color, #e3e5e8);
  --b3n-primary: #1979b1;
  --b3n-primary-text: white;
  --b3n-warn: #f44336;
  font-size: 14px;
  font-family: "Inter";
  letter-spacing: -0.02px;
  line-height: 24px;
  /**
    config density
    default is 0 or maximum
    maximum, 0, -1, -2, -3, -4, 0
  */
  --mdc-shape-small: 6px;
  --mdc-dialog-container-shape: 6px;
}
.dark-theme-portal-base .mat-ripple-element {
  background-color: var(--b3n-highlight, #424242);
  opacity: 0.1;
}
.dark-theme-portal-base .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme-portal-base .mat-mdc-option:hover:not(.mdc-list-item--disabled), .dark-theme-portal-base .mat-mdc-option:focus:not(.mdc-list-item--disabled), .dark-theme-portal-base .mat-mdc-option.mat-mdc-option-active, .dark-theme-portal-base .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(255, 255, 255, 0.08);
}
.dark-theme-portal-base .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #138fce);
}
.dark-theme-portal-base .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #039be5);
}
.dark-theme-portal-base .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme-portal-base .mat-pseudo-checkbox-full {
  color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.8705882353));
}
.dark-theme-portal-base .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #686868;
}
.dark-theme-portal-base .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark-theme-portal-base .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #138fce;
}
.dark-theme-portal-base .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark-theme-portal-base .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #138fce;
}
.dark-theme-portal-base .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.dark-theme-portal-base .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #313338;
}
.dark-theme-portal-base .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark-theme-portal-base .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #039be5;
}
.dark-theme-portal-base .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark-theme-portal-base .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #039be5;
}
.dark-theme-portal-base .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.dark-theme-portal-base .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #313338;
}
.dark-theme-portal-base .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark-theme-portal-base .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #039be5;
}
.dark-theme-portal-base .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark-theme-portal-base .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #039be5;
}
.dark-theme-portal-base .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.dark-theme-portal-base .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #313338;
}
.dark-theme-portal-base .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark-theme-portal-base .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.dark-theme-portal-base .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark-theme-portal-base .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.dark-theme-portal-base .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.dark-theme-portal-base .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #313338;
}
.dark-theme-portal-base .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.dark-theme-portal-base .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #686868;
}
.dark-theme-portal-base .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.dark-theme-portal-base .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #686868;
}
.dark-theme-portal-base .mat-app-background, .dark-theme-portal-base.mat-app-background {
  background-color: #313338;
  color: var(--b3n-color, #c1c1c1);
}
.dark-theme-portal-base .mat-elevation-z0, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z1, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z2, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z3, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z4, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z5, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z6, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z7, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z8, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z9, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z10, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z11, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z12, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z13, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z14, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z15, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z16, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z17, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z18, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z19, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z20, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z21, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z22, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z23, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-elevation-z24, .dark-theme-portal-base .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.dark-theme-portal-base .mat-mdc-option {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-body1-font-size, 1em);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, -0.02);
}
.dark-theme-portal-base .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: var(--b3n-background, #313338);
}
.dark-theme-portal-base .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #fff;
}
.dark-theme-portal-base .mat-mdc-card-subtitle {
  color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.8705882353));
}
.dark-theme-portal-base .mat-mdc-card-title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-headline6-font-size, 20px);
  line-height: var(--mdc-typography-headline6-line-height, 32px);
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: var(--mdc-typography-headline6-text-transform, none);
}
.dark-theme-portal-base .mat-mdc-card-subtitle {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-subtitle2-font-size, 12px);
  line-height: var(--mdc-typography-subtitle2-line-height, 24px);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 550);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}
.dark-theme-portal-base .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #138fce;
}
.dark-theme-portal-base .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(19, 143, 206, 0.25)'/%3E%3C/svg%3E");
}
.dark-theme-portal-base .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(19, 143, 206, 0.25);
}
.dark-theme-portal-base .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #039be5;
}
.dark-theme-portal-base .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(3, 155, 229, 0.25)'/%3E%3C/svg%3E");
}
.dark-theme-portal-base .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(3, 155, 229, 0.25);
}
.dark-theme-portal-base .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
}
.dark-theme-portal-base .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
}
.dark-theme-portal-base .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
}
.dark-theme-portal-base .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
.dark-theme-portal-base .mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: Inter;
  --mdc-plain-tooltip-supporting-text-size: 10.5px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: -0.02;
}
.dark-theme-portal-base .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 255, 255, 0.6);
}
.dark-theme-portal-base .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.87);
}
@media all {
  .dark-theme-portal-base .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
@media all {
  .dark-theme-portal-base .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
.dark-theme-portal-base .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #138fce);
}
.dark-theme-portal-base .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.dark-theme-portal-base .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.dark-theme-portal-base .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.dark-theme-portal-base .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.dark-theme-portal-base .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.dark-theme-portal-base .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.dark-theme-portal-base .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.dark-theme-portal-base .mdc-text-field--filled .mdc-text-field__ripple::before,
.dark-theme-portal-base .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(255, 255, 255, 0.87));
}
.dark-theme-portal-base .mdc-text-field--filled:hover .mdc-text-field__ripple::before, .dark-theme-portal-base .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.dark-theme-portal-base .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .dark-theme-portal-base .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.dark-theme-portal-base .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #fff;
}
.dark-theme-portal-base .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.42);
}
.dark-theme-portal-base .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.87);
}
.dark-theme-portal-base .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #138fce);
}
.dark-theme-portal-base .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.dark-theme-portal-base .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.dark-theme-portal-base .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.38);
}
.dark-theme-portal-base .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.dark-theme-portal-base .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.dark-theme-portal-base .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.87);
}
.dark-theme-portal-base .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.dark-theme-portal-base .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.dark-theme-portal-base .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #138fce);
}
[dir=rtl] .dark-theme-portal-base .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .dark-theme-portal-base .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .dark-theme-portal-base .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .dark-theme-portal-base .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.dark-theme-portal-base .mdc-text-field--outlined .mdc-text-field__ripple::before,
.dark-theme-portal-base .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .dark-theme-portal-base .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .dark-theme-portal-base .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.dark-theme-portal-base .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(19, 143, 206, 0.87);
}
.dark-theme-portal-base .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.dark-theme-portal-base .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.dark-theme-portal-base .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.dark-theme-portal-base .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.dark-theme-portal-base .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.dark-theme-portal-base .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.dark-theme-portal-base .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.38);
}
@media all {
  .dark-theme-portal-base .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
@media all {
  .dark-theme-portal-base .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
.dark-theme-portal-base .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(255, 255, 255, 0.38);
}
.dark-theme-portal-base .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.dark-theme-portal-base .mdc-text-field--disabled .mdc-text-field-character-counter,
.dark-theme-portal-base .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.dark-theme-portal-base .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.dark-theme-portal-base .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.dark-theme-portal-base .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.dark-theme-portal-base .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.dark-theme-portal-base .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.06);
}
.dark-theme-portal-base .mdc-text-field--disabled .mdc-notched-outline__leading,
.dark-theme-portal-base .mdc-text-field--disabled .mdc-notched-outline__notch,
.dark-theme-portal-base .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .dark-theme-portal-base .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .dark-theme-portal-base .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .dark-theme-portal-base .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .dark-theme-portal-base .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .dark-theme-portal-base .mdc-text-field--disabled .mdc-text-field-character-counter,
  .dark-theme-portal-base .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .dark-theme-portal-base .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .dark-theme-portal-base .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .dark-theme-portal-base .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .dark-theme-portal-base .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .dark-theme-portal-base .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .dark-theme-portal-base .mdc-text-field--disabled .mdc-notched-outline__leading,
  .dark-theme-portal-base .mdc-text-field--disabled .mdc-notched-outline__notch,
  .dark-theme-portal-base .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.dark-theme-portal-base .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fff;
}
[dir=rtl] .dark-theme-portal-base .mdc-text-field--end-aligned .mdc-text-field__input, .dark-theme-portal-base .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .dark-theme-portal-base .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .dark-theme-portal-base .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.dark-theme-portal-base .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field-focus-overlay {
  background-color: rgba(255, 255, 255, 0.87);
}
.dark-theme-portal-base .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.08;
}
.dark-theme-portal-base .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.24;
}
.dark-theme-portal-base select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme-portal-base select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.dark-theme-portal-base .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.54);
}
.dark-theme-portal-base .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(19, 143, 206, 0.87);
}
.dark-theme-portal-base .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(3, 155, 229, 0.87);
}
.dark-theme-portal-base .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.dark-theme-portal-base .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.38);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #039be5);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #039be5);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(3, 155, 229, 0.87);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.dark-theme-portal-base .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.dark-theme-portal-base .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.dark-theme-portal-base .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.dark-theme-portal-base .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.dark-theme-portal-base .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.dark-theme-portal-base .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.dark-theme-portal-base .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.dark-theme-portal-base .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #039be5);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(244, 67, 54, 0.87);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.dark-theme-portal-base .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.dark-theme-portal-base .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.dark-theme-portal-base .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.dark-theme-portal-base .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.dark-theme-portal-base .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.dark-theme-portal-base .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.dark-theme-portal-base .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.dark-theme-portal-base .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.dark-theme-portal-base [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.dark-theme-portal-base .mat-mdc-form-field-infix {
  min-height: 56px;
}
.dark-theme-portal-base .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}
.dark-theme-portal-base .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.dark-theme-portal-base .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}
.dark-theme-portal-base .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}
.dark-theme-portal-base .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}
.dark-theme-portal-base .mdc-text-field__input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-subtitle1-font-size, 1em);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
.dark-theme-portal-base .mdc-text-field__affix {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-subtitle1-font-size, 1em);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
[dir=rtl] .dark-theme-portal-base .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .dark-theme-portal-base .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .dark-theme-portal-base .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .dark-theme-portal-base .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.dark-theme-portal-base .mdc-text-field--textarea .mdc-text-field__input {
  line-height: 1.5rem;
}
[dir=rtl] .dark-theme-portal-base .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .dark-theme-portal-base .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
[dir=rtl] .dark-theme-portal-base .mdc-text-field--end-aligned .mdc-text-field__input, .dark-theme-portal-base .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.dark-theme-portal-base .mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-subtitle1-font-size, 1em);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
[dir=rtl] .dark-theme-portal-base .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .dark-theme-portal-base .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.dark-theme-portal-base .mat-mdc-form-field-subscript-wrapper,
.dark-theme-portal-base .mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-caption-font-size, 10.5px);
  line-height: var(--mdc-typography-caption-line-height, 12px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}
.dark-theme-portal-base .mat-mdc-form-field,
.dark-theme-portal-base .mat-mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-body1-font-size, 1em);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}
.dark-theme-portal-base .mat-mdc-form-field .mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: calc(1em * var(--mat-mdc-form-field-floating-label-scale, 0.75));
}
.dark-theme-portal-base .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1em;
}
.dark-theme-portal-base .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, var(--b3n-background, #313338));
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme-portal-base .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme-portal-base .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.dark-theme-portal-base .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.dark-theme-portal-base .mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme-portal-base .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.dark-theme-portal-base .mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme-portal-base .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.dark-theme-portal-base .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.dark-theme-portal-base .mdc-list-item--disabled .mdc-list-item__start,
.dark-theme-portal-base .mdc-list-item--disabled .mdc-list-item__content,
.dark-theme-portal-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.dark-theme-portal-base .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme-portal-base .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme-portal-base .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme-portal-base .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme-portal-base .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme-portal-base .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme-portal-base .mdc-list-item--selected .mdc-list-item__primary-text,
.dark-theme-portal-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #138fce);
}
.dark-theme-portal-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme-portal-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #138fce);
}
.dark-theme-portal-base .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme-portal-base .mdc-list-divider::after {
  border-bottom-color: white;
}
.dark-theme-portal-base .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.dark-theme-portal-base .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme-portal-base .mat-mdc-select-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.dark-theme-portal-base .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.38);
}
.dark-theme-portal-base .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.54);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(19, 143, 206, 0.87);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(3, 155, 229, 0.87);
}
.dark-theme-portal-base .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.dark-theme-portal-base .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.dark-theme-portal-base .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.38);
}
.dark-theme-portal-base .mat-mdc-select-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-subtitle1-font-size, 1em);
  line-height: var(--mdc-typography-subtitle1-line-height, 24px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}
.dark-theme-portal-base .mat-mdc-select {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-body1-font-size, 1em);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}
.dark-theme-portal-base .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, var(--b3n-background, #313338));
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme-portal-base .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme-portal-base .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.dark-theme-portal-base .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.dark-theme-portal-base .mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme-portal-base .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.dark-theme-portal-base .mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme-portal-base .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.dark-theme-portal-base .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.dark-theme-portal-base .mdc-list-item--disabled .mdc-list-item__start,
.dark-theme-portal-base .mdc-list-item--disabled .mdc-list-item__content,
.dark-theme-portal-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.dark-theme-portal-base .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme-portal-base .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme-portal-base .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme-portal-base .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme-portal-base .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme-portal-base .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme-portal-base .mdc-list-item--selected .mdc-list-item__primary-text,
.dark-theme-portal-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #138fce);
}
.dark-theme-portal-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme-portal-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #138fce);
}
.dark-theme-portal-base .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme-portal-base .mdc-list-divider::after {
  border-bottom-color: white;
}
.dark-theme-portal-base .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.dark-theme-portal-base .mat-mdc-autocomplete-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-subtitle1-font-size, 1em);
  line-height: var(--mdc-typography-subtitle1-line-height, 24px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}
.dark-theme-portal-base .mat-mdc-dialog-container {
  --mdc-dialog-container-color: var(--b3n-background, #313338);
  --mdc-dialog-with-divider-divider-color: rgba(255, 255, 255, 0.12);
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
.dark-theme-portal-base .mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: Inter;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Inter;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 1em;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: -0.02;
}
.dark-theme-portal-base .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #fff;
  --mdc-chip-elevated-disabled-container-color: #fff;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
}
.dark-theme-portal-base .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .dark-theme-portal-base .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #138fce;
  --mdc-chip-elevated-disabled-container-color: #138fce;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.dark-theme-portal-base .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .dark-theme-portal-base .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #039be5;
  --mdc-chip-elevated-disabled-container-color: #039be5;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.dark-theme-portal-base .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .dark-theme-portal-base .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.dark-theme-portal-base .mat-mdc-chip-focus-overlay {
  background: var(--b3n-highlight, #424242);
}
.dark-theme-portal-base .mat-mdc-chip {
  height: 32px;
}
.dark-theme-portal-base .mat-mdc-standard-chip {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 24px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.dark-theme-portal-base .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-unselected-icon-color: #212121;
}
.dark-theme-portal-base .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme-portal-base .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.3803921569));
}
.dark-theme-portal-base .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #64b5f6;
  --mdc-switch-selected-handle-color: #64b5f6;
  --mdc-switch-selected-hover-state-layer-color: #64b5f6;
  --mdc-switch-selected-pressed-state-layer-color: #64b5f6;
  --mdc-switch-selected-focus-handle-color: #90caf9;
  --mdc-switch-selected-hover-handle-color: #90caf9;
  --mdc-switch-selected-pressed-handle-color: #90caf9;
  --mdc-switch-selected-focus-track-color: #1e88e5;
  --mdc-switch-selected-hover-track-color: #1e88e5;
  --mdc-switch-selected-pressed-track-color: #1e88e5;
  --mdc-switch-selected-track-color: #1e88e5;
}
.dark-theme-portal-base .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #4fc3f7;
  --mdc-switch-selected-handle-color: #4fc3f7;
  --mdc-switch-selected-hover-state-layer-color: #4fc3f7;
  --mdc-switch-selected-pressed-state-layer-color: #4fc3f7;
  --mdc-switch-selected-focus-handle-color: #81d4fa;
  --mdc-switch-selected-hover-handle-color: #81d4fa;
  --mdc-switch-selected-pressed-handle-color: #81d4fa;
  --mdc-switch-selected-focus-track-color: #039be5;
  --mdc-switch-selected-hover-track-color: #039be5;
  --mdc-switch-selected-pressed-track-color: #039be5;
  --mdc-switch-selected-track-color: #039be5;
}
.dark-theme-portal-base .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e57373;
  --mdc-switch-selected-handle-color: #e57373;
  --mdc-switch-selected-hover-state-layer-color: #e57373;
  --mdc-switch-selected-pressed-state-layer-color: #e57373;
  --mdc-switch-selected-focus-handle-color: #ef9a9a;
  --mdc-switch-selected-hover-handle-color: #ef9a9a;
  --mdc-switch-selected-pressed-handle-color: #ef9a9a;
  --mdc-switch-selected-focus-track-color: #e53935;
  --mdc-switch-selected-hover-track-color: #e53935;
  --mdc-switch-selected-pressed-track-color: #e53935;
  --mdc-switch-selected-track-color: #e53935;
}
.dark-theme-portal-base .mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}
.dark-theme-portal-base .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 24px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.dark-theme-portal-base .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme-portal-base .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #138fce;
  --mdc-radio-selected-hover-icon-color: #138fce;
  --mdc-radio-selected-icon-color: #138fce;
  --mdc-radio-selected-pressed-icon-color: #138fce;
  --mat-mdc-radio-ripple-color: #fff;
  --mat-mdc-radio-checked-ripple-color: #138fce;
}
.dark-theme-portal-base .mat-mdc-radio-button.mat-primary .mdc-radio--disabled + label {
  color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.3803921569));
}
.dark-theme-portal-base .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #039be5;
  --mdc-radio-selected-hover-icon-color: #039be5;
  --mdc-radio-selected-icon-color: #039be5;
  --mdc-radio-selected-pressed-icon-color: #039be5;
  --mat-mdc-radio-ripple-color: #fff;
  --mat-mdc-radio-checked-ripple-color: #039be5;
}
.dark-theme-portal-base .mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label {
  color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.3803921569));
}
.dark-theme-portal-base .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-mdc-radio-ripple-color: #fff;
  --mat-mdc-radio-checked-ripple-color: #f44336;
}
.dark-theme-portal-base .mat-mdc-radio-button.mat-warn .mdc-radio--disabled + label {
  color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.3803921569));
}
.dark-theme-portal-base .mat-mdc-radio-button .mdc-radio {
  padding: calc((40px - 20px) / 2);
}
.dark-theme-portal-base .mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  top: calc(-1 * (40px - 20px) / 2);
  left: calc(-1 * (40px - 20px) / 2);
  width: 40px;
  height: 40px;
}
.dark-theme-portal-base .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control {
  top: calc((40px - 40px) / 2);
  right: calc((40px - 40px) / 2);
  left: calc((40px - 40px) / 2);
  width: 40px;
  height: 40px;
}
.dark-theme-portal-base .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 24px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.dark-theme-portal-base .mat-mdc-slider {
  --mdc-slider-label-container-color: white;
  --mdc-slider-label-label-text-color: black;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mat-mdc-slider-value-indicator-opacity: 0.9;
}
.dark-theme-portal-base .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #138fce;
  --mdc-slider-focus-handle-color: #138fce;
  --mdc-slider-hover-handle-color: #138fce;
  --mdc-slider-active-track-color: #138fce;
  --mdc-slider-inactive-track-color: #138fce;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #138fce;
  --mat-mdc-slider-ripple-color: #138fce;
  --mat-mdc-slider-hover-ripple-color: rgba(19, 143, 206, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(19, 143, 206, 0.2);
}
.dark-theme-portal-base .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #039be5;
  --mdc-slider-focus-handle-color: #039be5;
  --mdc-slider-hover-handle-color: #039be5;
  --mdc-slider-active-track-color: #039be5;
  --mdc-slider-inactive-track-color: #039be5;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #039be5;
  --mat-mdc-slider-ripple-color: #039be5;
  --mat-mdc-slider-hover-ripple-color: rgba(3, 155, 229, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(3, 155, 229, 0.2);
}
.dark-theme-portal-base .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}
.dark-theme-portal-base .mat-mdc-slider {
  --mdc-slider-label-label-text-font: Inter;
  --mdc-slider-label-label-text-size: 12px;
  --mdc-slider-label-label-text-line-height: 24px;
  --mdc-slider-label-label-text-tracking: -0.02;
  --mdc-slider-label-label-text-weight: 550;
}
.dark-theme-portal-base .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, var(--b3n-background, #313338));
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme-portal-base .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme-portal-base .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
.dark-theme-portal-base .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.dark-theme-portal-base .mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme-portal-base .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
.dark-theme-portal-base .mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme-portal-base .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
.dark-theme-portal-base .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
.dark-theme-portal-base .mdc-list-item--disabled .mdc-list-item__start,
.dark-theme-portal-base .mdc-list-item--disabled .mdc-list-item__content,
.dark-theme-portal-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
.dark-theme-portal-base .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme-portal-base .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme-portal-base .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme-portal-base .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme-portal-base .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme-portal-base .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme-portal-base .mdc-list-item--selected .mdc-list-item__primary-text,
.dark-theme-portal-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #138fce);
}
.dark-theme-portal-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme-portal-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #138fce);
}
.dark-theme-portal-base .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme-portal-base .mdc-list-divider::after {
  border-bottom-color: white;
}
.dark-theme-portal-base .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.dark-theme-portal-base .mat-mdc-menu-item[disabled],
.dark-theme-portal-base .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.dark-theme-portal-base .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(255, 255, 255, 0.5));
}
.dark-theme-portal-base .mat-mdc-menu-item .mat-icon-no-color,
.dark-theme-portal-base .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme-portal-base .mat-mdc-menu-item:hover:not([disabled]),
.dark-theme-portal-base .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.dark-theme-portal-base .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.dark-theme-portal-base .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.08);
}
.dark-theme-portal-base .mat-mdc-menu-content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-subtitle1-font-size, 1em);
  line-height: var(--mdc-typography-subtitle1-line-height, 24px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}
.dark-theme-portal-base .mat-mdc-menu-content,
.dark-theme-portal-base .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-body1-font-size, 1em);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}
.dark-theme-portal-base .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: var(--b3n-color, #c1c1c1);
  --mdc-list-list-item-supporting-text-color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.8705882353));
  --mdc-list-list-item-leading-icon-color: var(--b3n-highlight, #424242);
  --mdc-list-list-item-trailing-supporting-text-color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.3803921569));
  --mdc-list-list-item-trailing-icon-color: var(--b3n-highlight, #424242);
  --mdc-list-list-item-selected-trailing-icon-color: var(--b3n-highlight, #424242);
  --mdc-list-list-item-disabled-label-text-color: var(--b3n-highlight, #424242);
  --mdc-list-list-item-disabled-leading-icon-color: var(--b3n-highlight, #424242);
  --mdc-list-list-item-disabled-trailing-icon-color: var(--b3n-highlight, #424242);
  --mdc-list-list-item-hover-label-text-color: var(--b3n-color, #c1c1c1);
  --mdc-list-list-item-hover-leading-icon-color: var(--b3n-highlight, #424242);
  --mdc-list-list-item-hover-trailing-icon-color: var(--b3n-highlight, #424242);
  --mdc-list-list-item-focus-label-text-color: var(--b3n-color, #c1c1c1);
  --mdc-list-list-item-hover-state-layer-color: var(--b3n-highlight, #424242);
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: var(--b3n-highlight, #424242);
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
}
.dark-theme-portal-base .mat-mdc-list-option .mdc-list-item__start, .dark-theme-portal-base .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #138fce;
  --mdc-checkbox-selected-hover-icon-color: #138fce;
  --mdc-checkbox-selected-icon-color: #138fce;
  --mdc-checkbox-selected-pressed-icon-color: #138fce;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.dark-theme-portal-base .mat-mdc-list-option .mdc-list-item__start, .dark-theme-portal-base .mat-mdc-list-option .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #138fce;
  --mdc-radio-selected-hover-icon-color: #138fce;
  --mdc-radio-selected-icon-color: #138fce;
  --mdc-radio-selected-pressed-icon-color: #138fce;
  --mat-mdc-radio-ripple-color: #fff;
  --mat-mdc-radio-checked-ripple-color: #138fce;
}
.dark-theme-portal-base .mat-mdc-list-option .mdc-list-item__start .mdc-radio--disabled + label, .dark-theme-portal-base .mat-mdc-list-option .mdc-list-item__end .mdc-radio--disabled + label {
  color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.3803921569));
}
.dark-theme-portal-base .mat-mdc-list-option.mat-accent .mdc-list-item__start, .dark-theme-portal-base .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #039be5;
  --mdc-checkbox-selected-hover-icon-color: #039be5;
  --mdc-checkbox-selected-icon-color: #039be5;
  --mdc-checkbox-selected-pressed-icon-color: #039be5;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.dark-theme-portal-base .mat-mdc-list-option.mat-accent .mdc-list-item__start, .dark-theme-portal-base .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #039be5;
  --mdc-radio-selected-hover-icon-color: #039be5;
  --mdc-radio-selected-icon-color: #039be5;
  --mdc-radio-selected-pressed-icon-color: #039be5;
  --mat-mdc-radio-ripple-color: #fff;
  --mat-mdc-radio-checked-ripple-color: #039be5;
}
.dark-theme-portal-base .mat-mdc-list-option.mat-accent .mdc-list-item__start .mdc-radio--disabled + label, .dark-theme-portal-base .mat-mdc-list-option.mat-accent .mdc-list-item__end .mdc-radio--disabled + label {
  color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.3803921569));
}
.dark-theme-portal-base .mat-mdc-list-option.mat-warn .mdc-list-item__start, .dark-theme-portal-base .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.dark-theme-portal-base .mat-mdc-list-option.mat-warn .mdc-list-item__start, .dark-theme-portal-base .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-mdc-radio-ripple-color: #fff;
  --mat-mdc-radio-checked-ripple-color: #f44336;
}
.dark-theme-portal-base .mat-mdc-list-option.mat-warn .mdc-list-item__start .mdc-radio--disabled + label, .dark-theme-portal-base .mat-mdc-list-option.mat-warn .mdc-list-item__end .mdc-radio--disabled + label {
  color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.3803921569));
}
.dark-theme-portal-base .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.dark-theme-portal-base .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #138fce;
}
.dark-theme-portal-base .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme-portal-base .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #138fce;
}
.dark-theme-portal-base .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.dark-theme-portal-base .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.dark-theme-portal-base .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.dark-theme-portal-base .mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}
.dark-theme-portal-base .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .dark-theme-portal-base .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .dark-theme-portal-base .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.dark-theme-portal-base .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .dark-theme-portal-base .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .dark-theme-portal-base .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}
.dark-theme-portal-base .mat-mdc-list-base {
  --mdc-list-list-item-label-text-font: Inter;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 1em;
  --mdc-list-list-item-label-text-tracking: -0.02;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Inter;
  --mdc-list-list-item-supporting-text-line-height: 24px;
  --mdc-list-list-item-supporting-text-size: 1em;
  --mdc-list-list-item-supporting-text-tracking: -0.02;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Inter;
  --mdc-list-list-item-trailing-supporting-text-line-height: 12px;
  --mdc-list-list-item-trailing-supporting-text-size: 10.5px;
  --mdc-list-list-item-trailing-supporting-text-tracking: -0.02;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}
.dark-theme-portal-base .mdc-list-group__subheader {
  font-size: 1em;
  font-weight: 400;
  line-height: 24px;
  font-family: Inter;
  letter-spacing: -0.02;
}
.dark-theme-portal-base .mat-mdc-paginator {
  background: var(--b3n-background, #313338);
  color: rgba(var(--b3n-highlight, #424242), 0.87);
}
.dark-theme-portal-base .mat-mdc-paginator-icon {
  fill: rgba(var(--b3n-highlight, #424242), 0.54);
}
.dark-theme-portal-base .mat-mdc-paginator-decrement,
.dark-theme-portal-base .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(var(--b3n-highlight, #424242), 0.54);
  border-right: 2px solid rgba(var(--b3n-highlight, #424242), 0.54);
}
.dark-theme-portal-base .mat-mdc-paginator-first,
.dark-theme-portal-base .mat-mdc-paginator-last {
  border-top: 2px solid rgba(var(--b3n-highlight, #424242), 0.54);
}
.dark-theme-portal-base .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.dark-theme-portal-base .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.dark-theme-portal-base .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.dark-theme-portal-base .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(var(--b3n-highlight, #424242), 0.12);
}
.dark-theme-portal-base .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(var(--b3n-highlight, #424242), 0.12);
}
.dark-theme-portal-base .mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.dark-theme-portal-base .mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.dark-theme-portal-base .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.dark-theme-portal-base .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.dark-theme-portal-base .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.dark-theme-portal-base .mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.dark-theme-portal-base .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}
.dark-theme-portal-base .mat-mdc-paginator-container {
  min-height: 56px;
}
.dark-theme-portal-base .mat-mdc-paginator {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-caption-font-size, 10.5px);
  line-height: var(--mdc-typography-caption-line-height, 12px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}
.dark-theme-portal-base .mat-mdc-paginator .mat-mdc-select-value {
  font-size: 10.5px;
}
.dark-theme-portal-base .mat-mdc-tab, .dark-theme-portal-base .mat-mdc-tab-link {
  background-color: transparent;
}
.dark-theme-portal-base .mat-mdc-tab .mdc-tab__text-label, .dark-theme-portal-base .mat-mdc-tab-link .mdc-tab__text-label {
  color: rgba(255, 255, 255, 0.6);
}
.dark-theme-portal-base .mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.dark-theme-portal-base .mat-mdc-tab.mat-mdc-tab-disabled .mat-ripple-element, .dark-theme-portal-base .mat-mdc-tab-link.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.dark-theme-portal-base .mat-mdc-tab-link.mat-mdc-tab-disabled .mat-ripple-element {
  background-color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.3803921569));
}
.dark-theme-portal-base .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .dark-theme-portal-base .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #138fce;
}
.dark-theme-portal-base .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .dark-theme-portal-base .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #138fce);
}
.dark-theme-portal-base .mdc-tab__ripple::before,
.dark-theme-portal-base .mat-mdc-tab .mat-ripple-element,
.dark-theme-portal-base .mat-mdc-tab-header-pagination .mat-ripple-element,
.dark-theme-portal-base .mat-mdc-tab-link .mat-ripple-element {
  background-color: #138fce;
}
.dark-theme-portal-base .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .dark-theme-portal-base .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .dark-theme-portal-base .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .dark-theme-portal-base .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #039be5;
}
.dark-theme-portal-base .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .dark-theme-portal-base .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .dark-theme-portal-base .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .dark-theme-portal-base .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #039be5);
}
.dark-theme-portal-base .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.dark-theme-portal-base .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.dark-theme-portal-base .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.dark-theme-portal-base .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .dark-theme-portal-base .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.dark-theme-portal-base .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.dark-theme-portal-base .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.dark-theme-portal-base .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #039be5;
}
.dark-theme-portal-base .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .dark-theme-portal-base .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .dark-theme-portal-base .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .dark-theme-portal-base .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #f44336;
}
.dark-theme-portal-base .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .dark-theme-portal-base .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .dark-theme-portal-base .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .dark-theme-portal-base .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #f44336);
}
.dark-theme-portal-base .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.dark-theme-portal-base .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.dark-theme-portal-base .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.dark-theme-portal-base .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .dark-theme-portal-base .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.dark-theme-portal-base .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.dark-theme-portal-base .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.dark-theme-portal-base .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #f44336;
}
.dark-theme-portal-base .mat-mdc-tab-group.mat-background-primary, .dark-theme-portal-base .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #138fce;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.dark-theme-portal-base .mat-mdc-tab-group.mat-background-accent, .dark-theme-portal-base .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #039be5;
  --mat-mdc-tab-header-with-background-foreground-color: #000;
}
.dark-theme-portal-base .mat-mdc-tab-group.mat-background-warn, .dark-theme-portal-base .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #f44336;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.dark-theme-portal-base .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #fff);
}
.dark-theme-portal-base .mat-mdc-tab-header .mdc-tab {
  height: 48px;
}
.dark-theme-portal-base .mdc-tab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-button-font-size, 1em);
  line-height: var(--mdc-typography-button-line-height, 36px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.dark-theme-portal-base .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.dark-theme-portal-base .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme-portal-base .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #fff;
}
.dark-theme-portal-base .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #138fce;
  --mdc-checkbox-selected-hover-icon-color: #138fce;
  --mdc-checkbox-selected-icon-color: #138fce;
  --mdc-checkbox-selected-pressed-icon-color: #138fce;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.dark-theme-portal-base .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(19, 143, 206, 0.1);
}
.dark-theme-portal-base .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #138fce;
}
.dark-theme-portal-base .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #039be5;
  --mdc-checkbox-selected-hover-icon-color: #039be5;
  --mdc-checkbox-selected-icon-color: #039be5;
  --mdc-checkbox-selected-pressed-icon-color: #039be5;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.dark-theme-portal-base .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(3, 155, 229, 0.1);
}
.dark-theme-portal-base .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #039be5;
}
.dark-theme-portal-base .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.dark-theme-portal-base .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(244, 67, 54, 0.1);
}
.dark-theme-portal-base .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #f44336;
}
.dark-theme-portal-base .mat-mdc-checkbox-disabled label {
  color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.3803921569));
}
.dark-theme-portal-base .mat-mdc-checkbox .mdc-checkbox {
  padding: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  margin: calc((var(--mdc-checkbox-touch-target-size, 40px) - 40px) / 2);
}
.dark-theme-portal-base .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  top: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  left: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
}
.dark-theme-portal-base .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
  top: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  right: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  left: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  width: var(--mdc-checkbox-touch-target-size, 40px);
  height: var(--mdc-checkbox-touch-target-size, 40px);
}
@media all and (-ms-high-contrast: none) {
  .dark-theme-portal-base .mdc-checkbox .mdc-checkbox__focus-ring {
    display: none;
  }
}
.dark-theme-portal-base .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 24px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.dark-theme-portal-base .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #fff;
}
.dark-theme-portal-base .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #138fce;
}
.dark-theme-portal-base .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #039be5;
}
.dark-theme-portal-base .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.dark-theme-portal-base .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.dark-theme-portal-base .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: var(--b3n-background, #313338);
  --mdc-filled-button-label-text-color: #fff;
}
.dark-theme-portal-base .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #138fce;
  --mdc-filled-button-label-text-color: #fff;
}
.dark-theme-portal-base .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #039be5;
  --mdc-filled-button-label-text-color: #000;
}
.dark-theme-portal-base .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.dark-theme-portal-base .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.dark-theme-portal-base .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: var(--b3n-background, #313338);
  --mdc-protected-button-label-text-color: #fff;
}
.dark-theme-portal-base .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #138fce;
  --mdc-protected-button-label-text-color: #fff;
}
.dark-theme-portal-base .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #039be5;
  --mdc-protected-button-label-text-color: #000;
}
.dark-theme-portal-base .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.dark-theme-portal-base .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation: 0;
}
.dark-theme-portal-base .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.dark-theme-portal-base .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
.dark-theme-portal-base .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #138fce;
}
.dark-theme-portal-base .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #039be5;
}
.dark-theme-portal-base .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.dark-theme-portal-base .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
}
.dark-theme-portal-base .mat-mdc-button, .dark-theme-portal-base .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme-portal-base .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .dark-theme-portal-base .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.dark-theme-portal-base .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark-theme-portal-base .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .dark-theme-portal-base .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark-theme-portal-base .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme-portal-base .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .dark-theme-portal-base .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme-portal-base .mat-mdc-button.mat-primary, .dark-theme-portal-base .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #138fce;
  --mat-mdc-button-ripple-color: rgba(19, 143, 206, 0.1);
}
.dark-theme-portal-base .mat-mdc-button.mat-accent, .dark-theme-portal-base .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #039be5;
  --mat-mdc-button-ripple-color: rgba(3, 155, 229, 0.1);
}
.dark-theme-portal-base .mat-mdc-button.mat-warn, .dark-theme-portal-base .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.dark-theme-portal-base .mat-mdc-raised-button, .dark-theme-portal-base .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme-portal-base .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .dark-theme-portal-base .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.dark-theme-portal-base .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark-theme-portal-base .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .dark-theme-portal-base .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark-theme-portal-base .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme-portal-base .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .dark-theme-portal-base .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme-portal-base .mat-mdc-raised-button.mat-primary, .dark-theme-portal-base .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme-portal-base .mat-mdc-raised-button.mat-accent, .dark-theme-portal-base .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.dark-theme-portal-base .mat-mdc-raised-button.mat-warn, .dark-theme-portal-base .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme-portal-base .mat-mdc-button.mat-mdc-button-base,
.dark-theme-portal-base .mat-mdc-raised-button.mat-mdc-button-base,
.dark-theme-portal-base .mat-mdc-unelevated-button.mat-mdc-button-base,
.dark-theme-portal-base .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}
.dark-theme-portal-base .mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-button-font-size, 1em);
  line-height: var(--mdc-typography-button-line-height, 36px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.dark-theme-portal-base .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme-portal-base .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.dark-theme-portal-base .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark-theme-portal-base .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme-portal-base .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme-portal-base .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #138fce;
  --mat-mdc-button-ripple-color: rgba(19, 143, 206, 0.1);
}
.dark-theme-portal-base .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #039be5;
  --mat-mdc-button-ripple-color: rgba(3, 155, 229, 0.1);
}
.dark-theme-portal-base .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.dark-theme-portal-base .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #138fce;
}
.dark-theme-portal-base .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #039be5;
}
.dark-theme-portal-base .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
}
.dark-theme-portal-base .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
.dark-theme-portal-base .mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}
.dark-theme-portal-base .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
}
.dark-theme-portal-base .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.dark-theme-portal-base .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.dark-theme-portal-base .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}
.dark-theme-portal-base .mat-mdc-fab, .dark-theme-portal-base .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme-portal-base .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .dark-theme-portal-base .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.dark-theme-portal-base .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark-theme-portal-base .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .dark-theme-portal-base .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .dark-theme-portal-base .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme-portal-base .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .dark-theme-portal-base .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.dark-theme-portal-base .mat-mdc-fab.mat-primary, .dark-theme-portal-base .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme-portal-base .mat-mdc-fab.mat-accent, .dark-theme-portal-base .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.dark-theme-portal-base .mat-mdc-fab.mat-warn, .dark-theme-portal-base .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme-portal-base .mat-mdc-fab.mat-unthemed, .dark-theme-portal-base .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: var(--b3n-background, #313338);
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.dark-theme-portal-base .mat-mdc-fab.mat-primary, .dark-theme-portal-base .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #138fce;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.dark-theme-portal-base .mat-mdc-fab.mat-accent, .dark-theme-portal-base .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #039be5;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.dark-theme-portal-base .mat-mdc-fab.mat-warn, .dark-theme-portal-base .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.dark-theme-portal-base .mat-mdc-fab[disabled][disabled], .dark-theme-portal-base .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(255, 255, 255, 0.12);
  --mdc-fab-icon-color: rgba(255, 255, 255, 0.5);
  --mat-mdc-fab-color: rgba(255, 255, 255, 0.5);
}
.dark-theme-portal-base .mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-button-font-size, 1em);
  line-height: var(--mdc-typography-button-line-height, 36px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.dark-theme-portal-base .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: rgba(0, 0, 0, 0.87);
  --mdc-snackbar-container-color: #fff;
  --mdc-snackbar-supporting-text-color: var(--b3n-background, #313338);
}
.dark-theme-portal-base .mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: Inter;
  --mdc-snackbar-supporting-text-line-height: 24px;
  --mdc-snackbar-supporting-text-size: 1em;
  --mdc-snackbar-supporting-text-weight: 400;
}
.dark-theme-portal-base .mdc-data-table {
  background-color: var(--mdc-theme-surface, var(--b3n-background, #313338));
  border-color: rgba(255, 255, 255, 0.12);
}
.dark-theme-portal-base .mdc-data-table__row {
  background-color: inherit;
}
.dark-theme-portal-base .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, var(--b3n-background, #313338));
}
.dark-theme-portal-base .mdc-data-table__row--selected {
  background-color: rgba(19, 143, 206, 0.04);
}
.dark-theme-portal-base .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.dark-theme-portal-base .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.dark-theme-portal-base .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.12);
}
.dark-theme-portal-base .mdc-data-table__cell,
.dark-theme-portal-base .mdc-data-table__header-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.dark-theme-portal-base .mdc-data-table__pagination {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.dark-theme-portal-base .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(255, 255, 255, 0.04);
}
.dark-theme-portal-base .mdc-data-table__header-cell {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme-portal-base .mdc-data-table__pagination-total,
.dark-theme-portal-base .mdc-data-table__pagination-rows-per-page-label,
.dark-theme-portal-base .mdc-data-table__cell {
  color: rgba(255, 255, 255, 0.87);
}
[dir=rtl] .dark-theme-portal-base .mdc-data-table__pagination-button .mdc-button__icon, .dark-theme-portal-base .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.dark-theme-portal-base .mat-mdc-table {
  background: var(--b3n-background, #313338);
}
.dark-theme-portal-base .mat-mdc-table .mdc-data-table__row {
  height: 52px;
}
.dark-theme-portal-base .mat-mdc-table .mdc-data-table__pagination {
  min-height: 52px;
}
.dark-theme-portal-base .mat-mdc-table .mdc-data-table__header-row {
  height: 56px;
}
.dark-theme-portal-base .mdc-data-table__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 24px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.dark-theme-portal-base .mdc-data-table__cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-body2-font-size, 1em);
  line-height: var(--mdc-typography-body2-line-height, 24px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.dark-theme-portal-base .mdc-data-table__header-cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Inter));
  font-size: var(--mdc-typography-subtitle2-font-size, 12px);
  line-height: var(--mdc-typography-subtitle2-line-height, 24px);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 550);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, -0.02);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}
[dir=rtl] .dark-theme-portal-base .mdc-data-table__pagination-button .mdc-button__icon, .dark-theme-portal-base .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.dark-theme-portal-base .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #138fce;
}
.dark-theme-portal-base .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #039be5;
}
.dark-theme-portal-base .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}
.dark-theme-portal-base .mat-badge {
  position: relative;
}
.dark-theme-portal-base .mat-badge.mat-badge {
  overflow: visible;
}
.dark-theme-portal-base .mat-badge-hidden .mat-badge-content {
  display: none;
}
.dark-theme-portal-base .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.dark-theme-portal-base .ng-animate-disabled .mat-badge-content,
.dark-theme-portal-base .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.dark-theme-portal-base .mat-badge-content.mat-badge-active {
  transform: none;
}
.dark-theme-portal-base .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.dark-theme-portal-base .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.dark-theme-portal-base .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.dark-theme-portal-base .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .dark-theme-portal-base .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.dark-theme-portal-base .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .dark-theme-portal-base .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.dark-theme-portal-base .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .dark-theme-portal-base .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.dark-theme-portal-base .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .dark-theme-portal-base .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.dark-theme-portal-base .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.dark-theme-portal-base .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.dark-theme-portal-base .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.dark-theme-portal-base .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .dark-theme-portal-base .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.dark-theme-portal-base .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .dark-theme-portal-base .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.dark-theme-portal-base .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .dark-theme-portal-base .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.dark-theme-portal-base .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .dark-theme-portal-base .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.dark-theme-portal-base .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.dark-theme-portal-base .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.dark-theme-portal-base .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.dark-theme-portal-base .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .dark-theme-portal-base .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.dark-theme-portal-base .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .dark-theme-portal-base .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.dark-theme-portal-base .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .dark-theme-portal-base .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.dark-theme-portal-base .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .dark-theme-portal-base .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.dark-theme-portal-base .mat-badge-content {
  color: white;
  background: #138fce;
}
.cdk-high-contrast-active .dark-theme-portal-base .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.dark-theme-portal-base .mat-badge-accent .mat-badge-content {
  background: #039be5;
  color: white;
}
.dark-theme-portal-base .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.dark-theme-portal-base .mat-badge-disabled .mat-badge-content {
  background: var(--b3n-disabled, rgba(0, 0, 0, 0.8705882353));
  color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.3803921569));
}
.dark-theme-portal-base .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Inter;
}
.dark-theme-portal-base .mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.dark-theme-portal-base .mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.dark-theme-portal-base .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: var(--b3n-background, #313338);
  color: var(--b3n-color, #c1c1c1);
}
.dark-theme-portal-base .mat-bottom-sheet-container {
  font-size: 1em;
  font-weight: 400;
  line-height: 24px;
  font-family: Inter;
  letter-spacing: -0.02;
}
.dark-theme-portal-base .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.dark-theme-portal-base .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.dark-theme-portal-base .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.dark-theme-portal-base .mat-button-toggle {
  color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.3803921569));
}
.dark-theme-portal-base .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.dark-theme-portal-base .mat-button-toggle-appearance-standard {
  color: var(--b3n-color, #c1c1c1);
  background: var(--b3n-background, #313338);
}
.dark-theme-portal-base .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.dark-theme-portal-base .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px var(--b3n-border, #444444);
}
.dark-theme-portal-base [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px var(--b3n-border, #444444);
}
.dark-theme-portal-base .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px var(--b3n-border, #444444);
}
.dark-theme-portal-base .mat-button-toggle-checked {
  background-color: #212121;
  color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.8705882353));
}
.dark-theme-portal-base .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: var(--b3n-color, #c1c1c1);
}
.dark-theme-portal-base .mat-button-toggle-disabled {
  color: var(--b3n-disabled, rgba(0, 0, 0, 0.8705882353));
  background-color: black;
}
.dark-theme-portal-base .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: var(--b3n-background, #313338);
}
.dark-theme-portal-base .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.dark-theme-portal-base .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.dark-theme-portal-base .mat-button-toggle-group-appearance-standard {
  border: solid 1px var(--b3n-border, #444444);
}
.dark-theme-portal-base .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}
.dark-theme-portal-base .mat-button-toggle {
  font-family: Inter;
}
.dark-theme-portal-base .mat-calendar-arrow {
  fill: var(--b3n-icon-color, #888);
}
.dark-theme-portal-base .mat-datepicker-toggle,
.dark-theme-portal-base .mat-datepicker-content .mat-calendar-next-button,
.dark-theme-portal-base .mat-datepicker-content .mat-calendar-previous-button {
  color: var(--b3n-icon-color, #888);
}
.dark-theme-portal-base .mat-calendar-table-header-divider::after {
  background: var(--b3n-border, #444444);
}
.dark-theme-portal-base .mat-calendar-table-header,
.dark-theme-portal-base .mat-calendar-body-label {
  color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.8705882353));
}
.dark-theme-portal-base .mat-calendar-body-cell-content,
.dark-theme-portal-base .mat-date-range-input-separator {
  color: var(--b3n-color, #c1c1c1);
  border-color: transparent;
}
.dark-theme-portal-base .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.3803921569));
}
.dark-theme-portal-base .mat-form-field-disabled .mat-date-range-input-separator {
  color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.3803921569));
}
.dark-theme-portal-base .mat-calendar-body-in-preview {
  color: var(--b3n-border, #444444);
}
.dark-theme-portal-base .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.3803921569));
}
.dark-theme-portal-base .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  opacity: 0.5;
}
.dark-theme-portal-base .mat-calendar-body-in-range::before {
  background: rgba(19, 143, 206, 0.2);
}
.dark-theme-portal-base .mat-calendar-body-comparison-identical,
.dark-theme-portal-base .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.dark-theme-portal-base .mat-calendar-body-comparison-bridge-start::before,
.dark-theme-portal-base [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(19, 143, 206, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme-portal-base .mat-calendar-body-comparison-bridge-end::before,
.dark-theme-portal-base [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(19, 143, 206, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme-portal-base .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.dark-theme-portal-base .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.dark-theme-portal-base .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.dark-theme-portal-base .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.dark-theme-portal-base .mat-calendar-body-selected {
  background-color: #138fce;
  color: white;
}
.dark-theme-portal-base .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(19, 143, 206, 0.4);
}
.dark-theme-portal-base .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.dark-theme-portal-base .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.dark-theme-portal-base .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(19, 143, 206, 0.3);
}
@media (hover: hover) {
  .dark-theme-portal-base .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(19, 143, 206, 0.3);
  }
}
.dark-theme-portal-base .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: var(--b3n-background, #313338);
  color: var(--b3n-color, #c1c1c1);
}
.dark-theme-portal-base .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(3, 155, 229, 0.2);
}
.dark-theme-portal-base .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.dark-theme-portal-base .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.dark-theme-portal-base .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.dark-theme-portal-base .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(3, 155, 229, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme-portal-base .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.dark-theme-portal-base .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(3, 155, 229, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme-portal-base .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.dark-theme-portal-base .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.dark-theme-portal-base .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.dark-theme-portal-base .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.dark-theme-portal-base .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #039be5;
  color: white;
}
.dark-theme-portal-base .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(3, 155, 229, 0.4);
}
.dark-theme-portal-base .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.dark-theme-portal-base .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.dark-theme-portal-base .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(3, 155, 229, 0.3);
}
@media (hover: hover) {
  .dark-theme-portal-base .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(3, 155, 229, 0.3);
  }
}
.dark-theme-portal-base .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.dark-theme-portal-base .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.dark-theme-portal-base .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.dark-theme-portal-base .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.dark-theme-portal-base .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme-portal-base .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.dark-theme-portal-base .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme-portal-base .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.dark-theme-portal-base .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.dark-theme-portal-base .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.dark-theme-portal-base .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.dark-theme-portal-base .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.dark-theme-portal-base .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.dark-theme-portal-base .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.dark-theme-portal-base .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.dark-theme-portal-base .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .dark-theme-portal-base .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.dark-theme-portal-base .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-datepicker-toggle-active {
  color: #138fce;
}
.dark-theme-portal-base .mat-datepicker-toggle-active.mat-accent {
  color: #039be5;
}
.dark-theme-portal-base .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.dark-theme-portal-base .mat-date-range-input-inner[disabled] {
  color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.3803921569));
}
.dark-theme-portal-base .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.dark-theme-portal-base .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.dark-theme-portal-base .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.dark-theme-portal-base .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.dark-theme-portal-base .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.dark-theme-portal-base .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
.dark-theme-portal-base .mat-calendar {
  font-family: Inter;
}
.dark-theme-portal-base .mat-calendar-body {
  font-size: 13px;
}
.dark-theme-portal-base .mat-calendar-body-label,
.dark-theme-portal-base .mat-calendar-period-button {
  font-size: 1em;
  font-weight: 500;
}
.dark-theme-portal-base .mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.dark-theme-portal-base .mat-divider {
  border-top-color: var(--b3n-border, #444444);
}
.dark-theme-portal-base .mat-divider-vertical {
  border-right-color: var(--b3n-border, #444444);
}
.dark-theme-portal-base .mat-expansion-panel {
  background: var(--b3n-background, #313338);
  color: var(--b3n-color, #c1c1c1);
}
.dark-theme-portal-base .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-action-row {
  border-top-color: var(--b3n-border, #444444);
}
.dark-theme-portal-base .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .dark-theme-portal-base .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .dark-theme-portal-base .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .dark-theme-portal-base .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: var(--b3n-background, #313338);
  }
}
.dark-theme-portal-base .mat-expansion-panel-header-title {
  color: var(--b3n-color, #c1c1c1);
}
.dark-theme-portal-base .mat-expansion-panel-header-description,
.dark-theme-portal-base .mat-expansion-indicator::after {
  color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.8705882353));
}
.dark-theme-portal-base .mat-expansion-panel-header[aria-disabled=true] {
  color: var(--b3n-disabled, rgba(0, 0, 0, 0.8705882353));
}
.dark-theme-portal-base .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.dark-theme-portal-base .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.dark-theme-portal-base .mat-expansion-panel-header {
  height: 48px;
}
.dark-theme-portal-base .mat-expansion-panel-header.mat-expanded {
  height: 64px;
}
.dark-theme-portal-base .mat-expansion-panel-header {
  font-family: Inter;
  font-size: 12px;
  font-weight: 550;
}
.dark-theme-portal-base .mat-expansion-panel-content {
  font-size: 1em;
  font-weight: 400;
  line-height: 24px;
  font-family: Inter;
  letter-spacing: -0.02;
}
.dark-theme-portal-base .mat-grid-tile-header,
.dark-theme-portal-base .mat-grid-tile-footer {
  font-size: 1em;
}
.dark-theme-portal-base .mat-grid-tile-header .mat-line,
.dark-theme-portal-base .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.dark-theme-portal-base .mat-grid-tile-header .mat-line:nth-child(n+2),
.dark-theme-portal-base .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 10.5px;
}
.dark-theme-portal-base .mat-icon.mat-primary {
  color: #138fce;
}
.dark-theme-portal-base .mat-icon.mat-accent {
  color: #039be5;
}
.dark-theme-portal-base .mat-icon.mat-warn {
  color: #f44336;
}
.dark-theme-portal-base .mat-drawer-container {
  background-color: #313338;
  color: var(--b3n-color, #c1c1c1);
}
.dark-theme-portal-base .mat-drawer {
  background-color: var(--b3n-background, #313338);
  color: var(--b3n-color, #c1c1c1);
}
.dark-theme-portal-base .mat-drawer.mat-drawer-push {
  background-color: var(--b3n-background, #313338);
}
.dark-theme-portal-base .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-drawer-side {
  border-right: solid 1px var(--b3n-border, #444444);
}
.dark-theme-portal-base .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px var(--b3n-border, #444444);
  border-right: none;
}
.dark-theme-portal-base [dir=rtl] .mat-drawer-side {
  border-left: solid 1px var(--b3n-border, #444444);
  border-right: none;
}
.dark-theme-portal-base [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px var(--b3n-border, #444444);
}
.dark-theme-portal-base .mat-drawer-backdrop.mat-drawer-shown {
  background-color: var(--b3n-background, #313338);
  opacity: 0.6;
}
.dark-theme-portal-base .mat-step-header.cdk-keyboard-focused, .dark-theme-portal-base .mat-step-header.cdk-program-focused, .dark-theme-portal-base .mat-step-header:hover:not([aria-disabled]), .dark-theme-portal-base .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.dark-theme-portal-base .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .dark-theme-portal-base .mat-step-header:hover {
    background: none;
  }
}
.dark-theme-portal-base .mat-step-header .mat-step-label,
.dark-theme-portal-base .mat-step-header .mat-step-optional {
  color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.8705882353));
}
.dark-theme-portal-base .mat-step-header .mat-step-icon {
  background-color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.8705882353));
  color: white;
}
.dark-theme-portal-base .mat-step-header .mat-step-icon-selected,
.dark-theme-portal-base .mat-step-header .mat-step-icon-state-done,
.dark-theme-portal-base .mat-step-header .mat-step-icon-state-edit {
  background-color: #138fce;
  color: white;
}
.dark-theme-portal-base .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.dark-theme-portal-base .mat-step-header.mat-accent .mat-step-icon-selected,
.dark-theme-portal-base .mat-step-header.mat-accent .mat-step-icon-state-done,
.dark-theme-portal-base .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #039be5;
  color: white;
}
.dark-theme-portal-base .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.dark-theme-portal-base .mat-step-header.mat-warn .mat-step-icon-selected,
.dark-theme-portal-base .mat-step-header.mat-warn .mat-step-icon-state-done,
.dark-theme-portal-base .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.dark-theme-portal-base .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.dark-theme-portal-base .mat-step-header .mat-step-label.mat-step-label-active {
  color: var(--b3n-color, #c1c1c1);
}
.dark-theme-portal-base .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.dark-theme-portal-base .mat-stepper-horizontal, .dark-theme-portal-base .mat-stepper-vertical {
  background-color: var(--b3n-background, #313338);
}
.dark-theme-portal-base .mat-stepper-vertical-line::before {
  border-left-color: var(--b3n-border, #444444);
}
.dark-theme-portal-base .mat-horizontal-stepper-header::before,
.dark-theme-portal-base .mat-horizontal-stepper-header::after,
.dark-theme-portal-base .mat-stepper-horizontal-line {
  border-top-color: var(--b3n-border, #444444);
}
.dark-theme-portal-base .mat-horizontal-stepper-header {
  height: 72px;
}
.dark-theme-portal-base .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.dark-theme-portal-base .mat-vertical-stepper-header {
  padding: 24px 24px;
}
.dark-theme-portal-base .mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}
.dark-theme-portal-base .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .dark-theme-portal-base .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}
.dark-theme-portal-base .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}
.dark-theme-portal-base .mat-stepper-vertical, .dark-theme-portal-base .mat-stepper-horizontal {
  font-family: Inter;
}
.dark-theme-portal-base .mat-step-label {
  font-size: 1em;
  font-weight: 400;
}
.dark-theme-portal-base .mat-step-sub-label-error {
  font-weight: normal;
}
.dark-theme-portal-base .mat-step-label-error {
  font-size: 1em;
}
.dark-theme-portal-base .mat-step-label-selected {
  font-size: 1em;
  font-weight: 400;
}
.dark-theme-portal-base .mat-sort-header-arrow {
  color: var(--b3n-secondary-color, rgba(193, 193, 193, 0.8705882353));
}
.dark-theme-portal-base .mat-toolbar {
  background: var(--b3n-background, #313338);
  color: var(--b3n-color, #c1c1c1);
}
.dark-theme-portal-base .mat-toolbar.mat-primary {
  background: #138fce;
  color: white;
}
.dark-theme-portal-base .mat-toolbar.mat-accent {
  background: #039be5;
  color: white;
}
.dark-theme-portal-base .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.dark-theme-portal-base .mat-toolbar .mat-form-field-underline,
.dark-theme-portal-base .mat-toolbar .mat-form-field-ripple,
.dark-theme-portal-base .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.dark-theme-portal-base .mat-toolbar .mat-form-field-label,
.dark-theme-portal-base .mat-toolbar .mat-focused .mat-form-field-label,
.dark-theme-portal-base .mat-toolbar .mat-select-value,
.dark-theme-portal-base .mat-toolbar .mat-select-arrow,
.dark-theme-portal-base .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.dark-theme-portal-base .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.dark-theme-portal-base .mat-toolbar-multiple-rows {
  min-height: 64px;
}
.dark-theme-portal-base .mat-toolbar-row, .dark-theme-portal-base .mat-toolbar-single-row {
  height: 64px;
}
@media (max-width: 599px) {
  .dark-theme-portal-base .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .dark-theme-portal-base .mat-toolbar-row, .dark-theme-portal-base .mat-toolbar-single-row {
    height: 56px;
  }
}
.dark-theme-portal-base .mat-toolbar,
.dark-theme-portal-base .mat-toolbar h1,
.dark-theme-portal-base .mat-toolbar h2,
.dark-theme-portal-base .mat-toolbar h3,
.dark-theme-portal-base .mat-toolbar h4,
.dark-theme-portal-base .mat-toolbar h5,
.dark-theme-portal-base .mat-toolbar h6 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Inter;
  letter-spacing: 0.0125em;
  margin: 0;
}
.dark-theme-portal-base .mat-tree {
  background: var(--b3n-background, #313338);
}
.dark-theme-portal-base .mat-tree-node,
.dark-theme-portal-base .mat-nested-tree-node {
  color: var(--b3n-color, #c1c1c1);
}
.dark-theme-portal-base .mat-tree-node {
  min-height: 48px;
}
.dark-theme-portal-base .mat-tree {
  font-family: Inter;
}
.dark-theme-portal-base .mat-tree-node,
.dark-theme-portal-base .mat-nested-tree-node {
  font-weight: 400;
  font-size: 1em;
}
.dark-theme-portal-base .mat-mdc-form-field-infix {
  min-height: 44px;
}
.dark-theme-portal-base .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 22px;
}
.dark-theme-portal-base .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -28.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.dark-theme-portal-base .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 10px;
  padding-bottom: 10px;
}
.dark-theme-portal-base .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 10px;
  padding-bottom: 10px;
}
.dark-theme-portal-base .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 10px;
  padding-bottom: 10px;
}
.dark-theme-portal-base .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}
.dark-theme-portal-base .mat-mdc-checkbox .mdc-checkbox {
  padding: calc((var(--mdc-checkbox-ripple-size, 28px) - 18px) / 2);
  margin: calc((var(--mdc-checkbox-touch-target-size, 28px) - 28px) / 2);
}
.dark-theme-portal-base .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  top: calc((var(--mdc-checkbox-ripple-size, 28px) - 18px) / 2);
  left: calc((var(--mdc-checkbox-ripple-size, 28px) - 18px) / 2);
}
.dark-theme-portal-base .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
  top: calc((28px - var(--mdc-checkbox-touch-target-size, 28px)) / 2);
  right: calc((28px - var(--mdc-checkbox-touch-target-size, 28px)) / 2);
  left: calc((28px - var(--mdc-checkbox-touch-target-size, 28px)) / 2);
  width: var(--mdc-checkbox-touch-target-size, 28px);
  height: var(--mdc-checkbox-touch-target-size, 28px);
}
.dark-theme-portal-base .mat-mdc-checkbox-touch-target {
  display: none;
}
.dark-theme-portal-base .mat-mdc-chip {
  height: 32px;
}
.dark-theme-portal-base .mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}
.dark-theme-portal-base .mat-mdc-radio-button .mdc-radio {
  padding: calc((28px - 20px) / 2);
  margin: 0;
}
.dark-theme-portal-base .mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  top: calc(-1 * (28px - 20px) / 2);
  left: calc(-1 * (28px - 20px) / 2);
  width: 28px;
  height: 28px;
}
.dark-theme-portal-base .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control {
  top: calc((28px - 28px) / 2);
  right: calc((28px - 28px) / 2);
  left: calc((28px - 28px) / 2);
  width: 28px;
  height: 28px;
}
.dark-theme-portal-base .mat-mdc-radio-touch-target {
  display: none;
}
.dark-theme-portal-base .mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 40px;
  --mdc-list-list-item-two-line-container-height: 56px;
  --mdc-list-list-item-three-line-container-height: 80px;
}
.dark-theme-portal-base .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .dark-theme-portal-base .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .dark-theme-portal-base .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 48px;
}
.dark-theme-portal-base .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .dark-theme-portal-base .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .dark-theme-portal-base .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 64px;
}
.dark-theme-portal-base .mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.dark-theme-portal-base .mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.dark-theme-portal-base .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.dark-theme-portal-base .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.dark-theme-portal-base .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.dark-theme-portal-base .mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.dark-theme-portal-base .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}
.dark-theme-portal-base .mat-mdc-paginator-container {
  min-height: 56px;
}
.dark-theme-portal-base .mat-mdc-tab-header .mdc-tab {
  height: 48px;
}
.dark-theme-portal-base .mat-mdc-button.mat-mdc-button-base,
.dark-theme-portal-base .mat-mdc-raised-button.mat-mdc-button-base,
.dark-theme-portal-base .mat-mdc-unelevated-button.mat-mdc-button-base,
.dark-theme-portal-base .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}
.dark-theme-portal-base .mat-mdc-icon-button.mat-mdc-button-base {
  width: 36px;
  height: 36px;
  padding: 6px;
}
.dark-theme-portal-base .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 36px;
  max-width: 36px;
}
.dark-theme-portal-base .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 36px;
  height: 36px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.dark-theme-portal-base .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 36px;
  max-width: 36px;
}
.dark-theme-portal-base .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 36px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 36px;
  transform: translate(-50%, -50%);
}
.dark-theme-portal-base .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
.dark-theme-portal-base .mat-mdc-table .mdc-data-table__row {
  height: 52px;
}
.dark-theme-portal-base .mat-mdc-table .mdc-data-table__pagination {
  min-height: 52px;
}
.dark-theme-portal-base .mat-mdc-table .mdc-data-table__header-row {
  height: 56px;
}
.dark-theme-portal-base .mat-expansion-panel-header {
  height: 48px;
}
.dark-theme-portal-base .mat-expansion-panel-header.mat-expanded {
  height: 64px;
}
.dark-theme-portal-base .mat-horizontal-stepper-header {
  height: 72px;
}
.dark-theme-portal-base .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.dark-theme-portal-base .mat-vertical-stepper-header {
  padding: 24px 24px;
}
.dark-theme-portal-base .mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}
.dark-theme-portal-base .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .dark-theme-portal-base .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}
.dark-theme-portal-base .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}
.dark-theme-portal-base .mat-toolbar-multiple-rows {
  min-height: 64px;
}
.dark-theme-portal-base .mat-toolbar-row, .dark-theme-portal-base .mat-toolbar-single-row {
  height: 64px;
}
@media (max-width: 599px) {
  .dark-theme-portal-base .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .dark-theme-portal-base .mat-toolbar-row, .dark-theme-portal-base .mat-toolbar-single-row {
    height: 56px;
  }
}
.dark-theme-portal-base .mat-tree-node {
  min-height: 48px;
}
.dark-theme-portal-base .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}
.dark-theme-portal-base .h0,
.dark-theme-portal-base h1,
.dark-theme-portal-base h2,
.dark-theme-portal-base h3,
.dark-theme-portal-base h4 {
  letter-spacing: -0.2px !important;
}
.dark-theme-portal-base .h0 {
  font-size: 24px !important;
  font-weight: 550 !important;
}
.dark-theme-portal-base h1,
.dark-theme-portal-base .h1 {
  font-size: 20px !important;
  font-weight: 550 !important;
  line-height: 32px !important;
}
.dark-theme-portal-base h2,
.dark-theme-portal-base .h2 {
  font-size: 16px !important;
  font-weight: 550 !important;
}
.dark-theme-portal-base h3,
.dark-theme-portal-base .h3 {
  font-size: 14px !important;
  font-weight: 550 !important;
}
.dark-theme-portal-base h4,
.dark-theme-portal-base .h4 {
  font-size: 12px;
  font-weight: 550;
}
.dark-theme-portal-base .body-2 {
  font-size: 12px !important;
  font-weight: 400 !important;
}
.dark-theme-portal-base strong,
.dark-theme-portal-base b {
  font-weight: 550;
}
.dark-theme-portal-base .mat-mdc-menu-content:has(button.mat-mdc-menu-item) {
  padding-right: 16px;
  border-radius: 8px !important;
}
.dark-theme-portal-base .mat-mdc-menu-content:has(button.mat-mdc-menu-item) button.mat-mdc-menu-item {
  min-height: 40px;
  margin-left: 8px;
  border-radius: 6px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.dark-theme-portal-base .mat-mdc-menu-content:has(button.mat-mdc-menu-item) button.mat-mdc-menu-item:hover:not([disabled]) {
  background-color: var(--b3n-selection-hover, #f2f3f5);
}
.dark-theme-portal-base .mat-mdc-menu-content:has(button.mat-mdc-menu-item) button.mat-mdc-menu-item:hover:not([disabled]) .mdc-list-item__primary-text,
.dark-theme-portal-base .mat-mdc-menu-content:has(button.mat-mdc-menu-item) button.mat-mdc-menu-item:hover:not([disabled]) mat-icon:not(.inherit-color),
.dark-theme-portal-base .mat-mdc-menu-content:has(button.mat-mdc-menu-item) button.mat-mdc-menu-item:hover:not([disabled]) .mat-mdc-menu-submenu-icon {
  color: var(--b3n-selection-text-hover, #313338);
}
.dark-theme-portal-base .mat-mdc-menu-content:has(button.mat-mdc-menu-item) button.mat-mdc-menu-item.mat-mdc-menu-item-highlighted {
  background-color: var(--b3n-selection-active, #ebf2fb) !important;
}
.dark-theme-portal-base .mat-mdc-menu-content:has(button.mat-mdc-menu-item) button.mat-mdc-menu-item.mat-mdc-menu-item-highlighted .mdc-list-item__primary-text,
.dark-theme-portal-base .mat-mdc-menu-content:has(button.mat-mdc-menu-item) button.mat-mdc-menu-item.mat-mdc-menu-item-highlighted mat-icon:not(.inherit-color),
.dark-theme-portal-base .mat-mdc-menu-content:has(button.mat-mdc-menu-item) button.mat-mdc-menu-item.mat-mdc-menu-item-highlighted .mat-mdc-menu-submenu-icon {
  color: var(--b3n-selection-text-active, "#136899");
}
.dark-theme-portal-base .ngx-mat-tel-input-mat-menu-panel .mat-mdc-menu-content:has(button.mat-mdc-menu-item) {
  padding-top: 0 !important;
  padding-right: 0 !important;
}
.dark-theme-portal-base .ngx-mat-tel-input-mat-menu-panel .mat-mdc-menu-content:has(button.mat-mdc-menu-item) button.country-list-button {
  padding-left: 8px !important;
  margin-left: 0 !important;
  border-radius: 0 !important;
}
.dark-theme-portal-base .mat-mdc-form-field.mat-focused.mat-primary:not(.mat-form-field-invalid) .mat-mdc-select-arrow {
  color: var(--b3n-link, #1979b1) !important;
}
.dark-theme-portal-base .mat-mdc-autocomplete-panel,
.dark-theme-portal-base .mat-mdc-select-panel {
  border-radius: 6px !important;
  min-width: max-content !important;
}
.dark-theme-portal-base .mat-mdc-autocomplete-panel mat-option:not(.ngx-mat-select-search),
.dark-theme-portal-base .mat-mdc-select-panel mat-option:not(.ngx-mat-select-search) {
  min-height: 40px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 6px;
  padding-left: 8px;
  padding-right: 8px;
}
.dark-theme-portal-base .mat-mdc-autocomplete-panel mat-option:not(.ngx-mat-select-search):not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled):hover,
.dark-theme-portal-base .mat-mdc-select-panel mat-option:not(.ngx-mat-select-search):not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled):hover {
  --mdc-theme-primary: var(--b3n-selection-text-hover, #313338);
  background-color: var(--b3n-selection-hover, #f2f3f5) !important;
}
.dark-theme-portal-base .mat-mdc-autocomplete-panel mat-option:not(.ngx-mat-select-search).more-input:hover,
.dark-theme-portal-base .mat-mdc-select-panel mat-option:not(.ngx-mat-select-search).more-input:hover {
  --mdc-theme-primary: var(--b3n-selection-text-hover, #313338);
  background-color: #fff !important;
}
.dark-theme-portal-base .mat-mdc-autocomplete-panel mat-option:not(.ngx-mat-select-search).more-input .mdc-list-item__primary-text,
.dark-theme-portal-base .mat-mdc-select-panel mat-option:not(.ngx-mat-select-search).more-input .mdc-list-item__primary-text {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dark-theme-portal-base .mat-mdc-autocomplete-panel mat-option:not(.ngx-mat-select-search):not(.mat-mdc-option-multiple).mdc-list-item--selected,
.dark-theme-portal-base .mat-mdc-select-panel mat-option:not(.ngx-mat-select-search):not(.mat-mdc-option-multiple).mdc-list-item--selected {
  --mdc-theme-primary: var(--b3n-selection-text-active, #136899);
  background-color: var(--b3n-selection-active, #ebf2fb) !important;
}
.dark-theme-portal-base .mat-mdc-autocomplete-panel .mat-mdc-option.mat-mdc-option-active,
.dark-theme-portal-base .mat-mdc-select-panel .mat-mdc-option.mat-mdc-option-active {
  background: var(--b3n-selection-hover, #f2f3f5);
  color: var(--b3n-color, #26272c) !important;
}
.dark-theme-portal-base .mat-mdc-autocomplete-panel mat-optgroup .mat-mdc-optgroup-label,
.dark-theme-portal-base .mat-mdc-select-panel mat-optgroup .mat-mdc-optgroup-label {
  font-size: 12px;
}
.dark-theme-portal-base .mat-mdc-autocomplete-panel mat-optgroup .mat-mdc-optgroup-label .mdc-list-item__primary-text,
.dark-theme-portal-base .mat-mdc-select-panel mat-optgroup .mat-mdc-optgroup-label .mdc-list-item__primary-text {
  font-weight: 550;
  color: var(--b3n-secondary-color, rgba(38, 39, 44, 0.8));
}
.dark-theme-portal-base mat-toolbar {
  font-size: inherit !important;
  letter-spacing: -0.2px !important;
  font-weight: 400 !important;
}
.dark-theme-portal-base mat-toolbar-row .mat-mdc-form-field-infix,
.dark-theme-portal-base .input-40px .mat-mdc-form-field-infix {
  min-height: 40px;
}
.dark-theme-portal-base mat-toolbar-row .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label,
.dark-theme-portal-base .input-40px .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.dark-theme-portal-base mat-toolbar-row .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.dark-theme-portal-base .input-40px .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.dark-theme-portal-base mat-toolbar-row .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix,
.dark-theme-portal-base .input-40px .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.dark-theme-portal-base mat-toolbar-row .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix,
.dark-theme-portal-base .input-40px .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.dark-theme-portal-base mat-toolbar-row .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix,
.dark-theme-portal-base .input-40px .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.dark-theme-portal-base mat-toolbar-row .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label,
.dark-theme-portal-base .input-40px .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}
.dark-theme-portal-base mat-toolbar-row mat-form-field,
.dark-theme-portal-base .input-40px mat-form-field {
  max-width: 280px;
}
.dark-theme-portal-base mat-toolbar-row mat-form-field .mat-mdc-form-field-infix,
.dark-theme-portal-base .input-40px mat-form-field .mat-mdc-form-field-infix {
  width: fit-content;
  min-width: 100px;
}
.dark-theme-portal-base mat-toolbar-row mat-form-field .mat-mdc-form-field-infix .mat-mdc-select-arrow-wrapper,
.dark-theme-portal-base .input-40px mat-form-field .mat-mdc-form-field-infix .mat-mdc-select-arrow-wrapper {
  padding-left: 8px;
}
.dark-theme-portal-base mat-toolbar-row mat-form-field .mat-mdc-form-field-subscript-wrapper,
.dark-theme-portal-base .input-40px mat-form-field .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.dark-theme-portal-base mat-toolbar-row .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label,
.dark-theme-portal-base .input-40px .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 18px;
}
.dark-theme-portal-base mat-toolbar-row .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.dark-theme-portal-base .input-40px .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(-24.75px)
    scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
}
.dark-theme-portal-base mat-form-field.no-validator .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.dark-theme-portal-base .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.dark-theme-portal-base .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-icon-button.mat-mdc-button-base .mat-datepicker-toggle-default-icon {
  display: inline-block;
}
.dark-theme-portal-base .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(-26.75px)
    scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
}
.dark-theme-portal-base .mdc-floating-label {
  will-change: auto;
}
.dark-theme-portal-base .mdc-notched-outline__notch {
  border-right: 0;
}
.dark-theme-portal-base .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid) .mdc-notched-outline__leading,
.dark-theme-portal-base .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid) .mdc-notched-outline__notch,
.dark-theme-portal-base .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid) .mdc-notched-outline__trailing {
  border-color: var(--b3n-border, #e0e0e0);
}
.dark-theme-portal-base .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid):hover .mdc-notched-outline__leading,
.dark-theme-portal-base .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid):hover .mdc-notched-outline__notch,
.dark-theme-portal-base .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid):hover .mdc-notched-outline__trailing {
  border-color: var(--b3n-link, #1979b1);
}
.dark-theme-portal-base .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid):hover .mdc-notched-outline__notch .mdc-floating-label {
  color: var(--b3n-link, #027cb5);
}
.dark-theme-portal-base .mdc-text-field--outlined.mdc-text-field--focused:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__leading,
.dark-theme-portal-base .mdc-text-field--outlined.mdc-text-field--focused:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__notch,
.dark-theme-portal-base .mdc-text-field--outlined.mdc-text-field--focused:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__trailing {
  border-color: var(--b3n-link, #027cb5);
}
.dark-theme-portal-base .mdc-text-field--outlined.mdc-text-field--focused:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__notch .mdc-floating-label {
  color: var(--b3n-link, #027cb5);
}
.dark-theme-portal-base .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #039be5;
  --mdc-switch-selected-handle-color: #039be5;
  --mdc-switch-selected-hover-state-layer-color: #039be5;
  --mdc-switch-selected-pressed-state-layer-color: #039be5;
  --mdc-switch-selected-focus-handle-color: #039be5;
  --mdc-switch-selected-hover-handle-color: #039be5;
  --mdc-switch-selected-pressed-handle-color: #039be5;
  --mdc-switch-selected-focus-track-color: #039be58a;
  --mdc-switch-selected-hover-track-color: #039be58a;
  --mdc-switch-selected-pressed-track-color: #039be58a;
  --mdc-switch-selected-track-color: #039be58a;
}
.dark-theme-portal-base .mat-mdc-slide-toggle.mat-accent .mdc-form-field > label {
  padding-left: 8px;
}
.dark-theme-portal-base .mat-mdc-slide-toggle {
  --mdc-switch-unselected-focus-track-color: #00000061;
  --mdc-switch-unselected-hover-track-color: #00000061;
  --mdc-switch-unselected-pressed-track-color: #00000061;
  --mdc-switch-unselected-track-color: #00000061;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #fafafa;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.dark-theme-portal-base * {
  -moz-osx-font-smoothing: inherit !important;
  -webkit-font-smoothing: inherit !important;
}
.dark-theme-portal-base .mat-tooltip {
  white-space: pre-line !important;
  font-size: 12px !important;
}
.dark-theme-portal-base .secondary-text {
  color: var(--b3n-secondary-text, rgba(0, 0, 0, 0.5411764706)) !important;
}
.dark-theme-portal-base .mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 32px !important;
  --mdc-switch-selected-focus-state-layer-color: var(--b3n-primary) !important;
  --mdc-switch-selected-hover-state-layer-color: var(--b3n-primary) !important;
  --mdc-switch-selected-pressed-state-layer-color: var(--b3n-primary) !important;
  --mdc-switch-selected-handle-color: var(--b3n-primary) !important;
  --mdc-switch-selected-focus-handle-color: var(--b3n-primary) !important;
  --mdc-switch-selected-hover-handle-color: var(--b3n-primary) !important;
  --mdc-switch-selected-pressed-handle-color: var(--b3n-primary) !important;
  --mdc-switch-selected-focus-track-color: #1979b180 !important;
  --mdc-switch-selected-hover-track-color: #1979b180 !important;
  --mdc-switch-selected-pressed-track-color: #1979b180 !important;
  --mdc-switch-selected-track-color: #1979b180 !important;
}
.dark-theme-portal-base .mat-mdc-icon-button,
.dark-theme-portal-base .mat-mdc-fab,
.dark-theme-portal-base .mat-mdc-mini-fab {
  padding: 0 !important;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
}
.dark-theme-portal-base .mdc-dialog .mdc-dialog__title {
  --mdc-dialog-subhead-weight: 550;
  color: var(--b3n-title, #26272c);
}
.dark-theme-portal-base .mdc-dialog .mdc-dialog__content {
  padding: 6px 24px 8px;
}
.dark-theme-portal-base .mdc-dialog .mdc-dialog__actions {
  padding: 8px 24px 24px;
}
.dark-theme-portal-base .mat-mdc-dialog-container .mdc-dialog__content {
  --mdc-dialog-supporting-text-color: var(--b3n-color, #000000de) !important;
}
.dark-theme-portal-base .mat-mdc-button.mat-mdc-button-base,
.dark-theme-portal-base .mat-mdc-raised-button.mat-mdc-button-base,
.dark-theme-portal-base .mat-mdc-unelevated-button.mat-mdc-button-base,
.dark-theme-portal-base .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 40px;
  font-weight: 550;
}
.dark-theme-portal-base .mat-mdc-radio-button .mdc-form-field > label {
  cursor: pointer;
}
.dark-theme-portal-base .mdc-button--outlined .mdc-button__ripple {
  border-radius: 5px;
}
.dark-theme-portal-base a {
  color: var(--b3n-link, #1979b1);
}
.dark-theme-portal-base .mdc-switch .mdc-switch__icons {
  display: none;
}
.dark-theme-portal-base .mat-expansion-panel-header {
  font-weight: inherit;
}
.dark-theme-portal-base .mat-mdc-table .mdc-data-table__header-cell {
  color: var(--b3n-secondary-color, rgba(31, 33, 35, 0.6));
  border-bottom-color: var(--b3n-table-border, #dddddd);
}
.dark-theme-portal-base .mat-mdc-table .mdc-data-table__cell {
  color: var(--b3n-table-color, #1f2123) !important;
  border-bottom-color: var(--b3n-table-border, #dddddd);
}
.dark-theme-portal-base .mat-mdc-no-data-row {
  height: 52px;
}
.dark-theme-portal-base .mat-mdc-paginator {
  border-top: 1px solid var(--b3n-border, #e0e0e0);
}
.dark-theme-portal-base .mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  margin-right: 0.5rem;
}
.dark-theme-portal-base .box-uuid {
  padding: 4px 8px !important;
  line-height: 25px !important;
  background: var(--b3n-box-uuid, #e6e6e6);
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 550;
  color: var(--b3n-color, rgba(0, 0, 0, 0.8705882353));
  height: 25px !important;
  white-space: nowrap !important;
}
.dark-theme-portal-base .mat-datepicker-actions {
  padding: 16px;
}
.dark-theme-portal-base .mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.dark-theme-portal-base .mat-datepicker-content .mat-calendar .mat-calendar-content {
  padding-bottom: 24px;
}
.dark-theme-portal-base .mat-select-search-input::placeholder {
  color: var(--b3n-placeholder, rgba(31, 33, 35, 0.4)) !important;
}
.dark-theme-portal-base .mat-step-header.mat-primary .mat-step-icon-selected {
  background-color: var(--b3n-primary, #1979b1);
  color: white;
}
.dark-theme-portal-base .mat-step-header.mat-primary .mat-step-label-selected {
  color: var(--b3n-primary, #1979b1);
  font-weight: 550;
}
.dark-theme-portal-base .color-warn {
  color: #f44336 !important;
}
.dark-theme-portal-base .mdc-button--outlined.mat-primary {
  --mdc-outlined-button-label-text-color: var(--b3n-link, #027cb5);
}

body a {
  color: var(--b3n-link, #1979b1) !important;
}
body mat-icon,
body .material-icons,
body .material-symbols-rounded {
  font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
  font-family: "Material Symbols Rounded";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
body mat-icon.fill,
body .material-icons.fill,
body .material-symbols-rounded.fill {
  font-variation-settings: "FILL" 1, "wght" 300, "GRAD" 0, "opsz" 24;
}

/* You can add global styles to this file, and also import other style files */
:root {
  --app__warning--height: 0px;
  --gray-50: #fafafa;
  --gray-100: #f5f5f5;
  --gray-200: #eeeeee;
  --gray-300: #e0e0e0;
  --gray-400: #bdbdbd;
  --gray-500: #9e9e9e;
  --gray-600: #757575;
  --gray-700: #616161;
  --gray-800: #424242;
  --gray-900: #212121;
}

.floatRight {
  float: right;
}

.error-hint {
  color: #e95849 !important;
}

.message.error {
  color: #e95849 !important;
}

.mfb-component--br {
  z-index: 102 !important;
}
.mfb-component--br .mfb-component__button--main {
  background-color: #1a93f7 !important;
  box-shadow: none !important;
}
.mfb-component--br .mfb-component__button--main .mat-icon {
  font-size: 36px;
}

.alert {
  position: relative;
  padding: 0 1.6rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.full-width {
  width: 100% !important;
}

.ms-login {
  height: 41px !important;
  width: auto !important;
}

.mat-mdc-raised-button {
  box-shadow: none !important;
}

.header-toolbar {
  padding: 0 16px;
  height: 90px !important;
}
.header-toolbar mat-divider {
  width: inherit;
}

.content-form .form-item {
  padding: 0 20px;
}

.page-header {
  font-size: 20px;
  font-weight: 550 !important;
  letter-spacing: -0.2px !important;
}

.title-section {
  font-size: 16px;
  font-weight: 550;
  margin-bottom: 10px;
}

.error.touched {
  color: #e95849;
}

.success.touched {
  color: #43a047;
}

.password-complexity {
  width: 100%;
  font-size: 13px;
  color: var(--b3n-secondary-color, rgba(49, 51, 56, 0.8));
  margin-bottom: 1rem;
}

.mat-mdc-text-field-wrapper {
  background-color: transparent !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mat-mdc-form-field-icon-suffix,
.mat-mdc-form-field-icon-prefix {
  padding: 0 16px 0 16px !important;
}

.not-found-org {
  color: var(--b3n-secondary-color, rgba(49, 51, 56, 0.8));
}

body.theme-default .menu-container .mat-mdc-menu-item {
  padding: 0 8px !important;
  margin-left: 0 !important;
}
body.theme-default .italic {
  font-style: italic;
}

.mat-select-search-inner {
  background-color: white !important;
}