@use '@angular/material' as mat;
@use 'sass:map';

@mixin apply_light_theme($default-primary-palette, $default-accent-palette, $default-warn-palette, $typography) {
  background-color: var(--b3n-background, #ffffff);
  color: var(--b3n-color, #000000de);

  $theme: config-color-theme(
    $isLight: true,
    $primary: $default-primary-palette,
    $accent: $default-accent-palette,
    $warn: $default-warn-palette,
    $typography: $typography
  );

  @include mat.all-component-themes($theme);
  // apply default style and density component
  @include b3n-default-custom-material();

  .color-warn {
    color: mat.get-color-from-palette($default-warn-palette) !important;
  }
}
