@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import core scss*/
@import 'scss/core-without-plugins';
@import 'unuse/app-core.theme.scss';
@import './app/theme/app.theme.scss';

/* You can add global styles to this file, and also import other style files */
:root {
  --app__warning--height: 0px;
  --gray-50: #fafafa;
  --gray-100: #f5f5f5;
  --gray-200: #eeeeee;
  --gray-300: #e0e0e0;
  --gray-400: #bdbdbd;
  --gray-500: #9e9e9e;
  --gray-600: #757575;
  --gray-700: #616161;
  --gray-800: #424242;
  --gray-900: #212121;
}

.floatRight {
  float: right;
}

.error-hint {
  color: #e95849 !important;
}

.message.error {
  color: #e95849 !important;
}

.mfb-component--br {
  z-index: 102 !important;
  .mfb-component__button--main {
    background-color: #1a93f7 !important;
    box-shadow: none !important;
    .mat-icon {
      font-size: 36px;
    }
  }
}

.alert {
  position: relative;
  padding: 0 1.6rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.full-width {
  width: 100% !important;
}

.ms-login {
  height: 41px !important;
  width: auto !important;
}

.mat-mdc-raised-button {
  box-shadow: none !important;
}

.header-toolbar {
  padding: 0 16px;
  height: 90px !important;

  mat-divider {
    width: inherit;
  }
}

.content-form {
  .form-item {
    padding: 0 20px;
  }
}

.page-header {
  font-size: 20px;
  font-weight: 550 !important;
  letter-spacing: -0.2px !important;
}

.title-section {
  font-size: 16px;
  font-weight: 550;
  margin-bottom: 10px;
}

.error.touched {
  color: #e95849;
}
.success.touched {
  color: #43a047;
}

.password-complexity {
  width: 100%;
  font-size: 13px;
  color: var(--b3n-secondary-color, #313338cc);
  margin-bottom: 1rem;
}

.mat-mdc-text-field-wrapper {
  background-color: transparent !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mat-mdc-form-field-icon-suffix,
.mat-mdc-form-field-icon-prefix {
  padding: 0 16px 0 16px !important;
}

// .store-announcement {
//   .mat-mdc-dialog-container {
//     .mdc-dialog__container {
//       .mdc-dialog__title {
//         color: var(--b3n-color, #000000de) !important;
//       }

//       .mdc-dialog__surface {
//         background-color: var(--b3n-background, #ffffff) !important;
//         color: var(--b3n-color, #000000de) !important;

//         .mat-mdc-dialog-content {
//           color: inherit !important;
//         }

//         .mat-mdc-dialog-content {
//           color: inherit !important;
//         }
//       }
//     }
//   }
// }

.not-found-org {
  color: var(--b3n-secondary-color, #313338cc);
}

body.theme-default {
  .menu-container {
    .mat-mdc-menu-item {
      padding: 0 8px !important;
      margin-left: 0 !important;
    }
  }

  .italic {
    font-style: italic;
  }
}

.mat-select-search-inner {
  background-color: white !important;
}
