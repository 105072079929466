@mixin b3n-toggle {
  .mat-mdc-slide-toggle.mat-accent {
    --mdc-switch-selected-focus-state-layer-color: #039be5;
    --mdc-switch-selected-handle-color: #039be5;
    --mdc-switch-selected-hover-state-layer-color: #039be5;
    --mdc-switch-selected-pressed-state-layer-color: #039be5;
    --mdc-switch-selected-focus-handle-color: #039be5;
    --mdc-switch-selected-hover-handle-color: #039be5;
    --mdc-switch-selected-pressed-handle-color: #039be5;
    --mdc-switch-selected-focus-track-color: #039be58a;
    --mdc-switch-selected-hover-track-color: #039be58a;
    --mdc-switch-selected-pressed-track-color: #039be58a;
    --mdc-switch-selected-track-color: #039be58a;

    .mdc-form-field > label {
      padding-left: 8px;
    }
  }

  .mat-mdc-slide-toggle {
    --mdc-switch-unselected-focus-track-color: #00000061;
    --mdc-switch-unselected-hover-track-color: #00000061;
    --mdc-switch-unselected-pressed-track-color: #00000061;
    --mdc-switch-unselected-track-color: #00000061;
    --mdc-switch-unselected-focus-handle-color: #fafafa;
    --mdc-switch-unselected-hover-handle-color: #fafafa;
    --mdc-switch-unselected-pressed-handle-color: #fafafa;
    --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
    --mdc-switch-unselected-handle-color: #fafafa;
    --mdc-switch-selected-icon-color: #fff;
    --mdc-switch-disabled-selected-icon-color: #fff;
    --mdc-switch-disabled-unselected-icon-color: #fff;
    --mdc-switch-unselected-icon-color: #fff;
  }
}
