@use '@angular/material' as mat;
@use 'sass:map';

// https://github.com/angular/components/blob/main/guides/duplicate-theming-styles.md#disabling-duplication-warnings
mat.$theme-ignore-duplication-warnings: true;

$blue-palette: map.merge(
  mat.$blue-palette,
  (
    500: #1979b1
  )
);

$blue-dark-palette: map.merge(
  mat.$blue-palette,
  (
    500: #138fce
  )
);

$primary: mat.define-palette($blue-palette);
$primary-dark: mat.define-palette($blue-dark-palette);
$accent: mat.define-palette(mat.$light-blue-palette, 600, 400, 700);
$warn: mat.define-palette(mat.$red-palette);
$typography: mat.define-typography-config(
  $font-family: $font-family,
  $subtitle-1: $subtitle-1,
  $body-1: $body-1,
  $body-2: $body-2,
  $subtitle-2: $subtitle-2,
  $caption: $caption,
  $button: $button
);

// ============= Generate Component Theme Material ================
.theme-default {
  @include apply_light_theme($primary, $accent, $warn, $typography);
}

.dark-theme-portal-base {
  @include apply_theme_dark($primary-dark, $accent, $warn, $typography);
}

// =================== Global style =============================
body {
  a {
    color: var(--b3n-link, #1979b1) !important;
  }

  mat-icon,
  .material-icons,
  .material-symbols-rounded {
    font-variation-settings: 'FILL' 0, 'wght' 300, 'GRAD' 0, 'opsz' 24;

    &.fill {
      font-variation-settings: 'FILL' 1, 'wght' 300, 'GRAD' 0, 'opsz' 24;
    }

    font-family: 'Material Symbols Rounded';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }
}
