mat-icon {
  @for $size from 10 through 80 {
    &.s-#{$size} {
      font-size: #{($size) + 'px'} !important;
      width: #{($size) + 'px'} !important;
      height: #{($size) + 'px'} !important;
      min-width: #{($size) + 'px'} !important;
      min-height: #{($size) + 'px'} !important;
      line-height: #{($size) + 'px'} !important;
    }
  }
}
