// #region style input phonenumber
.intlTel {
  .mat-ripple-element,
  .mat-mdc-button-persistent-ripple::before {
    background-color: transparent !important;
  }

  .country-selector {
    opacity: 1 !important;
    top: 2px !important;
    left: -20px !important;
    width: 100px !important;
  }

  input:not(.country-search) {
    left: 10px !important;
    padding-left: 70px !important;
    padding: 0 0 0 90px !important;
    &::placeholder {
      color: var(--b3n-placeholder, #1f212366) !important;
    }
  }

  mat-error {
    font-size: 10.5px;
  }
}

.ngx-mat-tel-input-mat-menu-panel {
  &.mat-mdc-menu-panel {
    max-width: fit-content !important;
    padding: 8px !important;
  }

  .mat-mdc-menu-content:not(:empty) {
    max-height: 350px !important;
    min-width: max-content !important;

    .country-list-button {
      padding: 14px 24px 14px 8px !important;
      .icon-wrapper {
        padding-right: 16px !important;
      }
    }
  }

  .country-search {
    background-color: var(--b3n-background, #ffffff) !important;
    margin-bottom: 8px !important;
    color: var(--b3n-color, #313338) !important;
    border: none !important;
    outline: none !important;
    padding: 20px 10px 24px !important;
    margin-bottom: 0 !important;
    font-size: 16px !important;

    &::placeholder {
      color: var(--b3n-placeholder, #1f212366) !important;
    }

    &:focus {
      outline: none !important;
    }
  }

  .mat-divider {
    margin: 4px 0 !important;
  }

  input {
    border: 1px solid #ddd !important;
    border-radius: 6px !important;

    &:focus-visible {
      outline: var(--mdc-theme-primary, #2196f3) auto 1px !important;
    }
  }
}
// #endregion
