@use '@angular/material' as mat;
@use 'sass:map';

@mixin apply_theme_dark($dark-primary-palette, $dark-accent-palette, $dark-warn-palette, $typography) {
  background-color: var(--b3n-background, #313338);
  color: var(--b3n-color, #e3e5e8);

  $theme-dark: config-color-theme(
    $isLight: false,
    $primary: $dark-primary-palette,
    $accent: $dark-accent-palette,
    $warn: $dark-warn-palette,
    $typography: $typography
  );

  @include mat.all-component-themes($theme-dark);
  // apply default style and density component
  @include b3n-default-custom-material();

  .color-warn {
    color: mat.get-color-from-palette($dark-warn-palette) !important;
  }

  // mat-stroked-button
  .mdc-button--outlined.mat-primary {
    --mdc-outlined-button-label-text-color: var(--b3n-link, #027cb5);
  }
}
