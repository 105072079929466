@mixin b3n-toolbar {
  mat-toolbar {
    font-size: inherit !important;
    letter-spacing: -0.2px !important;
    font-weight: 400 !important;
  }

  mat-toolbar-row,
  .input-40px {
    @include mat.form-field-density(-4);

    mat-form-field {
      max-width: 280px;

      .mat-mdc-form-field-infix {
        width: fit-content;
        min-width: 100px;

        .mat-mdc-select-arrow-wrapper {
          padding-left: 8px;
        }
      }
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
      .mat-mdc-floating-label {
        top: 18px;
      }
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined
      .mdc-notched-outline--upgraded
      .mdc-floating-label--float-above {
      --mat-mdc-form-field-label-transform: translateY(-24.75px)
        scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
    }
  }
}
