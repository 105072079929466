@use '@angular/material' as mat;
@import './common/typography.scss';
@import './common/menu.scss';
@import './common/select-box.scss';
@import './common/toolbar.scss';
@import './common/formfield.scss';
@import './common/toggle';
@import './common/input-phone-number';
@import './common/flag-country.scss';
@import './common/select-search.scss';

// set default variable
$font-family: 'Inter';
$subtitle-1: mat.define-typography-level(1em, 24px, 400, null, -0.02);
$body-1: mat.define-typography-level(1em, 24px, 400, null, -0.02);
$body-2: mat.define-typography-level(1em, 24px, 400, null, -0.02);
$subtitle-2: mat.define-typography-level(12px, 24px, 550, null, -0.02);
$caption: mat.define-typography-level(10.5px, 12px, 400, null, -0.02);
$button: mat.define-typography-level(1em, 36px, 500, null, -0.02);

// Custom component angular material
@mixin b3n-default-custom-material() {
  --b3n-primary: #1979b1;
  --b3n-primary-text: white;
  --b3n-warn: #f44336;
  font-size: 14px;
  font-family: 'Inter';
  letter-spacing: -0.02px;
  line-height: 24px; // inherit for all child component

  @debug 'b3n-default-custom-material';

  /**
    config density
    default is 0 or maximum
    maximum, 0, -1, -2, -3, -4, 0
  */
  @include mat.form-field-density(-3); // min = -5
  @include mat.tooltip-density(0);
  @include mat.core-density(0);
  @include mat.checkbox-density(-3); // min = -3
  @include mat.dialog-density(0);
  @include mat.chips-density(0);
  @include mat.slide-toggle-density(0);
  @include mat.radio-density(-3);
  @include mat.list-density(-2);
  @include mat.paginator-density(0);
  @include mat.tabs-density(0);
  @include mat.button-density(0);
  @include mat.icon-button-density(-3);
  @include mat.table-density(0);
  @include mat.expansion-density(0);
  @include mat.stepper-density(0);
  @include mat.toolbar-density(0);
  @include mat.tree-density(0);
  @include mat.button-toggle-density(0);

  // add plugin
  @include b3n-typography();
  @include b3n-menu();
  @include b3n-selectbox();
  @include b3n-toolbar();
  @include b3n-formfield();
  @include b3n-toggle();

  // add variable of material
  --mdc-shape-small: 6px;
  --mdc-dialog-container-shape: 6px;

  * {
    -moz-osx-font-smoothing: inherit !important;
    -webkit-font-smoothing: inherit !important;
  }

  // tooltip
  .mat-tooltip {
    white-space: pre-line !important;
    font-size: 12px !important;
  }

  .secondary-text {
    color: var(--b3n-secondary-text, #0000008a) !important;
  }

  .mat-mdc-slide-toggle {
    // layer
    --mdc-switch-state-layer-size: 32px !important;
    --mdc-switch-selected-focus-state-layer-color: var(--b3n-primary) !important;
    --mdc-switch-selected-hover-state-layer-color: var(--b3n-primary) !important;
    --mdc-switch-selected-pressed-state-layer-color: var(--b3n-primary) !important;

    // circle
    --mdc-switch-selected-handle-color: var(--b3n-primary) !important;
    --mdc-switch-selected-focus-handle-color: var(--b3n-primary) !important;
    --mdc-switch-selected-hover-handle-color: var(--b3n-primary) !important;
    --mdc-switch-selected-pressed-handle-color: var(--b3n-primary) !important;

    // track
    --mdc-switch-selected-focus-track-color: #1979b180 !important;
    --mdc-switch-selected-hover-track-color: #1979b180 !important;
    --mdc-switch-selected-pressed-track-color: #1979b180 !important;
    --mdc-switch-selected-track-color: #1979b180 !important;
  }

  // tooltip
  // .mat-tooltip {
  //   padding: 4px 2px !important;
  // }

  // can resize icon and center button
  .mat-mdc-icon-button,
  .mat-mdc-fab,
  .mat-mdc-mini-fab {
    padding: 0 !important;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
  }

  // dialog
  .mdc-dialog {
    .mdc-dialog__title {
      --mdc-dialog-subhead-weight: 550;
      color: var(--b3n-title, #26272c);
    }

    .mdc-dialog__content {
      padding: 6px 24px 8px;
    }

    .mdc-dialog__actions {
      padding: 8px 24px 24px;
    }
  }

  .mat-mdc-dialog-container .mdc-dialog__content {
    --mdc-dialog-supporting-text-color: var(--b3n-color, #000000de) !important;
  }

  // button
  .mat-mdc-button.mat-mdc-button-base,
  .mat-mdc-raised-button.mat-mdc-button-base,
  .mat-mdc-unelevated-button.mat-mdc-button-base,
  .mat-mdc-outlined-button.mat-mdc-button-base {
    height: 40px;
    font-weight: 550;
  }

  //mat-radio-button
  .mat-mdc-radio-button {
    .mdc-form-field > label {
      cursor: pointer;
    }
  }

  // hover button but child has border-radous not fully
  .mdc-button--outlined {
    .mdc-button__ripple {
      border-radius: 5px;
    }
  }

  a {
    color: var(--b3n-link, #1979b1);
  }

  // remove icon in slide toggle
  .mdc-switch {
    .mdc-switch__icons {
      display: none;
    }
  }

  // expansion panel
  .mat-expansion-panel-header {
    font-weight: inherit;
  }

  // style table
  .mat-mdc-table {
    .mdc-data-table__header-cell {
      // color: #0000008a;
      color: var(--b3n-secondary-color, #1f212399);
      border-bottom-color: var(--b3n-table-border, #dddddd);
    }

    .mdc-data-table__cell {
      color: var(--b3n-table-color, #1f2123) !important;
      border-bottom-color: var(--b3n-table-border, #dddddd);
    }
  }

  .mat-mdc-no-data-row {
    height: 52px;
  }

  // mat-paginator
  .mat-mdc-paginator {
    // border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-top: 1px solid var(--b3n-border, #e0e0e0);
  }

  // list checkbox
  .mdc-list-item--with-leading-checkbox .mdc-list-item__start {
    margin-right: 0.5rem;
  }

  // uuid
  .box-uuid {
    padding: 4px 8px !important;
    line-height: 25px !important;
    background: var(--b3n-box-uuid, #e6e6e6);
    border-radius: 4px !important;
    font-size: 12px !important;
    font-weight: 550;
    color: var(--b3n-color, #000000de);
    height: 25px !important;
    white-space: nowrap !important;
  }

  // mat-datepicker
  .mat-datepicker-actions {
    padding: 16px;
  }

  // Border bottom mat-tab-header
  .mat-mdc-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  // expansion panel
  // .mat-expansion-indicator::after {
  // border-color: #0000008a;
  // }

  // mat-calendar
  .mat-datepicker-content .mat-calendar {
    // height: auto;

    .mat-calendar-content {
      padding-bottom: 24px;
    }
  }

  // mat-select-search
  .mat-select-search-input::placeholder {
    color: var(--b3n-placeholder, #1f212366) !important;
  }

  .mat-step-header.mat-primary {
    .mat-step-icon-selected {
      background-color: var(--b3n-primary, #1979b1);
      color: white;
      // TODO: theme variable
    }

    .mat-step-label-selected {
      color: var(--b3n-primary, #1979b1);
      font-weight: 550;
    }
  }
}
